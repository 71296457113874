import React, { useEffect } from 'react';

const BigTroutLake2021page1 = ({openImageView}) => {

    useEffect( ()=> {
        let e = document.getElementById('nav');
        if ( e !== undefined )
            e.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, []);
    
    return (
        <div className="bigtrout_page1">
                <img alt="Big Trout Lake" src="/bigtroutlake/bigtrout4.jpg"></img>
                <p>
                I had been through Algonquin Park many times in my life, but always with family or friends.  These last few years I had taken interest in the idea of a solo expedition, though I had never actually attempted it.  Going by myself meant I could move at my own pace, and explore with more freedom without the need to worry about anyone else.  This past year was the year I turned forty, and I wanted to do something to mark the occasion and perhaps test myself a bit, so I thought this would be a good year to go. 
                </p>
                <p>
                I think some of my friends and family thought I was a bit nuts to want to go camping alone, I’m pretty sure my wife thought I was having some sort of mid-lift crisis type thing going on, but I didn’t let that deter me.  My reasons were much simpler of course, I just happen to be a guy that really likes camping and I was motivated by the chance to explore deeper into Algonquin than I had been in a long time. I was looking for a personal challenge, something to test my endurance and hopefully find some true solitude. 
                </p>
                <p>
                I had never been solo in the back country before, but I am a pretty experienced camper and felt confident in my abilities to survive. That being said, I still wasn’t sure that I would fully enjoy this experience alone, all that I knew was that I wanted to do this at least once to find out. I suspected that the nights alone in the middle of Algonquin might be a little anxiety inducing as the sounds have a tendency to play tricks on you when you’re lying there in your tent trying to sleep.  All I could do is just do it and get through it I figured, so I started planning myself an adventure.
                </p>
                <p>
                I spent some time going over the map, looking for a route that interested me. I decided to take on the well-known Big Trout loop, and I would aim to complete it in five days. The plan was two days of travel in, a rest day on Big Trout followed by two days to travel back to where I started. This would set a pretty decent pace while still giving me a little time to relax and fish on the way.  This route is also a bit nostalgic for me as I have travelled through Big Trout and the Otterslides as a young kid, camping with my parents and I was really looking forward to paddling through the grassy creeks again.  I figured this route offered me a decent challenge, but it could be reasonably done with the time I had available.  The route covered a large part of the Algonquin back country with a lot of variety to keep things interesting. It included many different lakes, big and small... winding creeks, rivers and marshy areas too, so there would be a lot of different scenery and hopefully a high chance of wild life sightings.
                </p>
                <hr/>
                <div className="btl-map-container">
                    <div className="map-image">
                        <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/routemap.jpg")}>
                            <img alt="route map" src="/bigtroutlake/routemap.jpg"></img>
                        </div>
                    </div>
                    <div className="map-text">
                        Day 1: Canoe Lake -&#x0003E; Burnt Island Lake.<br/>
                        Day 2: Burnt Island Lake -&#x0003E; Big Trout Lake<br/>
                        Day 3: Big Trout Lake [Rest Day]<br/>
                        Day 4: Big Trout Lake -&#x0003E; McIntosh Lake<br/>
                        Day 5: McIntosh Lake -&#x0003E; Canoe Lake<br/><br/>

                        Total Loop Distance: Approx. 80km<br/>
                        Total Portages: 13 (including a double)<br/>
                        Portage Distance: 7.2km, 21.6km with Double Carry<br/>
                    </div>   
                </div>   
                <hr/>    
                <p>The Big Trout Lake loop can be done in either clockwise or counter clockwise direction, I decided on the latter.  Starting from the Canoe Lake access point, I would head through the Joe Lakes and aim to make it most of the way across Burnt Island Lake before setting up my first campsite.  The second day I would head through the Otterslides and into Big Trout Lake where I would spend a “rest” day and two nights.  On the fourth day I would travel to McIntosh Lake for one last night, heading back to the access point on day five. 
                </p>  
                <p>I planned the trip for mid-September because it’s my favorite time of year to camp.  No bugs, the weather is a little cooler but generally sunny and nice. The water is still swimmable and did I mention, NO BUGS!? I figured the weekend after Labor Day wouldn’t be too busy, hopefully I wouldn’t run into too much traffic on the trails. The plan was set, I was happy with my route and was really looking forward to the adventure. The only thing left was to get prepared and get in proper shape, though the trip was still many months away.
                </p>
                <p>Over the months I upgraded some of my camping gear to try and make my portages lighter and a bit easier. I purchased a small solo tent, much smaller and lighter than my other one. I finally invested in a decent water filter so I’m not as dependant on boiling water or using aqua tabs, and I upgraded my sleeping accessories to make sleeping more comfortable. I was really making an effort to keep weight down, but I was pretty sure I would still be doing double carries on all my portages. 
                </p>
                <p>This solo trip was actually the second trip I had planned for Algonquin this year, unfortunately an early May trout fishing trip with a good friend of mine never happened due to Covid restrictions, the park remained closed and we were unable to keep our reservations. It was a huge disappointment, as I had been, like many others, desperate for a chance to get out and do something, especially some camping and fishing.   I feared if things didn’t go well, I might have to cancel these plans as well.  All I could do was wait and hope for the best, so I continued with my preparations.
                </p>
                <img alt="old canoe" src="/bigtroutlake/oldcanoe.jpg"></img>
                <p>Just before summer, I was given an old used canoe.  It was in usable shape, but it was rough and could use a lot of TLC. This one is much lighter than my Aluminum so I decided I would make this my summer project, sand it down and refinish it. This canoe could be my solo canoe, being about a foot shorter than my other one, and much lighter, it would be faster on the water and easier on the portages.  Over about a month, in my spare time, I cleaned and sanded the outer hull, removed flaking material from old repairs, etc.  Unfortunately the majority of the weather we received later in the summer was very wet and hindered my ability to work on it, it was also not an environment conducive to painting. As time went by, I finally had to admit to myself that I could not complete the work on the canoe before the trip date and therefore would either need to rent a small solo canoe, or suck it up and carry the beast that is my sixteen and a half foot aluminum canoe. I chose to suffer.
                </p>
                <p>I try and stay pretty active year round, generally focused on strength training, without too much of a focus on endurance most of the year.  About three months before my trip I began focusing my workouts heavily towards improving endurance and overall work capacity as well as core strength.  I wanted to be able to move at a fast pace for long periods without getting winded and decrease the potential for injury. The gym had just finally opened up due to Covid restrictions, so I used a combination of weight training as well as general sport/athletic activities. I focused a lot on shoulder health as I knew I would be spending a lot of time paddling.  I also spent a great deal of time training things like ankle strength and mobility to avoid injuries on the portages.
                </p>
                <p>My workout’s consisted of heavy weighted lifts such as squats, deadlifts, shoulder press, and rows, coupled with lots of activity such as hiking, biking, canoeing, swimming, running, playing sports, etc, plus some interval training on the treadmill.  I was training harder each time, right up until a few days before the trip, at which point I would take a the last few days for recovery, while eating loads of carbs to refuel for all the traveling I would soon be doing. I maintained a high level of activity all throughout the summer so that when September trip time came, I would be ready for anything Algonquin could throw at me. It also makes the trip more enjoyable in general when you don’t feel like you’re dying half way through a portage.  I was as ready as I was ever going to be.
                </p>
                <p>Since I would be alone, I really tried to be as prepared as possible to avoid injuries or any potential problem while I’m out.  I made sure to wear proper footwear, appropriate clothing with rain gear, and carry a decent med kit + repair kit, even bear spray, just in case. Safety was priority and I did not have a SPOT type device with me, so I was relying on my skill, preparedness and a little luck to survive this. I would be navigating using my map with a compass but I would bring my GPS as well with me as a backup. This GPS was not your true hiking style GPS, I brought my Garmin Drive Smart 50. Now before you laugh, while it certainly isn’t ideal for hiking deep in the forest, it can be used to get your current location with some pretty good detail on a lake and proved very useful in verifying my navigation throughout my trip, if for nothing else but peace of mind.  
                </p>
                <p>I preplanned all of my meals for the trip in order to keep weight down as well as maintain proper nutrition. I figured out my approximate caloric requirements so I could make sure to bring enough to get me through the intense days of travel. I created meals that I would allow me to eat easily and on the go, without too much prep.  I focused on maintaining a good level of protein each day, while allowing for lots of carbs and fat to fuel most of my energy needs.
                </p>
                <img loading="lazy" alt="camping menu" src="/bigtroutlake/foodpack.jpg"></img>
                <p>My main protein sources were steak, bacon, eggs, beef jerky, and protein bars. My carb sources included rice, potatoes, oatmeal, pancakes, fruit, noodles, and plenty of sweets such as chocolate and candy. Various nuts such as cashews, almonds, peanuts and peanut butter provided lots of good fats as well as additional protein. I packed a daily multi vitamin. I brought some syrup for the pancakes, oil and some fish crisp just in case. Most importantly, I brought lots of coffee.  For something to eat on the go, I made individual daily snack packs, consisting of approximately two thousand calories each. Each pack was made up of various nuts and candies such as m&ms, gummy bears, chocolate covered raisins, yogurt covered cranberries and more.  These packs made munching while canoeing or portaging much easier. I carefully packed the food into large freezer bags, removed the air and packed them snugly. I managed to fit pretty much all of my food into the lower half of my big backpack, one of two packs that I would be bringing with me. 
                </p>
                <p>I was going to bring two backpacks with me. My larger 85 liter Teton backpack would contain my food, all the kitchen related things such as pots and pans, a stove, gas, etc. My sleeping pad and pillow, rope and the cooler would be placed on top but inside. The smaller bag I have is an older but trust worthy Swiss Army backpack that has been all over the place with me over many years. This bag would contain my clothes, a small amount of fishing gear, my med-kit, rain gear, an LED lantern and my navigation equipment such as map, and compass. It would also contain my repair kit, a knife, and a lighter.  I would strap my sleeping bag, tarp and my tent to the outside of the big pack. I would hang the big bag at night, while the smaller bag could come into the tent with me.  Along with the packs, I would have a canoe, two paddles, a fishing net and rod as well as my camera equipment.  I figured two trips on the portage was a given at this point. I hoped to take the smaller pack with the canoe on one run, strapping the extra paddle to the canoe with bungie cords, leaving only one to carry. On the second run I would take the large pack with whatever I could strap to it, and in my hands would be left the main paddle and a few smaller items.  With the canoe weighing in around 70+lbs, and each of my packs ranging in around 30-40lbs I thought I did pretty good. It was still going to be heavy but I could do this.  I wondered if I would regret bringing my aluminum canoe over renting a smaller, lighter model
                </p>
                <p>The summer flew by quickly and before I knew it, the trip day was almost here. A week out from leaving, I finalized all of my gear and gathered all of my food items. I stocked up on energy drinks for the drive there and back.  I charged all of my batteries and cleared all of my camera cards. I was going in with more camera gear than usual, aiming to document as much of the trip as I could, I even brought up a power bank so that I could recharge my camera batteries daily.  I had everything packed and ready to go a couple of days before I left, with the exception of the frozen items. Those would be placed in the cooler the night I left to keep them frozen for as long as possible. 
                </p>
                <p>My departure date was a Sunday, very early in the morning. I spent the Saturday before trying to relax and save my energy for what would be almost 20km of paddling the next day.  I packed all of my gear into the van, and strapped the canoe on the roof so it was ready for the highway. I went to bed early and did my best to get some sleep. I woke at about 2am and quickly got ready to hit the road. I made some coffee and put the frozen food into the big cooler with ice. With the cooler in the van and a final check to make sure I had everything. I grabbed my coffee and a protein shake for the morning, I said goodbye to my wife and kids who were now awake and got in the van for what would be an almost five hour drive.  My goal was to be in Algonquin early and be on the lake for 8-9am in the morning at the latest.  An early start would likely mean calmer waters on Canoe Lake and more time to find and enjoy my first campsite at the end of the day.   
                </p>
                <img loading="lazy" alt="Canoe Lake Permit Office" src="/bigtroutlake/canoelakeaccess.jpg"></img>
                <p>I hit the road about 2:30am, wide awake and looking forward to the morning ahead of me. Soon I would be in Algonquin Park. It had been almost exactly a year since I was in Algonquin last, due to my May trip being cancelled, this would be the only camping trip this year and I was going to make the best of every minute.  I filled up the gas tank in town and got on the highway, with music blasting I was ready for a long drive. I mostly enjoy the drive, especially once I’m passed Barrie and on highway 11, the small towns and scenery are just so nice to drive through.  The drive was pretty uneventful, and it didn't feel like long before I was in Huntsville. I made a quick stop at Tim Hortons for a coffee refill and a pee break before continuing on highway 60 towards the park.  Passing the West Gate sign made it all feel so real, I was finally here!  It wasn’t long before I came to the entrance road to the Canoe Lake access point, this would be my starting point. This was it, I was finally doing this, and it felt great so far. 
                </p>
                
                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/canoelake.jpg")}>
                    <img loading="lazy" alt="Canoe Lake Morning" src="/bigtroutlake/canoelake.jpg"></img>
                </div>
                
                <p>I parked my van on the stony, beach like area directly in front of the permit office and began unloading the canoe at around 7:45am. The parking lot was full of vehicles but it was very quiet, there were no people around. I got the canoe to the water’s edge and started unloading the rest of the gear from the van.  Shortly after, someone arrived to open the permit office. We said a quick good morning, then I finished loading the gear into the canoe. I placed the heavier bag in the front to keep the bow down, while keeping the smaller bag and camera gear close to me for easy access.  After a final check to make sure I didn’t forget anything, I drove the van back to the parking area where I left it for the duration of the trip. I couldn’t believe how many vehicles were still here. It was 8:20am as I walked back to my canoe, I grabbed a few photo’s to begin and shoved off into a cloudy but relatively calm Canoe Lake.
                </p>
                
                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/canoelake1.jpg")}>
                    <img loading="lazy" alt="Canoe Lake" src="/bigtroutlake/canoelake1.jpg"></img>
                </div>
                        
                <p>The morning started sort of gray and cloudy looking, but it was calm and I was hopeful it would remain that way. The weather report called for mostly sun with clouds over the next week, with the exception of Tuesday, which threatened rain and possibly even a thunderstorm.  I was prepared and wasn’t too worried, but as time went by the sun crept out and things started looking better.  I made my way between the islands about mid-way through Canoe Lake, the paddle was feeling real good as I took in my surroundings, exhilarated that I was finally in Algonquin Park and on a lake.  As I approached the fork at the end of Canoe Lake, I noticed a sign, Joe Lake / Potter Creek it said, with arrows pointing opposite directions.  It’s been a long time since I’ve been on Canoe Lake but I think I remember this sign I thought to myself.  I followed the arrow and made my way towards Joe Lake.  
                </p>

                <div className="image-frame50 expandable-image">
                    <img loading="lazy" alt="Canoe Lake Sign" src="/bigtroutlake/canoelakesign1.jpg" onClick={() => openImageView("/bigtroutlake/canoelakesign1.jpg")} />
               
                    <img loading="lazy" alt="Canoe Lake Sign" src="/bigtroutlake/canoelakesign.jpg" onClick={() => openImageView("/bigtroutlake/canoelakesign.jpg")}></img>
                </div>

                <p>It wasn’t long before I was at my first portage of the day, a really easy 295m haul around a dam into Joe Lake. As I approached, I noticed a small group of people coming out from the portage.  A grandfather with his son and grandson all coming back from an overnight stay on Burnt Island Lake. I stopped and chatted with them for a quick moment before heading on my way. This was the first sign of activity I had seen so far since setting out, but it was not to be the last.  I quickly completed the portage, it was extremely wide, flat and free of any real obstacles, and there is even a toilet along one side of the trail for when it gets busy I guess.  Thankfully the trail was anything but busy. As I re-packed my canoe on the shores of Joe Lake, another set of canoes could be seen coming towards me. More people on their way home. 
                </p>

                <div className="bear-country-img expandable-image">
                <img loading="lazy" alt="Bear Country Sign" src="/bigtroutlake/bearcountry.jpg" onClick={() => openImageView("/bigtroutlake/bearcountry.jpg")}></img>
                <img loading="lazy" alt="Portage Landing" src="/bigtroutlake/portage1.jpg" onClick={() => openImageView("/bigtroutlake/portage1.jpg")}></img>
                </div>

                <p>After a quick hello, I was on my way again, paddling into Joe Lake.  A short distance from the portage there is a small bridge you go under before reaching the main part of the lake.  I made my way around the East side of Joe Island and into the East Arm, a long narrow section of Joe Lake, dotted with campsites, some of them still occupied as I passed by.  As I paddled along close to shore, a grey heron landed at the water’s edge. He didn’t seem to mind my presence, but I tried my best to stay quiet and still, giving me the chance to get close enough for some decent pictures.    
                </p>
                
                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/joelake.jpg")}>
                    <img loading="lazy" alt="Joe Lake Bridge" src="/bigtroutlake/joelake.jpg"></img>
                </div>

                <p>As I paddled along the East Arm, I came across a few other campers at their sites, as well as a number of other canoes also on their way out.  I made a point of counting canoes as I went and I was surprised to see over forty of them throughout the day, all going the opposite direction. I guess that would explain the full parking lot back at Canoe Lake.  I made my way to the end of the East Arm where it narrows and becomes a small creek. I easily skipped the first portage, opting to travel the winding creek up until the 435 meter portage into Baby Joe Lake. I think this one is skippable but wasn’t sure, so I decided it was better to just hike it.                    
                </p>

                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/heron.jpg")}>
                    <img loading="lazy" alt="A Gray Heron" src="/bigtroutlake/heron.jpg"></img>
                </div>

                <p>I needed to stop for some lunch anyways, so I unloaded all of the gear onto the nice wooden stair platform that forms the entry to the portage.  It was here that a chipmunk accosted me as if he was expecting me to pay some sort of toll to pass.  He seemed particularly interested in the peanut butter and jelly sandwich that I had opened for lunch. Maybe he was just wondering how we manage get the nut into butter form?.  After a short lunch break I said goodbye to the little chipmunk guard, grabbed my canoe and gear and lugged it across the portage to Baby Joe Lake.  I was making good time so far, so I took the time to enjoy my surroundings as I paddled along.                    
                </p>
        
                <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Wooden Stairs at the Portage" src="/bigtroutlake/portagestairs.jpg" onClick={() => openImageView("/bigtroutlake/portagestairs.jpg")}></img>
                    <img loading="lazy" alt="The portage landing at Burnt Island" src="/bigtroutlake/burntislandlaunch.jpg" onClick={() => openImageView("/bigtroutlake/burntislandlaunch.jpg")}></img>
                </div>

                <p>It was a short paddle through Baby Joe Lake... that's probably why they call it Baby Joe Lake. It was here that I passed yet more canoes heading back, before arriving at my final portage of the day, an easy 200 meters into Burnt Island Lake. A relatively flat, easy path, finishing at a small inlet on the west end of the lake. This could be a long, hard paddle I thought to myself, hopefully the lake wasn’t too choppy. My intention was to get as far down Burnt Island as I could, making the next day’s travel a little shorter, but at least if things got rough I could just stop and camp as this was my destination lake for the day.                    
                </p>

                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/burntislandlake.jpg")}>
                    <img loading="lazy" alt="Looking out onto Burnt Island Lake" src="/bigtroutlake/burntislandlake.jpg"></img>
                </div>

                <p>I loaded up the canoe for one final haul and set off. It was after noon and the wind had started to pick up, but it was working slightly in my favor so it wasn’t all bad.  I set a goal to make it to the far end of Burnt Island Lake, hopefully getting a camp site close to the portage into Otter Slide. I paddled out from the portage landing and into the open lake. It was only slightly wavy and the wind blowing in a South East direction, I got myself into a steady rhythm and just paddled straight as I could down the lake.   
                </p>

                <p>A couple of hours later I was nearing the east end of the lake.  As I approached the Eastern shore, I came across a couple of guys in a canoe fishing in the waves, they were able to point me in the direction of the portage, so I knew I was in the right area.  There were a few campsites in the vicinity so I wanted to try and grab one of those if I could. The wind had really picked up and the lake was getting choppy, making paddling around much more difficult, I needed to get a campsite as soon as possible.                    
                </p>

                <p>I paddled myself over to the shore, just south of the portage to check out the campsite there. It wasn’t particularly nice and I decided it was worth it to continue looking.  I worked my way north along the shore, fighting the wind and waves, looking for another campsite.  The lake along here was shallow and rocky, and extended out for quite a way making navigation difficult. Even 100 meters off shore it would be easy to hit a big rock and never see it, the wind and waves making it that much more difficult.  I had to use extra caution as I paddled, but one of the benefits of the heavy aluminum canoe I was in was that it could take the beating with little more than a scratch or dent.                    
                </p>

                <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Camp view from lake" src="/bigtroutlake/campsite1.jpg" onClick={() => openImageView("/bigtroutlake/campsite1.jpg")}></img>
                    <img loading="lazy" alt="Camp view from inside" src="/bigtroutlake/campsite1-2.jpg" onClick={() => openImageView("/bigtroutlake/campsite1-2.jpg")}></img>
                </div>

                <p>I gradually made my way to the site just north of the portage and got out to take a look around.  The landing was a sloped stony beach like shore, great for pulling up a canoe. A short step up into a small but nicely laid out campsite and a couple of decent spots for a small tent. Not a campsite I would rave about by any means, but it did the job for a solo camper such as myself. It had a great view across the lake to the west, ideal for sunset viewing, and best of all I was really close to my next portage. It was an easy decision to spend my first night here.                     
                </p>              

                <p>I had arrived at my campsite at about 3:00pm, which was still pretty early in the day, giving me plenty of time to setup and explore camp. Today’s travel was roughly six and a half hours in total, pretty much what I had expected, so I was on track.  I setup camp quickly, I hung a rope for the food bag and setup the fire pit. Finding a suitable tree for a food hang can sometimes be a real chore, thankfully I found this nice tree leaning close to the water that made an ideal hanging location.     
                </p>

                <p>There was lots of small wood lying about camp, I gathered a decent pile before settling in so I could have a fire before going to bed.  Once my tent and sleeping area were all setup, and bags organized, I relaxed a bit and just enjoyed the peacefulness while reminiscing about the day’s travels. My day had been pretty physically demanding but I felt great, I felt accomplished and I was already looking forward to the next day’s travels which included plenty of creek paddling and hopefully some moose.                      
                </p>

                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/campsite1-3.jpg")}>
                    <img loading="lazy" alt="The Tent" src="/bigtroutlake/campsite1-3.jpg"></img>
                </div>

                <p>I was pretty hungry and had been looking forward to a steak and potatoes dinner all day, so I got a fire burning and put on a pot of cold lake water to boil. I took the still partially frozen steak out of the cooler a little earlier so it was nice and defrosted at this point. I sprinkled on some salt and pepper for taste before cooking.  When my water had boiled I placed in it four home grown Yukon gold potatoes I had brought with me and cooked them until they were nice and soft.                       
                </p>

                <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Boiling Potatoes" src="/bigtroutlake/potatoes.jpg" onClick={() => openImageView("/bigtroutlake/potatoes.jpg")}></img>
                    <img loading="lazy" alt="A steak, ready to cook" src="/bigtroutlake/steak.jpg" onClick={() => openImageView("/bigtroutlake/steak.jpg")}></img>
                </div>

                <p>I removed the potatoes from the water and placed them into some foil along with butter, salt and pepper. I then laid them in the coals of the fire to finish cooking.  I placed the steak directly on the grill over the fire, turning it once. It didn’t take long until the steak was perfectly cooked, very tender with little char on the outside and crispy fat edges. I don’t know if it was just hunger from travelling all day, but I think that steak might have been the best steak I’ve ever had.  The potatoes were also a hit, buttery and smoky with crispy skins.  When I was done, I packed up my garbage, took out a small snack for later, and hung the food bag for the night. It was going to get dark soon and I was planning on an early night.                     
                </p>

                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/burntislandsky.jpg")}>
                    <img loading="lazy" alt="A dark sky" src="/bigtroutlake/burntislandsky.jpg"></img>
                </div>

                <p>I had hoped to get a nice sunset view but as the evening wore on, the sky got really cloudy and it looked very much like rain. While the wind was going pretty steady all night, it never did rain at all. I spent the remainder of my night enjoying a fire and watching the lake. At about 9pm I put out the fire and got myself into the tent for a good night sleep.  To my surprise I didn’t really have any trouble sleeping, being alone here wasn't so bad, it was actually a very relaxing and enjoyable experience. As I lay there thinking about the day’s events, it wasn’t long before I drifted off to sleep.
                </p>
                
                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/fire1.jpg")}>
                    <img loading="lazy" alt="A fire on burnt island lake" src="/bigtroutlake/fire1.jpg"></img>
                </div>

        </div>  
        )
}

export default BigTroutLake2021page1;