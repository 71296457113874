import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

function Navbar() {

    const [isSticky, toggleSticky] = useState(false);

  const debounce = (func, wait) => {
    let timeout;
  
    return (...args) => {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
  
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const handleScroll = () => {
  
    let th = window.screen.height;
    if ( window.innerWidth <= 1024 ) 
      th *= 2;

    if ( window.pageYOffset > th ) 
        toggleSticky(true);
    else 
        toggleSticky(false);
  };
  
  useEffect(() => {
    
    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  window.addEventListener("scroll", debounce(handleScroll, 50));

    return (
      <div id="nav" className="nav-container">
        <nav className={isSticky ? 'sticky' : null}>
            <ul style={isSticky ? {marginTop:"0px"} : {marginTop:"60px"}}>
                <li id="nav-camplogs" className="nav-link"><Link to="/triplogs">Camp Logs</Link></li>
                <li id="nav-gallery" className="nav-link"><Link to="/gallery">Gallery</Link></li>
                <li id="nav-home" className="nav-link"><Link to="/">Home</Link></li>
            </ul>
        </nav>
      </div>
        
    )
}

export default Navbar;