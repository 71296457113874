import React, { useEffect } from 'react';

const Kiosk2023p4 = ({openImageView}) => {

    useEffect( ()=> {
        let e = document.getElementById('nav');
        if ( e !== undefined )
            e.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, []);
    
    return (
        <div className="kiosk2023_page4">

           <img loading="lazy" alt="Morning Calm" src="/kiosk2023/threemilecalm.jpg" onClick={() => openImageView("/kiosk2023/threemilecalm.jpg")}></img> 
          
           <p>I was up just after seven in the morning, reluctantly, I packed up my tent and other gear, making ready to leave camp.  I was in for a long paddle up Three Mile Lake followed by a nearly three-kilometer portage into Manitou Lake, I was not looking forward to it at all.  Three Mile Lake was nice and calm in the morning so I tried my best to get out while the getting was good and managed to leave the site just after nine after a quick oatmeal and protein breakfast.  I also filled all of my water bags and canteen before the portage so I was sure not to run out part way through.</p>
       
           <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Fall Colors" src="/kiosk2023/threemilecolor.jpg" onClick={() => openImageView("/kiosk2023/threemilecolor.jpg")}></img>
                    <img loading="lazy" alt="Portage Landing" src="/kiosk2023/portagelanding1.jpg" onClick={() => openImageView("/kiosk2023/portagelanding1.jpg")}></img>         
           </div>

           <p>The paddle up Three Mile was easy and enjoyable, the day was shaping up to be another hot one. I moved quickly; the lake was calm as I worked my way north up the western shore until I found the portage landing.  I wasn’t looking forward to this one, but I will say, you couldn’t ask for a nicer landing, a stoney, gradual slope with a log running alongside, nice and easy to get out and a large dry flat area to put your gear.  </p>
                 
           <div className="image-frame50 expandable-image">                
                    <p style={{marginTop:'5%'}}>I unloaded the canoe and headed down the path, I like to take the big packs first so I can see the path ahead before heading down with a canoe over my head.  In this case, the trail splits off a number of times so it was good to keep an eye out.  A good portion of this portage runs along an old logging road and is quite wide open and clear, with the exception of a growing number of fallen leaves.  The rest of the portage is pretty typical rocks and roots but mostly flat… that is until the Manitou Lake end. There is a long, very steep elevation change right at the portage landing on the Manitou Lake side.  Thankfully, the direction of my travel would have me moving my gear and canoe downhill, going back for the second carry sucked but at least I wasn’t carrying weight uphill. As I was moving my packs down the hill, I happened to come across a couple of guys who were in exactly that position, carrying a canoe up the steep terrain.  They jokingly asked me if they were almost there… with a chuckle I answered them. ‘Yeah, just a few more steps’… I was glad I wasn’t them.</p>
                    <img loading="lazy" alt="Portage Hill" src="/kiosk2023/portagehill.jpg" onClick={() => openImageView("/kiosk2023/portagehill.jpg")}></img>         
           </div>

           <p>I actually completed the portage much quicker than I expected, making it from camp to Manitou in just under three hours.  Despite how long that portage was, it wasn’t all that bad, quite flat for a good part and very wide and open. I was walking on a mat of fallen leaves which made things a little more dangerous when you can’t see what you’re stepping on, I had to be extra careful walking over rocks and roots.  The landing on Manitou Lake is very rocky and sits at the east end of the narrow inlet in the middle of the lake along the east shore.  My concerns for Manitou were well founded, by the time I was there the wind had picked up and the lake had become quite wavy.  I was a little concerned about going into the lake with the given conditions so I had to be careful and plan how I was going to make my way across.  I put the camera in the dry bag just in case and decided I would cross the narrow section of lake I was on directly, then I could carefully follow the shoreline north west to find a campsite. I wanted to be as close to shore as possible in case I got dumped, the wind and waves were a constant battle.  I wanted to find a site as soon as possible, but I was hoping to make some more distance up the lake if I could, to make the way out easier the next day.</p>
    
           <div className="image-frame50 expandable-image">                
                 <img loading="lazy" alt="Manitou Landing" src="/kiosk2023/manitoulanding.jpg" onClick={() => openImageView("/kiosk2023/manitoulanding.jpg")}></img>        
                 <img loading="lazy" alt="Manitou Landing" src="/kiosk2023/manitoulake.jpg" onClick={() => openImageView("/kiosk2023/manitoulake.jpg")}></img>         
           </div>
       
           <p>Carefully and as quickly as I could, I pushed away from the portage landing and made for the opposite shore.  The waves were pushing me around, I had to be very careful here, but I made it across.  It wasn’t getting any easier, now I was heading directly into the wind, going west along the shore, I had to push hard to make any distance.  I made it to the bend, following the shore north, finally making it to a small bay just around the bend and across from a large island.  </p>
           
           <p>I pulled the canoe up to the large stoney beach, where there was a large fire pit and log seating all around, I liked the look of it, but it was very exposed.  Further up, there is a steep hill with a set of wooden steps in the ground bringing you back into a more sheltered camp site with another fire pit and more log seating, though, not as nice.  There was plenty of firewood around the area and a couple decent spots for a tent.  This seemed like a decent site, and with any luck, the wind would calm down and I could enjoy my fire on the beach.</p>
       
           <div className="image-frame50 expandable-image">                
                 <img loading="lazy" alt="Manitou Beach" src="/kiosk2023/manitoubeach.jpg" onClick={() => openImageView("/kiosk2023/manitoubeach.jpg")}></img>        
                 <img loading="lazy" alt="Manitou Camp" src="/kiosk2023/manitoucamp.jpg" onClick={() => openImageView("/kiosk2023/manitoucamp.jpg")}></img>         
           </div>

           <div className="image-frame50 expandable-image">                
                 <img loading="lazy" alt="Wood Pile" src="/kiosk2023/woodpile.jpg" onClick={() => openImageView("/kiosk2023/woodpile.jpg")}></img>        
                 <p>I brought up all the gear to the top level and setup my tent in a nice spot back from the site a bit.  I easily found a solid tree to hang the food bag so I hung my rope for later, then decided to make some lunch.  This was the earliest I had made a site the entire trip, so I had lots of time to kill before dark which was nice.   I found some really nice firewood and cut up some logs in preparation for a roaring fire.  I like to end a good trip with a nice fire if I can and being on a beach afforded me a unique opportunity.    I loaded up so I didn’t have to skimp on wood that night.</p>                          
           </div>

           <p>The day had become quite hot like the previous, just a little more cloudy and very wavy.  I decided to go out for a bit of a swim to cool off then lay in the sun a bit.  I roamed around, checking out the cool rocks on the beach, and I found a clam feeding in the shallows.  It was another beautiful day and it appeared that I had Manitou Lake all to myself, I didn’t see anyone that day or the next.</p>
       
           <p>I spent the day lazing around camp and eating, dinner was rice and beef jerky again.  Once I had cleaned up, I sat by the fire watching the sun go down with some angry looking clouds in the background.  As the evening came, the lake gradually calmed down and the wind was nothing more than the occasional gentle breeze. I sat by the fire, finding the rocky sand beach quite comfortable, and watching as the sky turned to pure dark. You couldn’t see anything past the fire.  It became dead quiet except for the occasional crackling of the fire.  I felt a sense of such solitude being just a tiny point alone on such a large lake, I enjoyed this feeling as I gazed into the fire, and my mind drifted off. </p>
        
           <div className="image-frame50 expandable-image">                
                 <img loading="lazy" alt="Manitou Sunset" src="/kiosk2023/manitousunset.jpg" onClick={() => openImageView("/kiosk2023/manitousunset.jpg")}></img>        
                 <img loading="lazy" alt="Manitou Fire" src="/kiosk2023/manitoufire.jpg" onClick={() => openImageView("/kiosk2023/manitoufire.jpg")}></img>         
           </div>
          
           <p>It was getting late, after eleven anyways, and I needed to get some sleep before the next day. Tomorrow would be home time, and I had plenty of travelling to do before I got there.  I fell asleep quickly and as I did every night, had a pretty solid sleep.  </p>
        </div>  
        )
}

export default Kiosk2023p4;