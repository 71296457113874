import React, {useState, useRef, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import GalleryImageView from './galleryview';
import Slides from './imagelist.json';

const Gallery = () => {
   
    const NUM_SLIDES = Slides.images.length;
    const SLIDES_PER_PAGE = 20;
    const SLIDES_PER_ROW = 5;
    const [currPage, setPage] = useState(0);
    const [currSlide, setSlide] = useState(1)
    const [imageViewActive, setImageViewActive] = useState(-1);

    const galRef = useRef(null);

    const { slide } = useParams();
    const history = useHistory();

    useEffect( () => {
        
        if ( slide > 0 && slide <= NUM_SLIDES ) {
            setSlide(parseInt(currSlide));
            setImageViewActive(Slides.images[currSlide].url);
            history.push('/gallery');
        }
    }, [history, slide, currSlide, NUM_SLIDES]);


    const handleImageClick = (imagePath) => {

        if ( imageViewActive === -1 )
            setImageViewActive(imagePath);
    }

    const displayThumbnails = () => {

        const getThumbs = () => { 

           let slideList = [];

           for ( let i = 0; i < (SLIDES_PER_PAGE / SLIDES_PER_ROW); i++ ) {
               for ( let j = 0; j < SLIDES_PER_ROW; j++ ) {

                let slide = ( currPage * SLIDES_PER_PAGE ) + (i * SLIDES_PER_ROW) + j + 1;
                
                if ( slide > NUM_SLIDES )
                    continue;

                let fn =  Slides.images[slide-1].url;

                slideList.push(<div className="thumb-image" key={slide} onClick={() => { setSlide(slide-1); handleImageClick(fn) } }><img alt="" src={fn} /></div>)
               }
           }
    
           return slideList;
        }

        return ( 
            <div className="gallery-grid">
                {getThumbs()}                
            </div>
        )
    }

    const handlePageSelect = (page) => {

        let numPages = NUM_SLIDES / SLIDES_PER_PAGE + 1;

        if ( page >= 1 && page <= numPages )
            setPage(page-1);

        galRef.current.scrollIntoView();

        return false;
    }

    let pageLinks = () => {
        
        let numPages = Math.floor((NUM_SLIDES / SLIDES_PER_PAGE) + 1);

        let pLinks = [];

        for ( let i = 1; i <= numPages; i++ ) {
            let id = 'page-'+i;

            if ( currPage === i-1 ) 
                pLinks.push(<div className="page-link page-sel" key={id}>{i}</div>)
            else
                pLinks.push(<div className="page-link" key={id} onClick={() => handlePageSelect(i)}>{i}</div>)
        }
        return pLinks;
    }

    let pageCount = () => {

        let numPages = Math.floor( (NUM_SLIDES / SLIDES_PER_PAGE) + 1);

        return <div className="page-count">{currPage+1} / {numPages}</div>
    }

    const handlePrevPage = () => {

        if ( currPage > 0 ) {
             setPage(currPage-1);
             galRef.current.scrollIntoView();
            }

        return false;
    }

    const handleNextPage = () => {
        
        let numPages = NUM_SLIDES / SLIDES_PER_PAGE + 1;

        if ( currPage < (numPages - 2)) {
            setPage(currPage + 1);
            galRef.current.scrollIntoView();
           }

     return false;
    }

    return(
       
        <div ref={galRef} id="gallery" className="gallery-container">
         <Helmet>
                <title>Algonquin Adventures - Photo Gallery</title>
                <meta name="description" content="This outdoor slide gallery contains a collection of high quality pictures we have taken while travelling through Algonqion Park. The pictures include natural scenery and wildlife.  High resolution versions are available."></meta>  
                <base href="/gallery/"></base>
         </Helmet>
         
         { imageViewActive !== -1 ? <GalleryImageView slideIndex={currSlide} setSlideIndex={setSlide} numSlides={NUM_SLIDES} imageViewActive={imageViewActive} setImageViewActive={setImageViewActive} /> : null }
         
         {displayThumbnails()}

         <div className="page-nav-container">
             <div id="prev-page-btn" onClick={handlePrevPage}>&#x0003C;</div>
                {window.innerWidth > 768 ? pageLinks() : pageCount() }
             <div id="next-page-btn" onClick={handleNextPage}>&#x0003E;</div>
         </div>
    </div>    
    )
}

export default Gallery;