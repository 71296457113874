import React from "react";

const ImageView = ({closeImageView, imgSrc}) => {

    return (
            <div className="image-view-container">
                <div className="image-frame">
                    <img onClick={closeImageView} alt="" src={imgSrc} />
                </div>
            </div>
    )
}

export default ImageView;