import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ImageView from './imageview';

const LittleTrout2019 = () => {

    const [imageViewState, setImageViewState] = useState(null);

    useEffect( ()=> {
        let e = document.getElementById('nav');
        if ( e !== undefined )
            e.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },[]);

    return (
        <div className="triplog">
            <Helmet>
                <title>Little Trout Lake 2019</title>
                <meta name="description" content="Little Trout Lake Algonquin Park. High quality trout, lake trout, brook trout fishing. Algonquin Park Access Point at Magnetawan Lake, accessible from Highway 11 is the nearest point of entry if you wish to do some camping on this lake. Travel time is approximately 4-5 hours canoeing and portaging, depending on weather."></meta>  
            </Helmet>

            {imageViewState ? <ImageView closeImageView={() => setImageViewState(null)} imgSrc={imageViewState} /> : null }
           
            <h3>Little Trout Lake</h3>
            <p>For this year’s Algonquin trip, I decided to try something different. I managed to talk a very good friend and long-time fishing buddy, Nick, into a four day interior fishing adventure.  What made this time different was the time of year. For my entire life, all of my Algonquin trips have taken place in the early summer or early-mid fall. But this year, we wanted to try our luck at trout fishing, so we decided to go earlier than usual, in the spring, May 15th to be exact. Living on Lake Erie all of my life, I’ve always been an avid bass fisherman, and though I once caught a trout when I was a kid, it has been many years and I have never really tried to target them specifically. I spent some time learning about the subject, hoping to gain an advantage. I bought some new lures and trout bait to try out, I wanted to have lots of options since I wasn't sure what would work best. Spring was the best time for trout fishing, it would be cold but we could manage it and with any luck we wouldn’t have any bugs.</p>
    
            <img className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/tripmap.jpg") }} alt="map of route" width="80%" src="/littletroutlake/tripmap.jpg"></img>

            <p>
            The plan was to start at access point #3, Magnetawan Lake, on the west side of the park. I generally prefer the north and west sides of the park as they seem to offer a quieter experience, away from the busy highway 60 corridor.  We would head to Little Trout Lake to make camp, from there we could day trip out to explore and fish.  We made the reservations well ahead of time to make sure we got a spot, I really wasn't sure how busy it would be mid-May. As the day neared I got more excited about the trip, I could hardly wait, it’s been almost a year since I had been in Algonquin and I was itching to be there again, especially with such a chance for some good fishing. 
            </p>

            <p>
            It had been a pretty long winter, and it seemed like the cold wanted to hang a while longer. My excitement turned to worry when shortly before the trip date I receive a notice from Ontario Parks that my reservation for this year’s trip may have to be cancelled or at least delayed. Turns out the ice was still hanging around and the park would be opening later than expected.  Well, that really sucks, I thought to myself, still hoping maybe it would be ok.  I called the park to confirm and as it turned out, our trip date just happened to fall on the late opening date and was therefore unaffected. Woohoo! We were good to go.  
            </p>

            <img id="late-notice" alt="late notice" width="80%" src="/littletroutlake/lateopening.jpg"></img>

            <p>
            The trip was to be four days long. We would leave real early Wednesday morning and be at camp later that day. Our trip out would be Saturday giving us each one day of rest before work the next week… perfect. I try to do a lot of my preparations well before the trip, this gives me lots of time to think about and hopefully remember all the things I forgot. I charge the camera batteries, clear the cards, etc. On this occasion I was updating my GPS and figuring out my route on Garmin Bootcamp, it’s a handy tool that allows you to design and upload your trip route to your GPS. As I was looking at the route, I noticed a long dirt & gravel road that leads you into the park. At some point along the way there is a fork in the road, one side leading to a dead end. I took note, hoping to avoid a problem when I was driving in.  
            </p>

            <p>
            When Tuesday came, I had all of the gear packed, I loaded it into the van. I strapped the canoe to the roof and was ready to go.  I tried my best to get a few hours of sleep before leaving, tonight was the night. At about 2 AM Wednesday morning I’m up, I quickly grab a coffee as I go over a list in my head. Is there anything I forgot? I grab some food for the road, my GPS, kiss my sleeping wife and kids goodbye, then closing the door quietly behind me, I head out on the long drive.
            </p>

            <p>
            I’m on the road now, the drive to access point #3 is about 5 hours from me, but I still need to pick up Nick. I arrived at Nick's house just after 3AM, we quickly pack his gear in the back and get moving again.  The weather was clear and quiet overnight and the traffic was light, making the drive mostly uneventful. We arrived at the park permit office in Kearney around 7:30AM to pickup our permits, gave our legs a stretch and continued towards the park boundary.  On the way into the park we ran into a little bit of trouble, the trip may very well have ended before it had begun.
            </p>
            <div className="image-text">
            <img loading="lazy" alt="kearney office" width="80%" src="/littletroutlake/kearney.jpg"></img>
            <p>The office in Kearney where we picked up our Park Permits for the trip.</p>
            </div>
            <p>
            Just off of highway 518 you turn onto a dirt and gravel road called Forestry Tower Road and follow it all the way to the access point.  Along this road there are a couple of forks, one of them I had noticed on the GPS map during my planning. I remembered just to stay right and I would be fine. Well for some reason, since I was on a dirt road leading my right into the access point I stopped paying attention to my GPS and just went on instinct, which told me to keep right.  The road was much of the same but started to become steep and rocky and was looking less stable in general. We had to be almost there anyway, I thought to myself.  I had to drive very slowly and carefully so as to not roll the van off some of the steep edges along the thinning road. We came to the top of a steep hill with a fair bit of water at the bottom, how deep could it be, right? I let the van pick up some speed on the way down, whoosh, we went right through it.  We came to a similar spot a bit further down but each obstacle became more nerve wracking than the next until we were completely stopped in our tracks. We had come to what once was a road, but was now more like a small lake. There was no driving through this.
             </p>

             <img loading="lazy" alt="fork in road" width="80%" src="/littletroutlake/forkinroad.jpg"></img>

            <p>
            I made a 10 point turn on the firmest ground I could find and went back the other way.  We managed to survive the road back a second time and once we made it back to the fork, we decided to go in the other direction.  As it turns out, the fork in the road I was thinking of was still a way up the road, and by mixing them up we had inadvertently made our way towards some sort of mine. 
            </p>

            <p>
            Despite our slight detour we managed to get to our access point destination around 8:30AM Wednesday morning. We unloaded the canoe and all of our gear from the van. We spoke to a few guys who were also using the access point but going to different lakes, it was pretty quiet otherwise.  The morning was calm but cloudy and threatening rain but so far had behaved. We put the canoe in the water and loaded the gear.  As we launched our canoe and headed to our first portage it began to sprinkle lightly.
            </p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/accesspoint.jpg") }} alt="access point" width="80%" src="/littletroutlake/accesspoint.jpg"></img>
            <p>The dock at the Magnetawan Lake Access Point, the parking lot far in the background.</p>
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/magnetawanlake.jpg") }} alt="magnetawan lake" width="80%" src="/littletroutlake/magnetawanlake.jpg"></img>
            <p>A cold gray day for a paddle.</p>
            </div>
            <p>
            The first portage is right around the corner from the dock at the access point, barely a 3 minute paddle and we are already unloading the canoe.  Our first portage was a quick 135m into Hambone Lake and was easily passed. By the time we had started paddling into Hambone the rain was letting up and the sun was peeking out. Another canoe passed us at a pretty good pace as we stopped to check our map and figure out where our next portage was located.  </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/hambone.jpg") }} alt="hambone lake" width="80%" src="/littletroutlake/hambone.jpg"></img>

            <p>
                Hambone was a nice little lake, with a few campsites but we had a long way to go yet.  We had a little trouble finding the entrance to our next portage as it was poorly marked on the map I had. We had to back track a little once we realized we had gone too far but it’s a very small lake so it only took us an extra five minutes.  We eased up to the landing and started unloading the canoe, this was the 295m portage to Ralph Bice Lake.
            </p>

            <div className="portage2-sign">
            <img loading="lazy" alt="portage #2" width="80%" src="/littletroutlake/portage2.jpg"></img>
            </div>
            <p>
            This portage was also a quick one, though a bit muddy at times. The canoe landing at the end of the portage was muddy and rocky in low water making it difficult to load the canoe and get in. Somehow we managed to do it without getting stuck.  When we got to Ralph Bice Lake we stopped for a quick rest before continueing.  We were on the very south east of Ralph Bice Lake and we needed to paddle all the way to the north western tip to our next portage.  This was going to be a long paddle. 
            </p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/ralphbice.jpg") }} alt="ralph bice lake entrance" width="80%" src="/littletroutlake/ralphbice.jpg"></img>
            <p>Looking out into Ralph Bice Lake from the portage.</p>
            </div>
            <p>
            Ralph Bice Lake is a pretty large lake when your looking at it from end to end. We got extremely lucky in the weather department as the lake was calm as could be, making our nearly 6km paddle much safer and easier. Ralph Bice is such a big and beautiful lake but I wouldn't want to be caught out in it in bad weather. After close to 2 hours the next portage was in sight, we once again unloaded our gear and hauled up the canoe. 
            </p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/portage3.jpg") }} alt="portage #3" width="80%" src="/littletroutlake/portage3.jpg"></img>
            <p>Look at how bare the trees were this time of year, and not a bug in sight.</p>
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/portage3_2.jpg") }} alt="portage #3-2" width="80%" src="/littletroutlake/portage3_2.jpg"></img>
            </div>
            <p>
            This would be our last and longest portage of the day, at 435m it leads into Little Trout Lake, our final destination.  This portage was quite muddy making it feel longer than it should, but we made it through and continued on our way. As we launched into Little Trout Lake we once again referenced the map, we were now on a quest to find a campsite and there was a particular site on the north side I had hoped to get as I’ve heard it’s nice.
            </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/littletroutputin.jpg") }} alt="little trout lake put in" width="80%" src="/littletroutlake/littletroutputin.jpg"></img>

            <p>
            As we paddled toward our site we noticed another canoe travelling parallel to us across the lake but heading in the same direction.  We didn’t want them taking our site so we paddled hard in hopes that we could get there first.  It was a well fought battle on both sides, though in the end we were surprised to find out that the site was already occupied.  We continued east to the furthest end of the lake, finally settling on a different campsite on the north-east side of the lake. 
            </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/campsiteview.jpg") }} alt="campsite" width="80%" src="/littletroutlake/campsiteview.jpg"></img>
            
            <p>
            This campsite wasn’t the nicest site I’ve seen but it served our purpose and it was close to the portage we were taking the next day.  The landing was not sloped in any way, the edge of the site just sort of dropped off into the water. The water at the edge was too deep to step into with boots in most cases without getting your feet soaked. This meant the easiest way of getting in and out of the canoe was to balance along a large Cedar tree sticking out into the water as it provided a relatively flat platform.  Doing this alone, never mind with all the gear and the expensive camera equipment, knowing just how ice cold that water was, made each step a nerve wracking balancing act but we managed without either of us taking a swim.  
            </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/campsiteview2.jpg") }} alt="campsite view" width="80%" src="/littletroutlake/campsiteview2.jpg"></img>

            <p>
            One of the things that really stuck out about the site, and even the trails up until then, was the sheer amount of moose crap everywhere. I have seen a lot of moose crap but this was ridiculous. You couldn’t go 5 feet in any direction without finding a large pile, much of it fresh. I literally had to scoop mounds of it off the tent mat before laying the tent down because there was no room. That being said, we didn’t see one damn moose the entire time, not a single one. Wtf? Maybe next time…
            </p>

            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/moosepoop.jpg") }} alt="moose poop" width="80%" src="/littletroutlake/moosepoop.jpg"></img>
            <p>Signs of moose were everywhere but we didn't see one the entire trip.</p>
            </div>

            <p>
            Nick and I had taken a look at another site that wasn’t as nice before deciding this was the one. We did a look over, it had everything we needed, fire pit, boom box, the tent mat was a bit small and full of moose crap, but it’ll do. But something was missing. We didn’t have a grate for our fire. I brought the stove, just in case, but much prefer to cook and boil water on the fire when I can. We took a paddle around to the other campsites within the general area and found one at the end on the very north eastern corner of the lake which had a second grate.  We stole the extra grate and brought it back to our site, now we were good to go. We setup camp and made some food, then settled in for our first night. 
            </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/campsite.jpg") }} alt="campsite" width="80%" src="/littletroutlake/campsite.jpg"></img>

            <p>
            Dinner consisted of sausages and brown beans, good recovery food.  The weather up until that point had been pretty nice so we decided to hold off on setting up the tarp just yet.  We hung the food bag before relaxing around the fire a while. The evening was getting cold so having a blazing fire was a must, when bed time came we wrapped outselves in layers before getting inside our sleeping bags.  It had been a long day so it was going to be an early night, but tomorrow was a new day and would involve a lot of fishing. 
            </p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/morning1.jpg") }} alt="morning" width="80%" src="/littletroutlake/morning1.jpg"></img>
            <p>The morning sun evaporating the mist on the lake.</p>
            </div>

            <p>
            As was usual, I couldn’t sleep in the next morning. Whenever I camp in Algonquin I find myself getting up early, generally right at or slightly after dawn.  I find morning in Algonquin incredibly refreshing, seeing the mist float along a mirror flat lake, listening to the noises of the birds waking, it’s extremely serene.  Nick was still passed out, I put him through a tough day so I let him be. I took this opportunity to snap some pictures from shore as well as from the canoe. I paddled around a little in the mist by myself, a somehow meditative experience. Once I got back to shore I started setting up a fire and brought down the food bag in preparation for breakfast. Also, I really needed a coffee, so I got some water boiling. 
            </p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/campsiteview3.jpg") }} alt="morning" width="80%" src="/littletroutlake/campsiteview3.jpg"></img>
            <p>Looking out from camp, a lake so calm it's like a sheet of glass.</p>
            </div>
            <p>
            Breakfast that morning consisted of eggs and bacon, cooked over the fire. I can’t tell you how many times we almost knocked bacon into the fire but most of it survived our creative attempts to cook.  I made coffee with some freshly boiled lake water. I have a nice easy way to make coffee on the go. You need a fork, a spoon, a coffee cup, some coffee grinds and a few filters. I put a scoop of coffee into a filter, bunch it up at the top and keep it together using the fork. I now have something like a coffee “bag” and I use it much like you would make tea. I place the fork with coffee bag into the coffee cup, pour some hot water from the fire over it and let it steep a minute or two. Remove the bag, squeezing the excess with the spoon and there you have it, good strong camp coffee. Throw the filter bag into the fire and burn it for no waste.  
            </p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/bacon.jpg") }} alt="cooking bacon" width="80%" src="/littletroutlake/bacon.jpg"></img>
            <p>Mmmmm bacon everywhere....</p>
            </div>
            <p>
            After coffee was made, we discussed our soreness from the trip in and plans for the day ahead. We had two more full days in the bush and one of them was to be a day trip excursion to fish. We decided to do our day trip on day two so as to reserve one day to rest before the trip back out to civilization.  We readied a day bag with food and drinks as well as our fishing gear and headed out shortly after breakfast.
            </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/daytrip.jpg") }} alt="day trip" width="80%" src="/littletroutlake/daytrip.jpg"></img>

            <p>
            The plan was to make our way south of our campsite and take the short portage to Queer Lake, where we would fish a little bit, then we would make our way over to the Tim River via the 1450m portage and try fishing the rapids for a while.  The day was beautiful and sunny, at times it was easy to forget that it wasn’t summer time, travel in light clothing was the plan but we brought rain gear just in case.
            </p>

            <img loading="lazy" alt="day trip" width="80%" src="/littletroutlake/portage4.jpg"></img>

            <p>
            The very short 175m portage into Queer Lake was easily traversed, it felt easy especially since we were carrying only a small fraction of our gear with us. We entered a calm Queer Lake and while we did cast our lines a few times, our primary goal was to get to Tim River so we made it quick and continued on our way. 
            </p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/portage4_2.jpg") }} alt="day trip" width="80%" src="/littletroutlake/portage4_2.jpg"></img>
            <p>There is a stream hidden amonst the brush here, it runs between Little Trout and Queer Lake.</p>
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/portage4_3.jpg") }} alt="day trip" width="80%" src="/littletroutlake/portage4_3.jpg"></img>
            <p>The view onto Queer Lake from the end of the 175 meter portage.</p>
            </div>
            <p>
            As we paddled through Queer Lake we came across an occupied campsite, the fellow said hello and advised us that he had not as of yet seen any fish. We continued on our way undiscouraged. As we floated up to the long 1450m portage it had sort of an ominous feel. The entrance was surrounded by tall trees and massive roots, a foreboding yet beautiful entrance leading deep into the forest, I snapped some pictures though I don’t think they do it any justice.
            </p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/portage5.jpg") }} alt="day trip" width="80%" src="/littletroutlake/portage5.jpg"></img>
            <p>The entrance to the 1450 meter portage to the Tim River.</p>
            </div>

            <p>
            We pulled up the canoe and left it a little ways up the path but out of the way. There were three other canoes stashed there as well. We figured people were up on the trail ahead and would be back. Our intention was not to bring the canoe but only walk the trail and fish the river from shore once we arrived.  We started off on the trail, looking forward to the adventure but mostly to pulling in some brook trout. The portage was muddy in spots and with lots of ups and downs made for a very long but rather easy walk. It’s tough to know what 1450 meters feels like when you’re walking a path that varies so much, but eventually we came out to an opening along the Tim River where we decided to park ourselves for a while.  
            </p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/timriver1.jpg") }} alt="tim river" width="80%" src="/littletroutlake/timriver1.jpg"></img>
            <p>Part of the rapids along the Tim River</p>
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/timriver2.jpg") }} alt="tim river" width="80%" src="/littletroutlake/timriver2.jpg"></img>
            <p>This pool at the end of the rapids contained some hungry brook trout.</p>
            </div>
            <p>
            Nick and I setup our poles but I managed to get my line in the river first. I was using a small green crankbait and casting my line, pulling back against the current. Almost immediately I had a bite. Shortly after I hooked one and pulled in a small brook trout. It was my first one and I was happy. Soon after that, Nick caught his first of the trip. We fished this area a little while before it started to rain, we packed up and started the trip back. We kept just one fish for a snack later on. 
            </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/brooktrout.jpg") }} alt="tim river" width="80%" src="/littletroutlake/brooktrout.jpg"></img>

            <p>
            By the time we got back to the beginning of the 1450m portage the rain was really coming down. We tried to wait it out but it only slowed a little by the time we left.
            </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/rain2.jpg") }} alt="raining" width="80%" src="/littletroutlake/rain2.jpg"></img>
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/rain1.jpg") }} alt="raining" width="80%" src="/littletroutlake/rain1.jpg"></img>

            <p>
            We paddled back through Queer Lake and once again over the small portage into Little Trout Lake. By the time we were on Little Trout Lake the rain was starting to let up and we were still pretty dry.  We decided to filet our single brook trout on a small island not too far from our campsite, only bringing back the meat.
            </p>

            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/island1.jpg") }} alt="island" width="80%" src="/littletroutlake/island1.jpg"></img>
            <p>A small island on Little Trout Lake.</p>
            </div>

            <p>
            Once back, we started a fire and made dinner. We coated the chunks of trout meat in breading and fried it on the fire in a little oil and while it was barely enough for a snack between us two, it was the tastiest fish I’ve ever eaten.  
            </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/bigfire.jpg") }} alt="big fire" width="80%" src="/littletroutlake/bigfire.jpg"></img>

            <p>
            As we were spending the evening relaxing by the fire, the wind had picked up and the rain was starting to come down harder. Nick and I were sitting across from each other at the fire.  With an immediate sense of urgency we knowingly just sort of nodded at each other. We knew what had to be done, and quickly. Without missing a step we both went to work setting up the rope and large tarp I had brought with us for just such an occasion. Within a few minutes we had a pretty solid shelter over us, and the best part was that we had managed to keep it close enough to the fire that we could be under the shelter yet still at the fire without risk of actually setting the tarp on fire.  It rained on and off throughout the night, we spent our evening enjoying a nice big fire with some large logs we managed to find.  A couple of them were too big to cut so we just burned them in half, then threw the halves into the fire.  A large fire was especially nice because the nights were just above freezing. Just to sleep comfortably I had multiple layers of clothing on, with a toque and gloves plus I was inside my sleeping bag, on top of an air mat.
            </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/coldmorning.jpg") }} alt="cold morning" width="80%" src="/littletroutlake/coldmorning.jpg"></img>

            <p>
            The next morning was cold and damp but eventually warmed up to become comfortable. I once again started the day with a fire and some coffee, just enjoying the slow morning.  We didn’t have any plans for the day, we would mostly hang around and fish around camp, nothing too strenuous, then tomorrow would be our trip back.  
            </p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/hotwater.jpg") }} alt="hot water" width="80%" src="/littletroutlake/hotwater.jpg"></img>
            <p>Boiling some water for coffee and breakfast.</p>
            </div>
            <p>
            We had a simple breakfast of oatmeal and coffee and discussed the day’s possibilities. We decided to do more fishing and exploring in our general area.  Again we packed a day pack with some food and drinks as well as the fishing gear.  We puttered around the lake, making a short stop on an island across from our camp just to explore it.
            </p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/island2.jpg") }} alt="island view" width="80%" src="/littletroutlake/island2.jpg"></img>
            <p>A couple of giant boulders in the middle of the island.</p>
            </div>
            <div className="image-text">
            <div className="moss">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/moss.jpg") }} alt="moss" width="50%" src="/littletroutlake/moss.jpg"></img>
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/treefungi.jpg") }} alt="moss" width="50%" src="/littletroutlake/treefungi.jpg"></img>
            </div>
            <p>We found this soft looking moss on the island, as well as some sort of tree fungus.</p>
            </div>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/island3.jpg") }} alt="island view" width="80%" src="/littletroutlake/island3.jpg"></img>
            <p>A view of Little Trout Lake from the island.</p>
            </div>

            <p>
            We ended up going back to the 175m portage and making our way into Queer Lake. Once there, we spent some time trolling sections of the lake as well as casting into a small stream not far from the portage.  It is here that we caught yet another brook trout, this time on a silver diving bait. We released this one as well.  We spent a better part of our day exploring and fishing this lake without catching anymore fish, so we went back and fished some more in Little Trout Lake.  Unfortunately our luck didn't change that day.
            </p>
            
            <div className="image-text">
            <div className="frog">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/frog1.jpg") }} alt="frog" width="80%" src="/littletroutlake/frog1.jpg"></img>        
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/frog2.jpg") }} alt="frog" width="80%" src="/littletroutlake/frog2.jpg"></img> 
            </div>
                <p>Found this little guy hanging around the portage, had some really cool yellow colors to him.</p>
            </div>
            <hr></hr>
            

            <p>
            We made our way back to camp and settled in for the night. This was our last night here so we built up a good fire and ate lots of food and just enjoyed being in Algonquin for a while longer as tomorrow would be the long trip back home.
            </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/moon.jpg") }} alt="evening moon" width="80%" src="/littletroutlake/moon.jpg"></img>

            <p>
            We survived another cold night to wake up to another beautiful Algonquin morning.  The sun was out and it was warm with very little if any wind. The day was starting out on a positive note. 
            </p>
         
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/morning2.jpg") }} alt="morning" width="80%" src="/littletroutlake/morning2.jpg"></img>        
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/morning3.jpg") }} alt="morning" width="80%" src="/littletroutlake/morning3.jpg"></img>

            <p>
            We ate a good breakfast and packed up camp, making sure to leave no trace behind. We said goodbye to our campsite and started our paddle back. 
            </p>

            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/packed.jpg") }} alt="all packed up" width="80%" src="/littletroutlake/packed.jpg"></img>
            <p>Packed and ready to go.</p>
            </div>
            <p>
            We crossed Little Trout Lake easily and made our way to our first portage. Our path had been made muddier by the recent rain but we managed slowly but surely to trudge our way through. I still think buying new rain boots for this trip was one of the best decisions I’ve ever made as they sure came in handy.
            </p> 
            
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/loon.jpg") }} alt="loon" width="80%" src="/littletroutlake/loon.jpg"></img>
            <p>This loon dove into the water before I could get a better shot.</p>
            </div>

            <p>
            We soon found ourselves at the north end of Ralph Bice Lake with a very long paddle ahead of us. We were lucky that day as the lake was pretty calm, very little wind or waves, so we easily made the long paddle back to the 295m portage into Hambone. We completed the portage and entered Hambone Lake, once there it was a relatively quick paddle to our final and shortest portage back into Magnetawan Lake.
            </p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/group.jpg") }} alt="groups of people" width="80%" src="/littletroutlake/group.jpg"></img>
            <p>The busy portage on Hambone lake.</p>
            </div>
            <p>
            When we arrived at the portage, we unloaded and readied for the haul but were interrupted by a few groups of people heading the opposite direction. Many of these people were, I would guess, college age but we noticed that some of them weren’t dressed for the cold, some of them were even portaging in flip flop sandals. I sure hope they weren’t going long or far.  
            </p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/group2.jpg") }} alt="groups of people" width="80%" src="/littletroutlake/group2.jpg"></img>
            <p>A muddy start on this portage.</p>
            </div>
            <p>
               We completed our final portage and made our way back to the access point.  The trip back was done in less time than the trip in by about an hour.  As we pulled up to the dock, it was hard not to notice how packed the parking lot had become, we must have just missed the spring fishing rush.  We loaded up the van and strapped the canoe on the roof, then made our way back onto the dirt road that leads to highway 518. The road home was long and I was tired but it would be good to sleep in a warm bed at home tonight. I dropped Nick off at home, making my stay quick as I wanted to get back, I was still one and a half hours from home. 
               </p>
               
               <p>
                   When I finally pulled into my driveway, I felt a sense of relief and accomplishment. We had completed our trip, safely, we caught fish and I had made it home, time to rest and then go back to regular life.  I unloaded the canoe and van because I just wanted it over with, so while I was still feeling the last effects of the energy drinks and adrenaline, I unloaded and just sort of laid out everything in my garage for later sorting and cleaning. With the canoe in the back yard, I went in, showered and melted into the couch for a while. It was good to be home.
            </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/littletroutlake/end.jpg") }} alt="the end" width="80%" src="/littletroutlake/end.jpg"></img>


        </div>
    )
}

export default LittleTrout2019;