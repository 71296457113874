import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import Navbar from './components/navbar.jsx';
import Content from './components/content.jsx';
import Footer from './components/footer.jsx';
import TripLogs from './components/triplogs.jsx';
import Gallery from './components/gallery.jsx';

function App() {

  const Home = () => { 
    return (
     <div>
      <Content />
     </div>
  )}

  const Error = () => { 
    return (
      <div>
        <h2>Error 404 - Page not found!</h2>
      </div>
    )}

  return (
     <div className="app-container">
      <h1>MY ALGONQUIN ADVENTURES</h1>
        <div className="header-container">
        </div>
      <Navbar />
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/triplogs' component={TripLogs} />
        <Route path='/gallery/:slide' component={Gallery} />
        <Route path='/gallery' component={Gallery} />
        <Route component={Error} />
      </Switch>
      <Footer />
   </div>
  );
}

export default App;
