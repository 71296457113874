import React, { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import ImageView from './imageview';

const MinkLake2018 = () => {

    const [imageViewState, setImageViewState] = useState(null);

    useEffect( ()=> {
        let e = document.getElementById('nav');
        if ( e !== undefined )
            e.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },[]);

    return (
        <div className="triplog">
              <Helmet>
                <title>Mink Lake 2018</title>
                <meta name="description" content="Mink Lake Algonquin Park. Algonquin Park Access Point at Kiosk Lake, accessible from Highway 17 is the nearest point of entry if you wish to do some camping on this lake. Travel time is approximately 3-4 hours canoeing and portaging, depending on weather."></meta>
            </Helmet>

            {imageViewState ? <ImageView closeImageView={() => setImageViewState(null)} imgSrc={imageViewState} /> : null }
           
            <h3>Mink Lake</h3>
            <p>
            So this was it, the year to finally get back to some serious camping.  It had been quite a few years since I last made the trip into Algonquin Park, life is busy and my two girls were a bit too young to take trudging through the bush just yet. But this year would be different, with my youngest Maya, turning 6 soon and my oldest Petra, 10, they were at a great age to start exploring the wilderness as I did when I was a kid.  This would be a great opportunity to teach them some basic survival skills as well as how to enjoy and respect nature.  
            I started planning.</p>

            <p> For their first experience in Algonquin I wanted to take them into the interior but I didn’t want to spoil the adventure by overdoing it with long heavy portages or endless paddling.  I found a nice looking lake I couldn't recall ever being to before called Mink Lake.  There would be minimal portaging and relatively little paddling but still a couple of lakes in, perfect!  Got the reservations / permits and started packing.</p>

            <img className="expandable-image" onClick={ () => { setImageViewState("/minklake/ml2018-routemap.jpg") }} alt="map of route" width="80%" src="/minklake/ml2018-routemap.jpg"></img>

            <p>At some point along the way I became concerned I might not be able to fit everyone and our gear in one canoe. I have an old 16’ aluminum canoe passed down to me from my father, the same one I spent many Algonquin trips in as a child.  This canoe is great because it’s tough and will go over anything, but it’s also heavy!  So I setup the canoe in the back yard and loaded it with all the gear for a test run. I then had the kids and my wife sit in the canoe and wouldn’t you know it, we all fit! Pheww!</p>

            <p>The date of our reservation was June 23rd, we would be spending 5 days in the park, including a day of travel each way in and out, this means we would also be spending my youngest daughters 6th birthday at camp on the 27th, the day before we leave, what a great place to celebrate!
            Being from the southern Niagara area of Ontario, the drive to Algonquin Park takes us anywhere from 5 to 7 hours depending on the access point. I like to have everything packed well ahead, I pack everything in the van on the day we leave, the canoe is well tied down to the roof. I try and get some sleep that evening then wake everyone up at about 2AM to leave. Double check my packing list, load up the cooler, grab a coffee and go.</p>
            <p> I drive right through the night hoping everyone else can get some sleep on the way for the long day ahead.
            I have to say, though the drive is long, I actually enjoy it, well most of it. Once you hit highway 11 and you start to see more trees than traffic and the landscape gets more beautiful, it’s a very peaceful drive. I keep a few energy drinks on hand as well as some munchies to make sure I’m alert for the long haul. We stopped in Huntsville for gas and some McDonalds then it was straight on to Access Point #29, Kiosk Lake.</p>

            <p>Its early morning with lots of driving left to do but the sun is starting to rise and there is a heavy mist along the ground. I tell the kids to keep an eye out at the passing lakes and marshes for the chance to spot a moose but none were seen. It’s a beautiful drive none the less, and I take in all the sights along the way.  By about 8:30am we’ve reached our destination and I find a place to park the van so we can all get out the stretch a bit.  The day was calm with some cloud cover, making it very comfortable for travel.
            We unloaded all of our gear and got the canoe in the water.  Now comes the fun part I thought to myself, we’re finally here. Once the canoe was loaded and we were sure it was stable, we all got in. The canoe sure did have a lot of weight in it and we were packed solid with gear but we were well within its capacity and thing’s felt pretty level so we shoved off.</p>
            <div className="image-text">
            <img className="expandable-image" onClick={ () => { setImageViewState("/minklake/kiosk-access.jpg") }} alt="kiosk access point" src="/minklake/kiosk-access.jpg"></img>
            <p>Loading up the canoe at the boat launch at the Kiosk Lake access point.</p>
            </div>

            <p>  Kiosk Lake is a very large lake, and on a windy day this would have been a tougher paddle but the lake this day was pretty calm and made our way much easier.  Since our destination was Mink Lake we headed East along the northern shore and under the old train bridge.  On the north end of the train bridge there is a small passage through which you can get to the east side of Kiosk Lake.  From here we travelled south to our first portage. As we headed south on Kiosk, the lake narrowed and became shallow eventually becoming Mink Creek, which is not traversable by canoe. Here we found our portage to Little Mink Lake marked, 635m, that’s not so bad.</p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/mink-creek.jpg") }} alt="mink creek" src="/minklake/mink-creek.jpg"></img>
            <p>Had to portage around this creek.</p>
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/portage1.jpg") }} alt="first portage landing" src="/minklake/portage1.jpg"></img>
            <p>The portage leads into there, who wants to go first?</p>
            </div>
            <p>We unloaded our gear and started our hike through the woods, keeping a close eye on the kids, some of these trails can be quite rocky and slippery. I took the heavy bag which had all of our food, while my wife carried the other big bag with clothes. I took the canoe and the kids carried some of the smaller items such as paddles and fishing equipment. Try as I might to make this a single carry, with kids and some extra luxury items such as a cooler our two portages were to be double carries.  We didn’t have any real long portages and it was really nice to have the cooler with milk and some meat for the next few days.</p>

            <div className="portage-sign">
                    <div><img loading="lazy" alt="portage sign" src="/minklake/portage1-sign.jpg"></img></div>
                    <div className="portage-sign-text">
                    <p>As this portage sign points out, the trail leads to two different lakes. At some point the trail splits off into a different direction so I had to be extra careful not to miss the split, especially with a canoe over my head.
                    There was almost a kilometer difference between the two routes, thankfully the fork in the trail was well marked and we had no trouble making our way to the end.</p>
                    </div>
            </div>

<p>  The trail to Little Mink Lake was uneventful, though at the time it definitely felt longer than 635m. Once we had all of the gear to the other side we loaded up the canoe for our second paddle.  
Little Mink Lake is just a slightly larger body of water between two sections of creek that run into Mink Lake. The portages just go around the creek sections that are too narrow to paddle.  Little Mink Lake is small but pretty and it wasn’t long before we were at our second and last portage of the day, a 410m into Mink Lake where we will setup camp.
The second portage was completed without incident but the heavy carrying and swarms of bugs were starting to get to the kids, I assured them that our destination was near. </p>
<div className="image-text">
<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/portage2.jpg") }} alt="portage 2" src="/minklake/portage2.jpg"></img>
<p>A log jam on the other side of the creek. We took a rest here before setting out into Mink Lake.</p>
<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/loaded-canoe.jpg") }} alt="portage 2" src="/minklake/loaded-canoe.jpg"></img>
<p>Looking down the length of Mink Lake from the portage.</p>
</div>
<p> We had a quick lunch of peanut butter and jelly and continued on our way.  We were here at the north end of Mink Lake, an extensive log damn covers the north end of the lake near the portage landing, to the south, the lake appears to open up a bit, but is long and narrow overall. We load up the canoe for one final paddle to find a campsite.</p>
<div className="image-text">
<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/portage2-2.jpg") }} alt="portage 2" src="/minklake/portage2-2.jpg"></img>
<p>A log jam at the north end of Mink Lake.</p>
</div>
<p>We paddle about halfway down the lake, my wife at this point is pretty tired and is pleading with me to find a site. She is not a real strong paddler and deep unknown water scares her, so I give her credit for making it this far.  We found a site about halfway up the lake on the eastern side, it looked nice and the shallow stony sloped edge made for a decent canoe landing. We stopped here to look around, but we quickly decided to stay. We unpacked the canoe one final time that day.</p>
<p>By the time we had reached our site it was about 1PM so we still had lots of time in the day, our travels had only taken about 4 hours.  The site was not the nicest site i've seen, a little rocky and a bit steep in spots but would do nicely for our needs and everyone seemed to be happy with it. We setup the tent and hung a tarp, then worked on finding some firewood to get a fire started.
Dinner consisted of hot dogs and sausages, cooked over the fire with a bun. </p>

<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/campsite.jpg") }} alt="camp site" src="/minklake/campsite.jpg"></img>

<p>As the day grew later and after taking out a few snacks for the night, we found a suitable tree away from camp and hung the food bags and cooler up high.
The camp rules we follow no matter where we travel in Algonquin are always the same and very important, I tell my kids. We do not eat near or in the tent, only at the fire, we are always careful not to make a mess with food because it can attract a bear and always hang your food overnight, or even when leaving camp for a considerable amount of time. So far, we haven’t been eaten by a bear.</p>
<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/night1.jpg") }} alt="first night" src="/minklake/night1.jpg"></img>
<p>It’s been a long day and everyone is tired so the wife and kids fall asleep quickly the first night. I stayed out to tend the fire and enjoy the surreal peace and quiet of a night on an Algonquin lake. I’m still wide awake, probably part caffeine and part adrenaline as I have been up since 2AM but I can’t help but enjoy as much of the still night as I can before I finally crawl into the tent and fall asleep listening to the sounds of the forest.</p>
<p>Whenever I camp in Algonquin I naturally wake up much earlier than usual, I think it’s the fresh outside air sleeping in a tent. So here I am about 6, maybe 6:30am and wide awake again. Everyone else was still fast asleep so I used the opportunity to take some nice pictures of the morning mist across the lake. I also spent some time setting up the fishing poles hoping to get a chance to use them a little later in the day. </p> 

<div className="image-text"><img className="expandable-image" onClick={ () => { setImageViewState("/minklake/morning1.jpg") }} alt="mink lake morning" src="/minklake/morning1.jpg"></img>
<p>This was the view from our campsite in the morning.</p>
</div>

<p>I built a nice morning fire and boiled some water for coffee/tea and hot chocolate for the kids. Coffee always tastes so much better in Algonquin for some reason.  Once everyone was awake I made eggs and bacon for breakfast over the fire. I brought a small camp stove, but I reserve it and use the fire for cooking most of the time.
After everyone had eaten we spent our first day milling about camp, exploring the general area and being a bit lazy after all the hard work of the previous day.  South of our campsite was a small point that stuck out into the lake, Petra and I canoed over to it where we found a little beach which would be great for swimming, the day sure was getting hot.  We went back to get everyone else so we could all enjoy a swim and a bath.</p>

<div className="image-text">
<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/mink-beach.jpg") }} alt="mink lake beach" src="/minklake/mink-beach.jpg"></img>
<p>This beach made an ideal swimming spot.</p>
</div>
<p>Back at camp we had lunch, peanut butter and jam or salami sandwiches were on the menu. We also had some bananas and apples leftover from the previous day.  The kids and I went firewood hunting along the shore near camp and managed to find this one big log that kept us going all night.  We attempted to do some fishing from shore but unfortunately the shore line wasn’t great for fishing in any depth and the bottom was too easy to snag. We did some fishing from the canoe with no change in our luck, so we called it a day and enjoyed a quiet night by the fire.</p>

<div className="image-text">
<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/firewood1.jpg") }} alt="big log firewood" src="/minklake/firewood1.jpg"></img>
<p>If you look closely, you can see Maya securing the giant cedar log we found, it's not going anywhere!</p>
</div>


<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/dinner1.jpg") }} alt="dinner time" src="/minklake/dinner1.jpg"></img>

<p>Dinner the second night would be sausages (we brought a lot of them) and baked beans, then we roasted marshmallows over the fire. The bags were hung and once again we drifted off to sleep with the sounds of Algonquin all around us. Sometime in the middle of the early morning i was awoken by a terrified wife who heared noises outside the tent.  There were some strange scratching noises as well as something that sounded like a horse galloping near camp but whatever it was, it was gone when I went to go look. It’s not a bear I reassured my wife before falling back to sleep.</p>


<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/morning2.jpg") }} alt="mink lake morning" src="/minklake/morning2.jpg"></img>

<p>The next morning was much the same as the last, up in time to see the early mist. Like every day so far the weather was fantastic, warm and sunny with a slight breeze to help with the bugs.  Scrambled Eggs for breakfast with coffee / hot chocolate.
Petra and I decided we would do some serious firewood hunting. We canoed along the shore, while keeping my folding saw handy, searching for drift wood small enough to fit in the canoe. We managed to pack the canoe pretty good with some nice pieces of cedar, we made a few trips like this, each time tossing the wood up to camp and going back for more. At camp we cut the wood into more manageable pieces, we are going to have a nice fire tonight!</p>

<div className="image-text">
<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/firewood2.jpg") }} alt="firewood haul" src="/minklake/firewood2.jpg"></img>
<p>Don't worry, we brought Maya back to shore before this haul.</p>
</div>
<hr/>
<img id="rail-hike" loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/rail-hike.jpg") }} alt="rail bed hike" src="/minklake/rail-hike.jpg"></img>

<p className="railhike-text">The rest of the day was spent doing some more exploring, there is an old railroad bed that runs the length of Mink Lake and it wasn’t far from our campsite.  We bush wacked the short distance from our camp, up onto the old rail bed and we followed it for quite some time enjoying the peaceful hike through nature. This was a much easier walk than a portage as it was nice and flat.  We marked our entry point perpendicular to our campsite with a big chunk of birch tree we found, so we could find camp on the way back. We brought a few granola bars to snack on along the way, and we found some great firewood that we just couldn’t help but drag back to camp, a very enjoyable hike.  When we found a large pile of bear poop on the trail we decided it was time to head back to camp, though no bear was seen, we hoped to keep it that way.</p>

<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/mink-south.jpg") }} alt="mink lake south end" src="/minklake/mink-south.jpg"></img>
<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/mink-west.jpg") }} alt="mink lake west side" src="/minklake/mink-west.jpg"></img>

<p>We spent some time canoeing around and exploring Mink Lake’s southern and western sides. We found a rocky spot along shore on the west side of the lake that looked good for fishing. Petra and I would come back later and do some fishing there.  The rocks provided a great ledge for standing with a deep drop off at the edge. We caught quite a few small bass that evening.  It seemed as quick as we could cast a line we had a bite. They were small but we had lots of fun catching and releasing that day.
Another quiet day and night on Mink Lake, so far we hadn’t seen anyone else on the lake. Tomorrow would be the last day at camp, and also Maya’s 6th birthday.  </p>

<div className="breakfast-collage">
    
    <img id="coffee" loading="lazy" alt="coffee adnd hot chocolate" src="/minklake/coffee.jpg"></img>
    <img id="pancakes-cooking" loading="lazy" alt="pancakes cooking" src="/minklake/pancakes1.jpg"></img>
    <img id="breakfast-ready" loading="lazy" alt="breakfast is ready" src="/minklake/pancakes2.jpg"></img>
    
</div>

<p>I woke up early and made pancakes with syrup for everyone, they were well enjoyed. It was Maya’s birthday so we brought with us a small cake and some candles to celebrate. Gifts could wait until we get home but you need to have a cake. It was a little windy that day but I managed to get the 6 candles lit and we sang happy birthday to her around a camp fire, it was great. </p> 

<p>We enjoyed another lazy day at camp, finishing up food, knowing that any extra needed to be packed back the next day, exploring, fishing, swimming and playing cards.</p> 
<div>
<img id="spider" loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/spider.jpg") }} alt="dock spider" src="/minklake/spider.jpg"></img>
<img id="cicada" loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/cicada.jpg") }} alt="cicada" src="/minklake/cicada.jpg"></img>
</div>

<div className="spider-text">
<p>We found a giant dock spider hanging out on a rock in front of camp and Petra caught herself a large cicada bug, pretty ugly.</p>
</div>
<hr/>

<p>The day faded, and as the sun set you could see so many beautiful colors in the sky and water. The moon shone off the dark still lake and all you could hear was the crackling of the fire in the background. It was so peaceful here it’s hard to leave but this was our last night and come morning we would need to pack up to start the journey home.</p> 

<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/sunset1.jpg") }} alt="sunset" src="/minklake/sunset1.jpg"></img>

<p> We all tried to get a good sleep as it would be a long day, especially for me as I’m driving.
Morning came, we slept in a little but it was needed and we didn’t have far to go. The day was calm and sky clear which boded well for travel. We made a quick breakfast of oatmeal and coffee + hot chocolate wanting to leave as soon as we could to get back to the van.  We cleaned up camp, making sure to pick up any pieces of liter we find and either burn them or hike them back with us for proper disposal. A lesson instilled in me many years ago was the lesson of “leave no trace” and I strive to live by that rule and pass it on to my children.  We also try and pile up some firewood for the next person in case they need some in a hurry.</p>

<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/leaving.jpg") }} alt="leaving camp" src="/minklake/leaving.jpg"></img>

<p>Once we were packed we took one last look around camp and said goodbye to the small chunk of outdoors that had been our home for the last few days. We were off, paddling north to the portage back into Little Mink Lake.  As we reached our first portage the day was already well under way and the sun was out and it was hot. We hustled through the next couple of portages, taking a few pictures along the way.  It wasn’t long before we were at the marshy entrance to Kiosk Lake.</p> 

<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/kiosk.jpg") }} alt="kiosk lake" src="/minklake/kiosk.jpg"></img>

<p>By now the wind had really picked up and while the lake here wasn’t rough, it was protected by the length of the rail bed and so we were covered from the full brunt of the wind coming from the west. I was more concerned about when we passed under the rail bridge the north side, once we come out on the other side the lake opens up.</p>  <p>Once through the passage we needed to head west along the shore to get to the access point and it was going to be a sprint. As we turned west, I managed to keep the canoe turned into the large swells that were being created by the wind as my wife and I paddled hard as we could. That last 2km of the paddle was quite a test of endurance but we made it back to the dock, drained but safe.  We celebrated our arrival, unloaded the canoe once again and packed all the gear into the van. With the canoe tied down to the roof once again we headed back to the highway to make the long trip home. 
One quick stop for gas and McDonald’s on the way back and hours later we are home.</p>
<div className="end-collage">
    <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/holdingcanoe.jpg") }} alt="me and my canoe" src="/minklake/holdingcanoe.jpg"></img>
    <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/goodbye.jpg") }} alt="goodbye algonquin" src="/minklake/goodbye.jpg"></img>
</div>
<p> This trip was fun and a great way to introduce my kids to Algonquin. The kids really enjoyed playing around camp, exploring, swimming and fishing, though the bugs were a problem at times. I did learn some important lessons during this trip which will help me plan the next one for us. I think we could have packed lighter on food and possibly a few items we didn’t need. I think next time I will bring a smaller cooler or not bring one at all, mostly because it’s a huge pain to carry through the bush and it’s really just a luxury. I also promised my wife and kids, next time we go after bug season.</p>
<img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/minklake/sunset2.jpg") }} alt="sunset" src="/minklake/sunset2.jpg"></img>

</div>
    )
}

export default MinkLake2018;