import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Kiosk2023p1 from './kiosk2023p1'
import Kiosk2023p2 from './kiosk2023p2'
import Kiosk2023p3 from './kiosk2023p3';
import Kiosk2023p4 from './kiosk2023p4.jsx';
import Kiosk2023p5 from './kiosk2023p5';
import ImageView from './imageview';

const KioskLake2023 = () => {

    const topRef = useRef(null);

    const [curPage, setPage] = useState(0);
    const [imageViewActive, setImageViewActive] = useState(false);
       
    const handleNextPage = () => {
        topRef.current.scrollIntoView();
        if ( curPage < 4 )
         setPage(curPage + 1);
    }

    const handlePrevPage = () => {
        topRef.current.scrollIntoView();
        if ( curPage > 0 )
        setPage(curPage - 1);
    }

    useEffect(() => {    
        topRef.current.scrollIntoView();
      }, []);

    return (
        <div ref={topRef} className="triplog">
             <Helmet>
                <title>Kiosk Lake Solo Loop 2023</title>
                <meta name="description" content="Kiosk Lake Algonquin Park. Great fishing, bass, lake trout, brook trout fishing. Algonquin Park Access Point at Kiosk Lake, access from Highway 17."></meta>  
             </Helmet>
            
             {imageViewActive ? <ImageView closeImageView={() => setImageViewActive(false)} imgSrc={imageViewActive} /> : null }

             <h3>Kiosk Lake Loop - A Colorful Fall Adventure</h3>

             {curPage === 0 ? <Kiosk2023p1 openImageView={setImageViewActive} /> : null }  
             {curPage === 1 ? <Kiosk2023p2 openImageView={setImageViewActive}/> : null }
             {curPage === 2 ? <Kiosk2023p3 openImageView={setImageViewActive}/> : null }
             {curPage === 3 ? <Kiosk2023p4 openImageView={setImageViewActive}/> : null }
             {curPage === 4 ? <Kiosk2023p5 openImageView={setImageViewActive}/> : null }

            <div className="pagenav">

                {curPage > 0 ? <div><a href="#/" onClick={handlePrevPage}>&#x02190; Previous Page</a></div> : null}
                {curPage < 4 ? <div><a href="#/" onClick={handleNextPage} >Next Page &#x02192;</a></div> : null }
                {curPage === 4 ?  <div><a href="/triplogs">Camp Logs &#x02192;</a></div> : null }

            </div>
            
        </div>    
        )
}

export default KioskLake2023;