import React, { useEffect } from 'react';

const Kiosk2023p2 = ({openImageView}) => {

    useEffect( ()=> {
        let e = document.getElementById('nav');
        if ( e !== undefined )
            e.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, []);
    
    return (
        <div className="kiosk2023_page2">
           
           <p>I woke up around seven and looked outside my tent. It was a clear and calm morning and it was time to get going. I wanted to leave camp by ten at the very latest, earlier was of course better.  I packed up my sleeping bag, pad and clothes before pulling down the tent and repacking it.  I made a quick breakfast of oatmeal and peanut butter, filtered some water and packed up the remaining gear, ready to be loaded into the canoe.  I took one last look around the camp, gave the fire pit a good soak and I was ready to leave.  I was on the water and heading away from camp by nine o’clock. </p>

           <img alt="Mouse Lake" src="/kiosk2023/mousecamp.jpg"></img>

           <p>I would begin my day with a short, scenic paddle across Mouse Lake, after which I would be faced with a long, tiring 1700-meter portage.  The paddle was incredible, the lake was completely calm and it was surrounded by an array of colors, all different shades of red and orange lined the shores.  I was lucky enough to have the lake to myself, I thought, as I paddled my way forward. </p>
          
           <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Mouse Lake" src="/kiosk2023/mouselake1.jpg" onClick={() => openImageView("/kiosk2023/mouselake1.jpg")}></img>
                    <img loading="lazy" alt="Mouse Lake" src="/kiosk2023/mouselake2.jpg" onClick={() => openImageView("/kiosk2023/mouselake2.jpg")}></img>
            </div>
           
            <p>The portage landing was easy enough to find, another shallow beach, it had a small stream running through it. I unloaded my gear and began the long carry, beginning with the large pack and the big dry bag. I staggered my runs, dropping my bags when I felt tired, to then go back and pick up the canoe and the smaller pack.  I switched back and forth like that until I reached the end. I’m still double carrying everything; it just breaks it up a bit on the long trails.</p>

            <p>I was hoping today’s portages might be a little easier than the previous day’s, despite their length.  Unfortunately, the whole area is pretty rugged and the elevation changes were not over, the 1700-meter portage in particular sucked, being very rocky and very uphill, it made for quite a challenging way to start the day.  I was sitting on my canoe, having a short rest, when suddenly a dog came running up the path in my direction, followed shortly by his owner, Andrew.  Both were friendly, and we chatted for a while before each continuing on our own way.  I pushed through and finished the portage coming to the sandy/muddy landing on Mink Creek.  I love creek travel, a nice relaxing paddle down a narrow lazy river, the occasional beaver damn, good times I say.  I made my way through the creek quietly, listening to my surroundings, hoping to see some wild life in the area.  The birds and bugs were quite loud and active but no big sightings.</p>

            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Mink Creek" src="/kiosk2023/minkcreek1.jpg" onClick={() => openImageView("/kiosk2023/minkcreek1.jpg")}></img>
                    <img loading="lazy" alt="Mink Creek" src="/kiosk2023/minkcreek2.jpg" onClick={() => openImageView("/kiosk2023/minkcreek2.jpg")}></img>
            </div>

            <p>At the end of Mink Creek, there is a short 190-meter portage leading to Big Thunder Lake.  I was unpacking my canoe, readying for the carry, when two guys made their way up the creek behind me.  As it turns out, they were headed to Erables Lake that day, while I would be continuing on to Maple Lake.  I was ahead of them for a while but they were faster and eventually caught up.  We sat and chatted a while and walked the next portage together before they left me behind.  They were single carrying the portages, while I still needed to go back for the rest of my stuff.   </p>

            <p>Big Thunder felt like a very quiet lake, it was pretty and small, it felt very sheltered.  I made the short paddle across Big Thunder and to the next portage, another long one at 1495 meters.  Thankfully this would be the end of the long portaging for the day.  It was early still and shaping up to be another warm day.  I stopped and ate and drank little before heading out, always trying to maintain proper hydration and energy during the portages.  The 1495-meter from Big Thunder to Erables Lake turned out to be slightly less torturous than previous ones, so that was nice. I managed to complete this one in two carries without much trouble, I was now done all of my long portaging for the day, what a nice feeling that was.</p>

            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Big Thunder Lake" src="/kiosk2023/bigthunderlake.jpg" onClick={() => openImageView("/kiosk2023/bigthunderlake.jpg")}></img>
                    <img loading="lazy" alt="Portage Landing" src="/kiosk2023/erablesportage.jpg" onClick={() => openImageView("/kiosk2023/erablesportage.jpg")}></img>
            </div>

            <p>I was finally on Erables Lake, it was a beautiful lake, as are most in Algonquin Park, but each has its own charm.  I paddled west through the narrow section on the southern end of Erables until it opened up to the main lake.  Here the lake was a little rougher, so I crossed carefully to the west side and followed the shore north, using campsites as landmarks, making my way towards the northern tip of the lake.  As I was paddling about half up the lake, I heard a sound above me and looked up just in time to see as a bald eagle flew out from the trees overhead and across the lake.  I didn’t have the chance to take a picture but it sure was a neat sight to see. </p>
       
            <div className="image-frame50 expandable-image">  
                    <p>The lake began to narrow, as I got closer to the portage landing.  I was finally here, the last portage of the day, barely worth mentioning at only 170-meters.  As I reached the end, I met yet another fellow traveler, his name was Kevin.  Once again, I spent some time chatting with a random stranger in the middle of the bush before continuing on my journey.  Despite coming to Algonquin in part for the solitude, it’s always interesting to run into and talk with other people having adventures of their own. <br/><br/> This portage does not lead you directly to the shore of Maple Lake. You need to paddle up a short creek and over a beaver dam before coming out to the actual lake.  Thankfully the island site I intended to take was close and wouldn’t take long to reach.  It was getting close to five o’clock by this time so I hurried myself a little.</p>
                     
                    <img loading="lazy" alt="Portage Sign" src="/kiosk2023/portagesign.jpg" onClick={() => openImageView("/kiosk2023/portagesign.jpg")}></img>
            </div>
            
            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Erables" src="/kiosk2023/erableslake.jpg" onClick={() => openImageView("/kiosk2023/erableslake.jpg")}></img>
                    <img loading="lazy" alt="Tree" src="/kiosk2023/watertree.jpg" onClick={() => openImageView("/kiosk2023/watertree.jpg")}></img>
            </div>

            <p>As I paddled out of the creek and onto the lake, I clearly saw the island I was looking for and headed straight for it.  I paddled around the west side of the first of two small islands, looking for the campsite sign, I found it.  As I came around the west side, I found a high, rocky shoreline, but further up there was a step down to the water,  a shallow, rocky area I could use as the landing with a gradual slope into the water.  It was far from perfect but it would work.  I pulled up the canoe and climbed up to the site to take a look.</p>

            <p>This was indeed the campsite, a nice cozy site sitting on the west side of the small island, there was a decent firepit with plenty of wood available, lots of log seating and a great rock ledge with a beautiful view out onto the lake.  I was quite happy with the site and got to work quickly, setting up my tent and preparing a fire.  After most of the work was done, I stripped down and took a short swim in the lake to cool off.  The water was so refreshing for this time of year but it was getting late and I wasn’t staying in long, I got out and dried off at the fire.  </p>
       
            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Erables" src="/kiosk2023/maplecampsite1.jpg" onClick={() => openImageView("/kiosk2023/maplecampsite1.jpg")}></img>
                    <img loading="lazy" alt="Tree" src="/kiosk2023/maplecampsite2.jpg" onClick={() => openImageView("/kiosk2023/maplecampsite2.jpg")}></img>
            </div>

            <p>After another eight-hour day, I was very hungry and looking forward to the pork chops I brought with me.  I sprinkled the pork chops with some salt and pepper and put them over the fire until crispy around the edges.  In a pot on the side, I cooked a rice pack to go along with the meat, dinner was delicious.</p>
       
            <img alt="Maple Sunset" src="/kiosk2023/maplesunset.jpg"></img>
                   
            <p>After dinner I spent my time enjoying the fire and star gazing from the rocky ledge at the water.   I took the opportunity to try taking some milky way photo’s while I had a dark clear sky, none of them turned out exactly as I had hoped, but I made a little progress and learned a few things.   I didn’t bother to hang the food bag since I was on an island, I just stashed it on the other side of the island, away from my tent for the night.  Knowing I had another full day of travel the next day, I was in bed before eleven and fast asleep.</p>
       
        </div>  
        )
}

export default Kiosk2023p2;