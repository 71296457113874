import React from 'react';
import './imageslidefade.css';

const ImageSlideFade = ({photoList, setImageViewState}) => {
    
    const getImages = () => {

            let images = photoList.map( (image) => <div className="fadeSlide expandable-image" onClick={ () => { setImageViewState(image)}} style={{ backgroundImage: "url("+image+")" }}></div> )

            return ( 
                <div>
                    {images}
                </div>
            )
    }

    return (

        <div className="fadeSlideFrame">
            {getImages()}
        </div>
    )
}


export default ImageSlideFade;