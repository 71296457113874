import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import BigTroutLake2021page1 from './bigtrout2021page1';
import BigTroutLake2021page2 from './bigtrout2021page2';
import BigTroutLake2021page3 from './bigtrout2021page3';
import ImageView from './imageview';

const BigTroutLake2021 = () => {

    const topRef = useRef(null);

    const [curPage, setPage] = useState(0);
    const [imageViewActive, setImageViewActive] = useState(false);
       
    const handleNextPage = () => {
        topRef.current.scrollIntoView();
        if ( curPage < 2 )
         setPage(curPage + 1);
    }

    const handlePrevPage = () => {
        topRef.current.scrollIntoView();
        if ( curPage > 0 )
        setPage(curPage - 1);
    }

    useEffect(() => {    
        topRef.current.scrollIntoView();
      }, []);

    return (
        <div ref={topRef} className="triplog">
             <Helmet>
                <title>Big Trout Lake 2021</title>
                <meta name="description" content="Big Trout Lake Algonquin Park. Great fishing, bass, lake trout, brook trout fishing. Algonquin Park Access Point at Canoe Lake, accessible from Highway 60 is the nearest point of entry if you wish to do some camping on this lake."></meta>  
             </Helmet>
            
             {imageViewActive ? <ImageView closeImageView={() => setImageViewActive(false)} imgSrc={imageViewActive} /> : null }

             <h3>Big Trout Lake Loop - A Solo Adventure</h3>

             {curPage === 0 ? <BigTroutLake2021page1 openImageView={setImageViewActive} /> : null }        
             {curPage === 1 ? <BigTroutLake2021page2 openImageView={setImageViewActive}/> : null }
             {curPage === 2 ? <BigTroutLake2021page3 openImageView={setImageViewActive}/> : null }
             
            <div className="pagenav">

                {curPage > 0 ? <div><a href="#/" onClick={handlePrevPage}>&#x02190; Previous Page</a></div> : null}
                {curPage < 2 ? <div><a href="#/" onClick={handleNextPage} >Next Page &#x02192;</a></div> : null }
                {curPage === 2 ?  <div><a href="/triplogs">Camp Logs &#x02192;</a></div> : null }

            </div>
            
        </div>    
        )
}

export default BigTroutLake2021;