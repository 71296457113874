import React, { useEffect } from 'react';

const Kiosk2023p3 = ({openImageView}) => {

    useEffect( ()=> {
        let e = document.getElementById('nav');
        if ( e !== undefined )
            e.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, []);
    
    return (
        <div className="kiosk2023_page3">

            <img alt="Maple Morning" src="/kiosk2023/maplemorning.jpg"></img>

            <div className="image-frame50 expandable-image">  
                    <p style={{marginTop:'5%'}}>I was up by seven and got to packing almost instantly, as was routine, I rolled up the sleeping bag, pad, and packed my clothing.  Today was supposed to be a slightly shorter travel day, so I planned to take some time this morning to make a bigger breakfast.  This morning I was having scrambled eggs and bacon.  I made a small fire and scrambled my eggs in a pan over it while the bacon hung across the cooking grate over the flames.  The smell was delicious and it wasn’t long before I could finally dig in. This would provide me with some much-needed energy for the trails today.  </p>   
    
                    <img loading="lazy" alt="Breakfast" src="/kiosk2023/maplebreakfast.jpg" onClick={() => openImageView("/kiosk2023/maplebreakfast.jpg")}></img>
            </div>

            <p>I finished up and washed my dishes, packed the remaining gear and prepared to leave camp.  I put my fire out and took a quick look around camp, then checked my map to verify my direction of travel before heading out.  My destination today was Three Mile Lake, where I would camp for two nights.  I had a campsite in mind, though, given how quiet it had been, I was confident I would have my pick of sites.  </p>

            <p>The day was clear and sunny with minimal wind, the lake was calm and ideal for travel.  I left camp at nine thirty, heading towards my first portage at the north west corner of the lake, a short 390-meters that lead into Ratrap Lake.  The trail was dry and clear of any major obstacles, a relatively easy hike but made for a good leg warm up.  From the portage landing, I made my way directly across Ratrap and through a small marshy narrow leading into Dahinda Lake. In the south-west end of this tiny lake, I would find my next challenge, the 1490-meter portage into Boggy Lake.</p>

            <p>Despite its length, this portage wasn’t all that bad, about a third of the way in you run across a logging road but the path is very open and mostly clear, it was a nice change.  I nearly stepped on a snake, more than once, while heading down the portage with a canoe over my head.  When I finally got to the end, I was relieved that the longest portage of the day was over. Two more smaller portages and I was home free and, on my lake, where I would finally get to enjoy my rest day.  </p>

            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Landing" src="/kiosk2023/portagelanding.jpg" onClick={() => openImageView("/kiosk2023/portagelanding.jpg")}></img>
                    <img loading="lazy" alt="Boggy" src="/kiosk2023/boggylake.jpg" onClick={() => openImageView("/kiosk2023/boggylake.jpg")}></img>
            </div>

            <p>Boggy Lake was small and, well, boggy and quite green along the shore, being surrounded by pine trees.  I put the canoe in the water and loaded my gear, heading straight across this lake to yet another portage, the 660-meter into North Sylvia Lake. It didn’t take long to get there; the portage landing was a nice rocky shore covered in roots and pine needles.  </p>

            <p>The portage into North Sylvia Lake was pretty tame and uneventful, I made good time and was soon loading the canoe back up for another paddle.  The wind was much more noticeable here and North Sylvia Lake was starting to pick up, fighting a mild headwind I made my way steadily across the lake and north into Dismal Bay.  I located the portage on the west shore and got ready for one final haul.</p>
            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="North Sylvia" src="/kiosk2023/northsylvia1.jpg" onClick={() => openImageView("/kiosk2023/northsylvia1.jpg")}></img>
                    <img loading="lazy" alt="North Sylvia" src="/kiosk2023/northsylvia2.jpg" onClick={() => openImageView("/kiosk2023/northsylvia2.jpg")}></img>
            </div>

            <p>This one was a 550-meter portage into Three Mile Lake and was another decent hike, mostly clear of obstacles but a noticeable uptick in the amount of leaves covering the ground.  Completing this one brought me to the eastern shore of Three Mile Lake.  I intended to head towards the rocky peninsula sticking out on the south-west side of the lake and just north of the big island.  This meant heading south on Three Mile, opposite of the direction I’d go on the way out, hoping to find the campsite unoccupied. </p>

            <p> It was around three in the afternoon and the lake was a pretty choppy at this point.  I decided to cross to the west side of the lake right away and then follow the shore south, allowing me to avoid the worst of the wind and waves.  Paddling steadily and hoping there was no one else on the lake or on my site, I made my way down the lake. I finally came to the rocky peninsula with the campsite I was looking for.  I had to fight the wind hard coming around the rocks and into the open in order to get the canoe landed on shore.</p>

            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Three Mile Lake" src="/kiosk2023/threemilelake.jpg" onClick={() => openImageView("/kiosk2023/threemilelake.jpg")}></img>
                    <img loading="lazy" alt="Three Mile Camp" src="/kiosk2023/threemilecamp.jpg" onClick={() => openImageView("/kiosk2023/threemilecamp.jpg")}></img>
            </div>

            <p>Once the canoe was secure, I hopped out to take a look at the site.  While the landing was a bit rocky, I fell in love with the site as soon as I saw it.  I had a good fire pit with seating overlooking the lake.  There was a large sloped rock shore, ideal for swimming from or laying on. I had an incredible view of the lake all around me, and a great spot for star gazing.  There were at least a couple of decent options for tent pads and firewood was plentiful.  I was really happy with this site and quickly brought all of my gear up from the canoe before setting up the tent and preparing the fire.</p>

            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Sunset Stars" src="/kiosk2023/sunsetstars.jpg" onClick={() => openImageView("/kiosk2023/sunsetstars.jpg")}></img>
                    <img loading="lazy" alt="Sunset Stars" src="/kiosk2023/threemilestars.jpg" onClick={() => openImageView("/kiosk2023/threemilestars.jpg")}></img>         
            </div>

            <p>Tonight, I wouldn’t need to go to bed early and the next morning I could sleep in if I chose to, it was a freeing thought, I had the whole next day free.  I was at my site and setup before four o’clock in the afternoon, I had plenty of time to do what I needed to do and relax a bit.  I found a suitable tree to hang my food bag and I pulled up my canoe for the night.  I ate some peanut butter and jelly sandwiches as a late lunch and spent some time gathering more firewood.  </p>

            <img loading="lazy" alt="Fire" src="/kiosk2023/fireonthreemile.jpg" onClick={() => openImageView("/kiosk2023/fireonthreemile.jpg")}></img>

            <p>By night time I was settled in, sitting on a log by the fire enjoying the flickering of the flames. I opened up some beef jerky and a creamy pasta pack for dinner, then I spent a good amount of time taking photos of the stars from the rocky shoreline.  I enjoyed some snacks before hanging the food and heading to bed.</p>
            <br/>
            <hr/>
            <br/>

            <p>The next morning, I was up and out of the tent around eight thirty, sleeping in a little more than usual but still not wanting to waste a nice day. Once again it appeared I would be treated to a clear sunny day.  I took the morning slow, no need to hurry or go anywhere.  I started a fire and boiled some water for coffee and just sat for a while taking in the morning sounds and smells. </p>

           
            <div className="image-frame50 expandable-image">   
                    <p style={{marginTop:'5%'}}>I was looking forward to today because today was pancake breakfast day. Since this was a rest day, it was a good day to make some pancakes with butter and syrup and load up on some good old carbs.  I ate three huge pancakes before I felt so full, I thought I would burst and then I sat around some more.  I had no real plans for my rest day, I mostly intended to explore the immediate area and take pictures, and just rest. </p>

                    <img loading="lazy" alt="Pancake" src="/kiosk2023/pancake.jpg" onClick={() => openImageView("/kiosk2023/pancake.jpg")}></img>         
            </div>

            <p>There were a couple of trails leading away from my campsite that I followed for a little while, most likely animal trails. I did a little exploration of the forest area directly behind my campsite and otherwise lounged around most of the day. The day had become rather windy so canoeing was out of the question, it was a big lake, and I didn’t really feel like getting blown around everywhere when I had plenty of paddling to do over the next couple of days.  So, I stayed on site, taking pictures, enjoying a daytime fire and going for a swim. I also spent an enormous amount of time laying out and tanning / napping on the large sloped rock at the front of my campsite, it was perfect, better than any beach for sure.  I’m not generally worried about sunburn in October but today was something else, it felt like July!</p>        
                           
            <img loading="lazy" alt="Rock" src="/kiosk2023/camprock.jpg" onClick={() => openImageView("/kiosk2023/camprock.jpg")}></img> 
          
            <p>Lunch was simple, soup and pepperoni and a protein bar.  I had lots of nuts and candy to snack on through the day as well.  This was day four, so all meals would be simple dry food from here on out.  After lunch, I decided to go and hunt for a little more firewood so I could have a big fire that night and still leave some for the next guy.  I collected a good pile in addition to what was already on my site, I was in good shape for a fire now.  </p>

            <p>I took one more quick dip in the water later that afternoon before settling in by the fire for a while.  I made a simple dinner, rice and pepperoni, and watched the fire.  Every now and then I took some more pictures of the sky while I lay out on my big sloped rock, it was so dark, I could see everything in such detail and the sky was filled with stars.  I had been lucky with consistently clear nights, giving me a new view each night.  I packed up my food and got into bed around eleven, my next day was a travel day and I would be facing my longest portage of the entire trip so I wanted to be well rested.</p>

            <img loading="lazy" alt="Sunset" src="/kiosk2023/threemilesunset.jpg" onClick={() => openImageView("/kiosk2023/threemilesunset.jpg")}></img> 
            <br/><br/>
        </div>  
        )
}

export default Kiosk2023p3;