import React from 'react';
import {Link} from 'react-router-dom';

const Footer = () => {

    const handleHomeClick = () => {

        let e = document.getElementsByClassName('header-container')[0];
        e.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    
    return (
        <div>
        <div className="resource-container">

               <div className="resource-links">
                   <h4>Park Resources</h4>
                   <ul>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.ontarioparks.com/park/algonquin">Ontario Parks</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.algonquinpark.on.ca/visit/camping/">Algonquin Park</a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.algonquinmap.com/">Jeff's Algonquin Map</a></li>
                    </ul>
               </div> 

               <div className="resource-links">
                    <h4>Site Links</h4>
                    <ul>
                        <li><Link to="/triplogs">Camp Logs</Link></li>
                        <li><Link to="/gallery">Picture Gallery</Link></li>
                        <li><a href="#/"onClick={handleHomeClick}>Back to the Top</a></li>
                    </ul>
               </div>

           </div>

        <div className="footer">
            <span id="copyright-text">Copyright &#x000A9; 2020 - Matt Kodatsky, CodeMeIn. All Right's Reserved. Not affiliated with Ontario Parks.</span>      
        </div>
        </div>
    )

}

export default Footer;