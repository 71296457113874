import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import ImageView from './imageview';
import ImageSlideFade from '../imageslidefade';

const Nipissing2023 = () => {

    const [imageViewState, setImageViewState] = useState(null);

    useEffect( ()=> {
        
        let e = document.getElementById('nav');
        if ( e !== undefined )
            e.scrollIntoView({ behavior: 'smooth', block: 'start' });
    
    }, []);

    return (
        <div className="triplog">
              <Helmet>
                <title>The Nipissing River 2023</title>
                <meta name="Nipissing River Camp Log" content="Nipissing River Algonquin Park. Algonquin Park Access Point at Tim River, accessible on the West side of the park. Early May Trout Fishing."></meta>
            </Helmet>

            {imageViewState ? <ImageView closeImageView={() => setImageViewState(null)} imgSrc={imageViewState} /> : null }
           
            <h3>The Nipissing River</h3>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/nipandcanoe.jpg")}} alt="Nipissing River" width="80%" src="/nipissing2023/nipandcanoe.jpg"></img>
            
            <p>Spring was approaching quickly and the itch to fish was once again setting in. It had been a rather warm winter, save for a few icy blasts, and there just wasn’t enough cold to make my local lake safe for ice fishing.  My yearly ice fishing trip further north with friends didn’t pan out this year either, so I was anxious for any chance to get out and fish, especially since prime brook trout season was almost here.</p>

            <p>It was almost April before I seriously started really considering whether going into Algonquin this May would be a possibility.  Unfortunately, I was unable to find anyone willing or able to go with me at the time and I took far too long in considering whether or not I wanted to solo this time of year.  My intention was not to make this a big camping trip, no long portaging or big lakes this time, I just wanted to fish for a couple of days and come home.  Unfortunately, by the time I started looking for reservations, I found most of the easier to get to spots had already been taken.  I couldn’t believe how busy everything was!</p>            
           
            <p>My plan going in, was to head north on the weekend, starting May 13th, and stay two nights. I later added a third night because, well, I mean, if I’m already here, right? …  So, three nights and four days it was, I planned to come home on Tuesday, the 16th.  After scouring the reservation calendar, the best option I could find available that also fit my schedule was on the Upper Nipissing River.  It was a little further than I really wanted to travel this time, but I could make it work, I didn’t have any good alternatives anyway.  I’ve always wanted to check out the Nipissing River, I’ve heard it’s a good area to search for brook trout, so this seemed sort of ideal and four days would allow for the extra travel time that would be required while still getting in plenty of fishing. </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/tripmap.jpg")}} alt="Trip Map" width="80%" src="/nipissing2023/tripmap.jpg"></img>
            
            <p>The Nipissing River is quite long and I plan to explore the entirety of it over the coming years but for this particular trip I would be limited to the Upper Nipissing given the time available to me. In addition, I planned to explore some of the smaller, less familiar lakes along my route.  I decided I would spend two of my nights on the river followed by a third night on Chibiabos Lake, figuring this would give me lots of fishing opportunities. My mind was set, four days in Algonquin solo, with one quest, to catch and eat some tasty brook trout.  I made my reservations and eagerly began the process of planning and packing. </p>

            <p>I made a serious effort to keep my gear size down for this trip, I was only going for a few nights, and I wanted so badly to make single carries on the portage.  I didn’t bring any extravagant foods with me, mostly just dried meals, soup and jerky, oatmeal for breakfast and a variety of chocolate/protein/granola bars. Coffee was in good supply, I also brought some oil and fish crisp, enough for three good trout meals, should I be so lucky.  I pared down my gear to essentials only but made sure to have very warm clothes for the cold nights.  Despite my efforts, the pack was far to awkward and heavy to carry with my aluminum canoe necessitating a second carry anyways.  </p>

            <p>Screw it… double carry it is, I thought. It’s not as though I had THAT much portaging to do, I mostly just wanted to see if I could do it. I think I need a better/bigger pack…. Anyways, since I was bringing a second pack and the second carry would be small, I became a little more lenient with my packing and grabbed a few small extras. I even managed to stuff my feather down pillow into one of the packs, ensuring a very comfortable sleep.  Unfortunately, the roughly 2.6kms of total portaging that I had on my way in, had just become almost 8km.  </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/nip1.jpg") }} alt="Nipissing River" width="80%" src="/nipissing2023/nip1.jpg"></img>
           
            <p>I wanted to be at my campsite as soon as possible. My research suggested to me that the best site available along this stretch of the Nipissing would be the first one, located on a 55 meter portage. I was hoping to snag this one if it was available, so the plan was to drive through the night and be on the water and on the go as early as I could Saturday morning.  I would start at the Tim River, access point number two on the west side of the park.  This access point is a roughly five-hour drive from my home so I wanted to leave no later than about one in the morning.</p>

            <p>The night of the trip came quickly. After work, I packed all of my gear into the van and mounted the canoe to the roof.  I went over and over the list to make sure I didn’t forget anything…. Of course, I still forgot something.  I then managed to catch about three hours of actual sleep before waking up at midnight ready for the long drive. I made a pot of coffee and filled my massive bubba mug, grabbed some food and energy drinks for the road there and back, and went over my list one last time.   I was ready to go, the van was gassed up, the gps was programmed and I was on the road by just after midnight. I couldn’t wait, Algonquin Park, here I come!</p>

            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/timriverbridge.jpg") }} alt="bridge" width="50%" src="/nipissing2023/timriverbridge.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/timrivermorning.jpg")}} alt="van" width="50%" src="/nipissing2023/timrivermorning.jpg"></img>
            </div>

            <p>The drive to Algonquin was peaceful and uneventful, which is nice considering the tendency of moose to wander across the roads at all hours this time of year.  I was hoping to see some moose but I’d rather not find myself having to swerve around one on the road, so I was extra cautious while driving through moose country just before dawn.  I had been this way last in 2019 when a friend and I went through the Magnetawan access point, but this time I’d be continuing on the unpaved road that winds its way through the forest further north.  The road was long but in decent shape, there was one point where I wasn’t completely sure I had made the right turn, but soon after I found myself at the small parking lot that sits next to the dock on the Tim River.  I was here, finally! I parked close to the water so I could unload all the camping gear. There were quite a few vehicles parked in the lot, but not a single person around as far as I could see, probably all the reservations on Rosebary Lake, I thought.</p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/timriver1.jpg")}} alt="Tim River" width="80%" src="/nipissing2023/timriver1.jpg"></img>
            
            <p>I unloaded my canoe and placed it along side the dock which sat well above the current water level.  I then unloaded all of my gear from the van and into the canoe.  I drove across the small bridge that leads into the back section of the lot and parked in a nice out of the way spot, taking a few moments to snap some pictures. It was a beautiful morning with mostly clear skies, there was a cool stillness to the air, a chorus of sounds from the many birds around me filled the otherwise silent riverbanks. It was just six thirty in the morning as I paddled away from the dock, slowly, taking in my surroundings with all of my senses and enjoying the fact that I am once again in Algonquin.</p>

            <p>I paddled slowly down the Tim River, watching the shores closely, hoping to catch a glimpse of a moose or really any wildlife.  I did see quite a few birds, and I watched a pair of otters cross the river before heading up on the shore and disappearing.  It was a very enjoyable paddle, the water was very still, there was no wind.  The rising sun was visible through the trees in the distance and the surrounding nature seemed so alive.  There was a thick mist slowly rising from the water.  I paddled along the various twists and turns before eventually I came to the mouth of the river where it opens up into Tim Lake. </p>
            
            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/timriver2.jpg")}} alt="tim river" width="50%" src="/nipissing2023/timriver2.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/timriver3.jpg")}} alt="tim river" width="50%" src="/nipissing2023/timriver3.jpg"></img>
            </div>

            <p>Tim Lake has a large island right in the middle of it. I was headed to the portage on the north shore of the lake, directly across from the island which would take me to Chibiabos Lake.  The paddle was easy and the day was getting nicer. I was surely over dressed for what was shaping up to be a beautiful sunny day but I was prepared for cold, wearing layers just in case. I made my way over to the portage, it was easy enough to find, I landed my canoe and unloaded my gear, then readied myself for the carry. </p>
               
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/timtochibiabosportage.jpg")}} alt="Tim River" width="80%" src="/nipissing2023/timtochibiabosportage.jpg"></img>
           
            <p>The portage into Chibiabos was rather short at just 345 meters but it was mostly uphill making it feel longer.  It was early in the year and I wasn’t in the most ideal portaging shape yet but I was surprised to find the constant and steep elevation changes so tiring.  I’m sure lack of sleep wasn’t helping any, nonetheless, I completed the hike and found myself at the edge of Chibiabos Lake. A small lake, it looked nice and it had a couple of campsites on it, but I was just passing through this time.  I made the short paddle across Chibiabos towards the north-east corner where it narrows into more of a creek. There is a portage tucked away in this small corner of the lake.</p>

            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/chibiaboslake.jpg") }} alt="chibiabos lake" width="50%" src="/nipissing2023/chibiaboslake.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/portagedock.jpg")}} alt="portage dock" width="50%" src="/nipissing2023/portagedock.jpg"></img>
            </div>

            <p>The portage landing here is quite wet so someone has built a nice long wooden dock, I was able to pull my canoe right up along side it and unload my gear.  This was another short one, 320 meters into Indian Pipe Lake. It was much like the previous portage in that it was very up-hilly.   This portage crosses a logging road and as I got close, I noticed a wooden sign embedded in the ground along the trail just before the road, indicating that it is indeed a ‘crossing’, good to know. I crossed, and before long I was at the calm shore of Indian Pipe Lake. </p>

            <p>Indian Pipe Lake is another small, pretty lake. It has some interesting rock islands and it was very calm when I got there. It was still very early, and I was making great time so far.  It was here that I first started to notice the bugs, black flies were starting to become noticeable but didn’t seem to be biting aggressively yet, I just swatted at them when they annoyed me.  I crossed the lake to get to my next portage, a not so short 820 meter into West Koko Pond. I stopped for a quick rest and a bit of lunch to recoup some energy before continuing on my way, peanut butter and jelly was on the menu.</p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/indianpipelake.jpg")}} alt="Indian Pipe Lake" width="80%" src="/nipissing2023/indianpipelake.jpg"></img>
                       
            <p>At 820 meters, this next portage was my longest of the day/trip.  All of these portages were ‘black line’ portages, as in low or no maintenance, despite this, I found the trails to be quite clear and obvious, and easy to follow.  Though the trails were all significantly wet and muddy, the real hell of these portages is the seemingly constant and sometimes steep elevation changes, giving you the pleasure of going uphill in both directions.  Many of the downhills are steep enough that they are just as miserable as the up-hills.  </p>

            <p>I eventually made it to West Koko Pond where after a ridiculously short paddle to the other side, I would be greeted by another 790 meters of hiking my stuff up and down hills.  I was still making good time however; I was earlier than expected despite the double carries and I was almost to the Nipissing River.  It was here on West Koko that I made an unfortunate discovery.  I had finished up my water and needed to filter more, so I pulled out my sawyer squeeze only to find I had no flow. None. Not a drop. Shit, I forgot to clean/flush the filter, but it got worse, I forgot the syringe I need to do the flushing properly.  Somehow when I was packing, I missed this, rendering my water filter useless in the moment. </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/westkokopond.jpg")}} alt="West Koko Pond" width="80%" src="/nipissing2023/westkokopond.jpg"></img>           

            <p>I didn’t have an alternative way to properly flush the filter at the time, so I tried blowing it out, shaking it and squeezing really hard to force water through. I did eventually manage to get a small amount of flow, but it was painfully slow.  This was inconvenient at worst but not serious as I had multiple options for sterilizing water, I just happened to prefer the ease of the filter.  That being said, I can say first hand that the water in West Koko Pond does NOT taste very good, even when filtered.  I made due with what I could force through the filter, I could boil / sterilize more water when I got to camp. I continued over the portage until I finally got to Big Bob Lake. </p>
 
            
                <ImageSlideFade photoList={['/nipissing2023/nymph1.jpg','/nipissing2023/nymph2.jpg','/nipissing2023/nymph3.jpg']} setImageViewState={setImageViewState} />
            
            <p>The wind had picked up a bit by now, but it was keeping the bugs down and wasn’t strong enough to be a problem while paddling. The landing was shallow and a bit rocky, but not too bad overall.  As I looked down, I noticed a massive insect swimming in the water that I had never seen before. I scooped it up with my paddle and grabbed a few pictures for identification at a later time, it was a mean looking thing, about the size of a bic lighter, wearing what looked to be medieval armor.</p>
                      
            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/bigbob1.jpg") }} alt="big bob lake" width="50%" src="/nipissing2023/bigbob1.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/bigbob2.jpg") }} alt="big bob lake" width="50%" src="/nipissing2023/bigbob2.jpg"></img>
            </div>

            <p>It was a mostly easy paddle against the wind as I made my way across Big Bob Lake to the next portage.  I said a quick hello to a fellow that was on the eastern campsite as I passed by, then a short time later I pulled up to the 200-meter portage that would lead me to the Nipissing River.  After a somewhat easier hike, I found myself at a very narrow, very shallow stream, heading out into the distance.  I went back up the trail to get a better look and take some pictures of the small waterfall that lies at the end of the portage.  I wondered if the canoe would even float in the shallow waters as I loaded the gear back in. I carefully maneuvered through the stream and over a small dam, managing to do most of it while actually sitting in the canoe.  </p>
           
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/nip2.jpg")}} alt="Nipissing River" width="80%" src="/nipissing2023/nip2.jpg"></img>

            <p>At the end of the stream, the water joins the larger main river body known as the Nipissing River, it was much deeper and wider and much easier to paddle now.  I made my best effort to create a mental image of my surroundings here, so that I could find the portage on the way back.  I paddled out into the river, heading east, happy to finally be here. The day was beautiful, warm and sunny and the river was calm with a slight breeze going in my direction, it was a good day to be here.  </p>

            <p>I paddled along the river, admiring the scenery around me. I was surrounded by vast stretches of wetlands with tall pine trees lining the background.  The river wound back and forth for a while, with only a couple of beaver damns to be crossed before the shores and trees close in and the river becomes rockier and the next portage nears.  </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/nip3.jpg")}} alt="Nipissing River" width="80%" src="/nipissing2023/nip3.jpg"></img>

            <p>After about an hour I came to the first of two 65-meter portages that I must traverse before getting to my destination.   These portages take you around all the rocks that appear when the water level is too low.  I crossed both portages easily, and shortly after, I pulled up to my potential destination campsite on the 55-meter portage.  As I pulled up to the landing, I looked around hopeful. To my surprise, I saw no one, the site was open for the taking. I quickly unloaded my gear and pulled up my canoe claiming my new home for the next two nights.</p>


            <div className="image-frame50">
                <div>
                    <p>It was almost one o’clock when I got to my final destination for the day. I had spent a great deal of time exploring and taking photos on the way in, with a few food breaks so overall I had made decent time. I took a look around the site to assess my new home. This is the location of a 55-meter portage that takes you around a neat set of rapids with a tiny waterfall, allowing further travel down the Nipissing, but it doubles as a campsite. It was reasonable to expect that I would have people passing through my site, something I certainly wasn’t used to, but I was ok with given the circumstances. Besides, how busy could it get?</p>
                    <p>The site was small and not really a very nice overall, at least by Algonquin Park standards, but I wasn’t here for the site, I was here for the location and the fish. The canoe landing was shallow and muddy but not terrible.  The thunderbox was a short way back into the woods and viewable from the water.  The site was small with very few suitable tent spots and the firepit was a bit of a disaster.  On the up side, I was in a prime location to base camp and day trip down the river. I had a beautiful front and rear view and I had my own personal rapids for fishing only a few meters from my camp.</p>

                </div>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/campsitesign.jpg")}} alt="campsite sign" src="/nipissing2023/campsitesign.jpg"></img>
            </div>         
           
            <p>I setup my tent on the only spot that really looked any good, it was just barely large enough.  The portage trail passed by about five feet from my tent so I could hear anyone going by.  I unpacked my sleeping stuff then went in search of a tree to hang the food.  The only tree that seemed remotely suitable for hanging my pack was a massive pine tree right at the front of the site, rising up high and overlooking the water, it was an absolute unit of a tree. I tossed a rope over one if it’s large branches in preparation to hang the bag before dark.</p>

            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/campsite1.jpg")}} alt="campsite" width="50%" src="/nipissing2023/campsite1.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/firepit.jpg")}} alt="camp fire" width="50%" src="/nipissing2023/firepit.jpg"></img>
            </div>


            <p>It was early still so I decided to make some coffee and something to eat then I’d boil some water for drinking later.  I gathered a bunch of dry pine needles and threw them into the fire pit.  Within seconds there was smoke and shortly afterwards, fire.  I had not started the fire, this could mean only one thing, previous visitors did not extinguish the fire… at all.  On a windy day such as it was, this could have been a disaste. When will people learn to properly extinguish their fires?  It’s not a hard thing to do and I cannot overstate its importance.  With the ability to enjoy a nice fire in the back country comes the responsibility to supervise and extinguish any fire when you leave camp.  I write this as wild fires consume vast swaths of land all across Canada, so it feels extra important to mention. After a short pissed off rant to myself about it in the middle of the woods, I continued building my fire and boiled a couple of pots of water, which were left to cool for later use.  </p>

            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/frontview.jpg") }} alt="campsite view" width="50%" src="/nipissing2023/frontview.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/nip4.jpg") }} alt="campsite view" width="50%" src="/nipissing2023/nip4.jpg"></img>
            </div>

            <p>Once I had eaten and relaxed a bit, I decided it was time to get to some fishing.  There were some beautiful rapids with a small waterfall next to my site, it was time to see if it had fish in it.  I carefully walked out onto the shallow rocks at the back side of the site, casting out into the deeper water.  I was casting a silver spinner of some sort and within only a few casts I had a solid bite. I pulled back the rod and might have squealed in delight at the realization that I had been fishing only moments but had already hooked a decent size brook trout.  I eagerly reeled in the line, praying that I didn’t loose it.  Not expecting such quick results, in my excitement to fish, I had left my net up at the camp and didn’t have it available to me so when I had the fish close enough, I grabbed the line and ran it up the shore to make sure I could get it secured.  I took a few pictures and put it on a stringer, leaving the fish swimming in a pool of water to comfortably await dinner.  I continued to fish for a while, but didn’t get anything more than a couple of bites. It was still early so there would be time to fish more later, it was time to clean and filet my trout. </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/brooktrout.jpg")}} alt="Brook Trout" width="80%" src="/nipissing2023/brooktrout.jpg"></img>

            <p>I took the fish up on some rocks across the river to clean and filet, then disposed of the fish parts in the river, hoping not the attract any unneeded bear attention.  I rinsed the filets in the rushing water, in the end I had two beautiful pinkish orange, boneless fish filets ready to be pan fried.  I added some wood to the fire and prepared the fish with some fish crisp breading via the shake and bake method.  I tossed some oil into a frying pan, put the fish in and carefully cooked it over the fire. It didn’t take long and the fish turned out perfect. I sat by the fire and ate the entire thing out of the pan, it was as tasty as I had hoped.</p>

            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/filets.jpg") }} alt="trout filet" width="50%" src="/nipissing2023/filets.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/filetscooked.jpg") }} alt="fried trout" width="50%" src="/nipissing2023/filetscooked.jpg"></img>
            </div>

            <p>A sense of satisfaction washed over me, I had succeeded in my primary goal, catch fish and make dinner. I was full and happy, the day was great, the meal was everything I had anticipated, this was the peak. Everything else after this is just bonus and it was still early yet, I had days more to go and plenty of opportunities to be had.   I sat by the fire and let my stomach rest a bit, enjoying the peaceful surroundings.  </p>

            <p>A couple of guys passed through my campsite, on their way down the river, they were going further than me that day.  We chatted for a bit before they went on their way, I guess they had some pretty good luck with fishing as well.</p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/rearview.jpg")}} alt="Nipissing River" width="80%" src="/nipissing2023/rearview.jpg"></img>

            <p>It wasn’t long before I decided to take another go at fishing my site. It was evening and prime fishing time I figured. I carried the canoe down the portage to the back side of the river and fished the same area as before, but from the canoe, giving me a bit more casting freedom.  I had a couple of hours before dark and in that time, I managed to pull in a mixture of fall fish and chubs as well as a couple more brook trout, one of them quite large. I released them all since it was late and I had already had fish that day, there was no need to keep another.</p>

            <p>I pulled the canoe back up to the campsite and repacked anything that didn’t need to be out. I enjoyed a quiet night by the fire, it had been a long but enjoyable day, a great start to my trip and had high hopes of adventure to come, man I did not know what I was asking for.  I hung the food pack and got into bed around nine thirty, falling asleep quickly as my body was long overdue for some rest.  I slept well and found it warmer than expected, I was comfortable and didn’t stir once. I was up and out of the tent by six in the morning, eagerly hoping to see a moose somewhere close by and get an early start to fishing. </p>

            <p>I took some nice morning shots with the camera then started casting from shore. The morning was calm and quiet, the mist hovered over the water.  I didn’t catch a thing that morning, not even a bite, I was a little surprised to be honest. I took a break to take down the food bag and make some breakfast.  I boiled some water for coffee and oatmeal.  I swear everything tastes so much better when you’re camping.</p>

            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/marsh1.jpg") }} alt="nipissing" width="50%" src="/nipissing2023/marsh1.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/marsh2.jpg") }} alt="nipissing" width="50%" src="/nipissing2023/marsh2.jpg"></img>
            </div>

            <p>I took the morning slow; my plan today was to take a small pack and travel up the Nipissing a bit further to explore and fish the different rapids but I wasn’t going far and I was in no hurry. I spent a bit more time fishing around camp before packing some snacks, water and fishing gear for the day. I loaded up the canoe with what I needed for the next few hours and the camera.  It was around noon when I actually left camp and started lazily paddling up the river. It was turning out to be a very sunny and hot day, the bugs were around but a steady breeze kept them in check most of the time.  I paddled east up the Nipissing towards the next portage, a 200-meter hill that goes around some rapids. </p>

            <p>This portage landing was quite steep. You pretty much get out of your canoe and just hike up a 45-degree hill, there’s a bit of a plateau at the top which acts as the campsite, followed by a long downhill trek to the water below. This makes for a pretty steady climb on the way back, thankfully I wasn’t carrying much with me.  As chance would have it, the guys who had previously passed through my campsite the day before, had stayed here and were in the process of packing and moving on, we chatted once again before continuing on.  There is a large wet, muddy section at the end of this portage which makes for a wet hike, but I managed to bushwack around the water and push / pull my canoe over it to avoid getting wet.  </p>

            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/portage200m.jpg") }} alt="portage landing" width="50%" src="/nipissing2023/portage200m.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/portagemud.jpg") }} alt="muddy portage" width="50%" src="/nipissing2023/portagemud.jpg"></img>
            </div>

            <p>The 200-meter portage ends near a nice set of rapids with deeper pools of water around it. I spent a fair bit if time fishing here, catching some small brook trout and chub, nothing for dinner. The river gets narrower here and you start to see the alders along the edges. I continued down the Nipissing towards the next portage, a small 110-meter that goes around yet another rocky section of the river.  </p>

            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/beaverdam.jpg") }} alt="beaver dam" width="50%" src="/nipissing2023/beaverdam.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/rapids.jpg") }} alt="rapids" width="50%" src="/nipissing2023/rapids.jpg"></img>
            </div>

            <p>At first, I was confused as I couldn’t find the portage marker. It wasn’t until I pulled over a fairly large beaver damn before the rapids that I was able to see it on the other side.  The rapids further up are impassible but I did catch a couple small fish in the pools of water there.  I walked the portage and fished the other end of the rapids without any luck.  I snacked on a couple of protein bars and trail mix that I brought with me, making sure to keep myself hydrated as well.  It was quite a warm sunny day for May, I considered that this may be why fishing seemed slow today.  </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/nip5.jpg")}} alt="Nipissing River" width="80%" src="/nipissing2023/nip5.jpg"></img>

            <p>After some time, I started heading back to camp. I wanted to fish all of the rapids on the way back as well so I made my way back slowly, fishing each hole that looked like it might have some trout.  While I did catch a number of fish, nothing worthy of turning into dinner.  It was a fun day of exploration and fishing; I took lots of great pictures and look forward to a day when I can explore even further down the river.  For now, it was time to get back to camp, though I wasn’t done with fishing for the day just yet, perhaps my luck would change later this evening.</p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/sittingbyfire.jpg")}} alt="Sitting By Fire" width="80%" src="/nipissing2023/sittingbyfire.jpg"></img>

            <p>I got back to camp and pulled my canoe back up to the site as I would be shore fishing the rest of the night.  I took some time to gather a good amount of firewood so I could have a nice fire.  I made a quick dinner of rice and pepperoni and cleaned up a bit.  There wasn’t much else to do except fish and relax by the fire, so that is exactly how I spent the rest of the night.  I was leaving camp the next day, headed for Chibiabos Lake to stay one more night, but I was in no hurry and I wasn’t going far.  I was in bed a little later than the night before, but not by much, I wanted to be up early to go moose watching and fish some before I had to start packing.  I fell asleep quickly to the sound of rushing water from the river, it made for some very relaxing background noise as I drifted off.</p>

            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/mist1.jpg") }} alt="morning mist" width="50%" src="/nipissing2023/mist1.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/mist2.jpg") }} alt="merganser" width="50%" src="/nipissing2023/mist2.jpg"></img>
            </div>

            <p>This night was colder than the previous one, somewhere around three degrees Celsius, but I was comfortable and was up again around six in the morning.  The morning mist is always such a neat sight, it almost feels like you’re in a whole different world sometimes.  I went down to the water and watched geese and mergansers in the river hunting for their breakfast no doubt, but no signs of a moose.  I spent an hour or so fishing but didn’t catch a single thing.  Day three was shaping up to be another beautiful sunny one, there was a slight breeze and the bugs didn’t seem too terrible yet.</p>

            <p>I made some breakfast, oatmeal and peanut butter, then began packing up camp.  I started in the tent, repacking any clothing and sleeping items I had out, throwing them in the bag wherever there was room.  Once I had everything put away, I went over the site to check for any forgotten items. I was good, it appeared I had everything, I even packed the large empty glass liquor bottle I found on the site to take out and properly dispose of.  I loaded up the canoe and said goodbye to my camp, it’s been fun! </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/beaverdam2.jpg")}} alt="Beaver Dam" width="80%" src="/nipissing2023/beaverdam2.jpg"></img>

            <p>Off I went, heading west on the Nipissing, back in the direction I had come from.  I wasn’t in any hurry to get to my destination; it was only a few hours of travel at most and I had all day, so I made a point of fishing a few spots that I had missed on my way in.  One spot in particular was in a deep pool of water just after a beaver dam; the rushing water here looked to be an ideal spot to fish. Unfortunately, after spending some time here, I didn’t get a single bite. I continued on, coming once again to the rocky 65-meter portage.  </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/portage65m.jpg")}} alt="Portage 65m" width="80%" src="/nipissing2023/portage65m.jpg"></img>

            <p>I hauled my gear over the portage and down the next short section of the Nipissing, then over one more short 65-meter hike before I came to the last part of the river.  This stretch of river winds it’s way through a large area of wetlands and marsh before arriving at my portage.  The river edges are sort of closed in at the beginning, but the trees open up as you get further along, I kept a close eye out for the land marks I had made note of earlier.  As I pulled away from the 65-meter portage and into this last section of river, I noticed it had become a bit breezy, but I had no idea what was in store for me ahead.</p>

            <p>I continued along the river, but the further I got, the heavier the wind blew back at me.  Before I knew it, I was battling the wind with all of my might and struggling to keep my canoe straight. By this time, I was well into the river and there were rolling waves with white caps, cresting in the massive gusts that kept coming my way. This was not a sight I expected to come across today, least of all on the Nipissing River.  </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/nip6.jpg")}} alt="Rough Nipissing" width="80%" src="/nipissing2023/nip6.jpg"></img>

            <p>I fought hard, inching my way through the river as best I could, swearing to myself about how a such a seemingly simple task had become so difficult.  This would take me forever, I thought to myself.  I persevered, switching paddling sides quickly as I was inevitably pushed off course, I was digging hard into the water with every stroke only barely taking me forward.  The only break from the steady wind being at the occasional bend in the river, where I’d get blown into the riverbank trying to make the turn.  One particular turn ended up being a trip changing moment.  As I paddled around a corner, I came into a very long straight section of river.  Here, a massive wind gust suddenly came at me, taking hold of my canoe.  I fought it as best I could, but it just wasn’t enough. I watched as the front of my canoe lifted slightly, while I was spun around sideways causing me to catch a rolling wave in a bad way. I was instantly dumped sideways and rolled into the Nipissing River with my canoe overturned next to me.</p>

            <p>I managed to keep my head above water, I was wearing my life preserver as always but my first thought was that the water wasn’t quite as cold as I was expecting it to be, well maybe my second though, my first thought was more like ‘Oh shit, I can’t believe this just happened’.  I reacted by quickly grabbing my canoe by the end and flipping it back over in the water. In doing so I managed to keep most of the gear inside, but it was now floating just below the surface.  </p>

            <p>With one hand on my canoe, I kicked and swam as best I could to the edge of the river, which thankfully wasn’t real far away.  I grabbed on to the weeds along the edge of the river and pulled myself up, which is quite a task in itself since there is no stable footing, just mushy mud and water.  I was wearing my big rubber boots at the time, and as I stepped into the bank of the river trying to scramble up the side, I sank so deep into the mud, I wasn’t sure I’d get out.  At one point in the struggle, I realized it was going to be just me or the boots. I managed to slowly pull myself from the mud, in the end, I am down one rubber boot, the other one survived miraculously. </p>
            
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/stuckinmud.jpg")}} alt="Stuck In Mud" width="80%" src="/nipissing2023/stuckinmud.jpg"></img>

            <p>I pulled my canoe as close to the edge as I could, it now resembled a bowl of soup with all of my gear floating around in it.  It was then, that I noticed my paddle floating away far down the river, never to been seen again.  For a split second, I thought about diving in for it but there was no way in hell I was catching it and I’d be in even worse shape if I tried, so I let it go.  I had a backup paddle strapped to my canoe for just such an emergency, though this was the first time I’d ever needed it. I emptied my now soaking wet gear from the canoe and threw it up on any dry brush I could find around me, hoping I hadn’t lost anything more.  I took an assessment of my gear, I had lost a paddle, a boot and my sun glasses, but it appears that everything else was accounted for, even if soaking wet.</p>
            
            <p>I took my collapsible bucket and bailed out the canoe as best I could, then I turned it over to dump out the rest of the water.  The canoe was now mostly empty.  I repacked my gear, which now weighed double or more what it did on the way in.  So, there I was, sitting on a wet, muddy bank in the middle of the Nipissing River in socks, soaking wet, muddy and with absolutely no chance of paddling down the river due to the current, waves and insane wind conditions.  What the f*ck was I going to do? I wondered out loud.  I sat there for a few moments, taking in the seriousness of my situation and planning on how I was going to survive this.  What I did have going for me was time and weather, it was still relatively early in the day, and it was sunny enough that I wasn’t freezing… yet.</p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/nip7.jpg")}} alt="Rough Nipissing" width="80%" src="/nipissing2023/nip7.jpg"></img>

            <p>Have you ever thought about what it’d be like to just line a heavy canoe down the length of the Nipissing instead of paddling it? Yeah, me either but that was exactly what I was about to do. I had no choice it seemed but to try and walk the rest of the way. I wasn’t so sure how stable the banks of the river were but I was going to make it out of here one way or another.  Thankfully my hiking shoes were still tied to my pack, so on they went.  I grabbed a good rope from one of my packs and tied each end of the canoe, using this rope I was able to keep the canoe just off the shore for the most part and pull it along the edge of the river.</p>

            <p>This was far from an easy task; in fact, it may have been one of the most exhausting things I have ever been through in my life but I was intent on getting out of this mess.  Just inches to feet at a time, I heaved the canoe forward, trying my best to keep a grip on the rope, I trudged though deep muddy banks, crawling on hands and knees at times, jumping over small streams and quite frequently landing in very deep mud, having to pull my limbs out, the suction of the thick mud sapping my energy more each time.  Quite often I would have to pull the canoe through mud or work it around debris along the edge, it was time consuming and draining but I worked my way around the curves of the river bit by bit, reminding myself often how miserable being stuck out here at night would be.  It took me hours, but eventually I found myself near the stream that led back to the portage landing, I was almost free of the river.  I got as close to the stream as I could before hopping back in the canoe and paddling the short way back to the 200-meter portage.</p>

            <p>Once at the landing, I didn’t waste any time taking everything out and trying to drain or squeeze as much water out of my gear as I could.  I also made a point of opening and drying all of the camera equipment as my cards and batteries all got a bit wet too. The camera was wet but I don’t think it got too thorough of a soaking since it was well packed, so I took it out and let it dry in the sun a bit.  I had removed the battery back on the river which likely helped it survive, but I opted not to use it for the remainder of the trip until I had a chance to really dry it and inspect it.  The action cam I have was in its waterproof case and mounted to the canoe so it was safe, unfortunately I did not get video of the actually dumping in the river.</p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/windbound1.jpg")}} alt="Wind Bound" width="80%" src="/nipissing2023/windbound1.jpg"></img>

            <p>After letting everything sit in the sun for a bit, I once again gathered up all of my gear and began heading down the portage towards Big Bob Lake.  I was roughly four to five hours of travel from my vehicle at this point and I was intent on going home as soon as possible given my situation, with all of my gear and clothing soaked, there was no way I could spend the night on Chibiabos as planned.  I made quick work of the 200-meter portage, but when I got to the shores of Big Bob, I was greeted with a wild and windy lake with large rolling white caps, no way in hell I could even get my canoe in the lake at the landing, forget trying to cross, I was totally wind bound.</p>

            <p>I checked the time; it was almost five in the afternoon and I was stuck on the portage landing.  I decided this would be a good time to make some hot coffee and soup, it had been an exhausting day and I needed the warmth and calories.  I setup my stove on a flat spot on the ground and boiled some water, my pocket rocket worked quite well despite the wind.  As I sipped my coffee, I pondered my situation. I had to wait for the wind to subside before I could get back to the van, but if it didn’t die down, I was stuck here and the temperature would be getting very uncomfortable, very soon.  I think up until this point, I hadn’t really worried much, but now I was starting to worry.  </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/windbound2.jpg")}} alt="Wind Bound" width="80%" src="/nipissing2023/windbound2.jpg"></img>

            <p>As I watched the waves roll by, hoping the wind would settle down I realized that time is going by and I don’t have any to spare, an idea came to me.  I pulled out my tent and set it up in the middle of the portage to air it out. I unpacked and opened up my sleeping bag, my mat, all of my clothes and any gear that was wet and hung them all on the many branches hanging around the portage landing.  Since the wind was blasting so hard, I figured maybe I could air dry everything a bit and there was no better time to start than right that moment.  I adjusted and moved items as they dried, but it worked surprisingly well.  </p>

            <p>I managed to fully dry the tent pretty quickly because its small and mostly mesh. As my clothes and sleeping items gradually dried over the next 3-4 hours, I put them all in the tent one by one.  I should have known to check sooner but the socks I was wearing in the Nipissing muck contained a couple of leeches still, one of which managed to attach himself to my leg.  He was quickly flicked off in the tent while changing into less muddy socks, yuck!  </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/drying.jpg")}} alt="Drying" width="80%" src="/nipissing2023/drying.jpg"></img>
 
            <p>By about eight thirty at night, I had dry clothes to wear to sleep and I even managed to fully dry my sleeping bag so I didn’t freeze that night.  The wind never did die down, it continued well into the night and so I was stuck camping on the portage after all, but at least I was dry. </p>

            <p>I ate and repacked everything nearby but out of the way.  I tied down the tent and secured the fly right in the middle of the path because there was nowhere else to put it.  I left the canoe on the side of the path, closer to the waters edge and placed the food bag inside, securing it to the canoe for the night.  There were no suitable trees for hanging my bag so I had to risk it, hoping nothing would get into the food. In the slight chance something did get to my food, I would hear it, and I could head the opposite way down the portage with bear spray in hand if needed.  Once everything was secure, I went to bed early, I was tired and wanted to get up early, in hopes of getting on the lake while it was nice and calm.  I think I was asleep by nine and I was up at quarter to six in the morning.  Almost immediately upon waking I started packing my gear and getting organized, I wanted to get out of here at this point and just go home.  </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/bigbob3.jpg")}} alt="Big Bob" width="80%" src="/nipissing2023/bigbob3.jpg"></img>
 
            <div className="image-frame50">
                <div><br/>
                    <p>It wasn’t long and I was ready to go. I made a quick oatmeal breakfast before leaving.  The wind was nearly non existent this morning, as were the bugs and Big Bob had calmed, making for an easy paddle over to the next portage.  I started the morning with the 790-meter portage, that sure woke me up.  Thankfully, the packs had lost most of their water weight since drying overnight.  I crossed over West Koko Pond again and then completed the 820-meter portage once more.  I stopped for a break, I was now done the longer of the portages, only two more to go and I’m home free.</p>
                </div>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/portage820sign.jpg")}} alt="portage sign" src="/nipissing2023/portage820sign.jpg"></img>
            </div> 

            <p>Once into Indian Pipe Lake, I filled my canteen with water and sterilized with an aqua tab.  The water was nice and refreshing, and so much better than West Koko Pond water.  It wasn’t long and I was at the 320-meter portage into Chibiabos Lake.  As I hiked down the path, I heard a loud rumbling in the forest as I passed the logging road that intersects the trail.  I looked up just in time to watch a fully loaded logging truck fly by, first time that’s happened.  I crossed Chibiabos Lake, there appeared to be someone camping at one of the sites. I wish I could have spent more time here, I guess I’ll have to come back.</p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/indianpipelake2.jpg")}} alt="Indian Pipe Lake" width="80%" src="/nipissing2023/indianpipelake2.jpg"></img>
 
            <p>I made quick work of the last portage, the 345-meter back to Tim Lake.  Once on the lake, I noticed the wind had picked up a bit.  Perhaps still a little gun-shy from the previous day’s events, I was extra careful heading out onto a windy Tim Lake.  I noticed a few kayaks and canoe’s out with people fishing as I paddled towards the Tim River.  The occasional wind gust would catch the canoe and try and turn me around, but I managed to keep myself on course, eventually reaching the opening of the river. </p>

            <p>As I entered the Tim River, I noticed how different it felt from the way in. It was later in the day and the wind and current made the paddle more intense and less ‘lazy’ feeling, I suppose you could say.  The way back felt much longer, but I did eventually find my way back to the dock at the Tim River access point.  I pulled up along side the dock and took a look around, there sure were a lot of cars in the lot.  I went and grabbed the van and parked it near the water.  As I unloaded the canoe, a group of three guys who had also made their way back through the Tim River, returning from a fishing trip, pulled up to the dock.  We spoke a bit; I told them of my fall into the Nipissing and we discussed our various fishing results a while before bidding each other goodbye.</p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/nipissing2023/timriver4.jpg")}} alt="tim river access" width="80%" src="/nipissing2023/timriver4.jpg"></img>

            <p>After securing the canoe to the roof, I set out onto the unpaved road, back towards Kearney and then home.  I was looking forward to being home, after such a long ‘short’ trip, I couldn’t wait to be clean and in my own bed.  I got stuck behind a grading machine for a while, it was slow but kind of cool to watch.  He eventually found a spot to let me pass and I was on my way.  The drive home was a good five hours, giving me lots of time to think about the previous day’s events.  I was just looking to do some fishing, not necessarily looking for adventure, this time I guess adventure found me.  </p>

            <p>I now look back at what happened and laugh at the situation, it definitely had a sense of type two fun in it. I love a good adventure, but sometimes things don’t go as you plan them, so be prepared.  This experience taught me some lessons but it also reinforced that preparation for the unplanned is important.  There are things that I did right, and there are some things that I could have done better.  This experience will, in the grand scheme of things, make me a better and safer camper and so it was worth going through.  In the end, I had an amazing trip, I caught some great fish and made it home safe. I look forward to doing it all again next time, well… minus the swim.</p>
            <p>The End.</p>

</div>
    )
}

export default Nipissing2023;