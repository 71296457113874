import React, { useEffect } from 'react';

const BigTroutLake2021page2 = ({openImageView}) => {

    useEffect( ()=> {
        let e = document.getElementById('nav');
        if ( e !== undefined )
            e.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, []);
    
    return (
        <div className="bigtrout_page2">

                
                <img alt="morning on burnt island" src="/bigtroutlake/burntislandmorning.jpg"></img>
                
                <p>I was up early the next morning, about seven o'clock, which is early to <i>me</i>. I'm not really a big early morning guy, unless i'm camping or fishing. The morning was cool, calm and very cloudy.  Since this was going to be a travel day and I needed to move soon, I got started immediately, packing up all the stuff inside the tent before doing anything else. My sleeping bag, sleeping pad, pillow and any inside stuff was tidied up and ready to go.  With a lot of the work out of the way, all I would need to do is take down my tent and go. Now I could get started making some breakfast, but first... coffee!.                      
                </p>
                             

                <div className="img50-right expandable-image">                
                    <p>I started boiling water over the fire, making coffee on a cool Algonquin morning is always an enjoyable experience.  For my first breakfast in the bush, I brought six eggs, pre-scrambled and frozen. By this morning, they were defrosted enough that I could put them into a pan and cook them easily. A little salt and pepper and they were good to go. The Eggs and coffee were followed shortly by oatmeal and a protein bar, I was now fueled up and ready.                      
                    </p>

                    <img alt="breakfast at burnt island" src="/bigtroutlake/burntislandbreakfast.jpg" onClick={() => openImageView("/bigtroutlake/burntislandbreakfast.jpg")}></img>
                </div>

                <p>I cleaned up my dishes, and packed up around camp. I put out the fire by soaking it multiple times with buckets of water, followed by stirring it around until no hot spots or steam are present. Putting a fire out properly is extremely important, leftover hot spots can reignite and spark fire without the presence of someone to control it, potentially becoming a disaster. Always, always put your fire out completely before leaving camp for any length of time.                    
                </p>    

                <p>I cleaned out and packed up my tent which felt like so much less of a chore compared to my larger tent. I cleaned all of the pine needles that made their way in by simply picking up the tent with an open door and giving it a good shake.  Once the tent was folded and bagged up, I secure everything to its proper pack and piled everything in one spot, ready to go. A final look around camp to ensure I wasn’t leaving anything behind and I was ready for day two of my journey. I piled the gear back into the canoe and hit the water at about 10am, I was on my way to Big Trout Lake.                    
                </p>

                <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Portage Landing" src="/bigtroutlake/portagelanding1.jpg" onClick={() => openImageView("/bigtroutlake/portagelanding1.jpg")}></img>
                    <img loading="lazy" alt="Portage Landing" src="/bigtroutlake/portagelanding2.jpg" onClick={() => openImageView("/bigtroutlake/portagelanding2.jpg")}></img>
                </div>

                <p>The water was calm, the sky was cloudy and there was little to no wind to start the day.  A short paddle, a couple hundred meters from the campsite at most, and I pulled up to the sandy shore of my first portage.  The 790 meter portage would lead me into Otterslide Lake and was a memorable way to start the day.  This portage, while not extremely long, proved to be a little rougher than expected in spots. It starts off all nice and flat, lulling you into a false sense of ease, then <i>BAM!</i>..rocks, roots and mud all over the place. It wasn't a bad way to wake the muscles up for the day, but I was happy to finish the portage. I enjoy the paddling much more, and I had lots of that coming next.                    
                </p>
                
                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/portageend1.jpg")}>
                    <img loading="lazy" alt="end of the portage" src="/bigtroutlake/portageend1.jpg"></img>
                </div>

                <p>It wasn’t long before I was heading away from the portage and into Otterslide Lake. I had run into a couple of people and their dogs at the portage, I believe they were also doing the Big Trout loop but with a slightly different schedule.  They had planned on staying on Otterslide Lake for the night. I would love to camp on Otterslide Lake sometime but I needed to get to to Big Trout that day.  This was the first time I had seen anyone out this far, it was very quiet otherwise. I continued paddling making my way further out into the lake.                     
                </p>

                <p>As I rounded the big point on the north side, just after the portage I came across a couple of loons fishing in the lake. I sat and watched them as they ducked under the water. I did my best to get closer and take pictures, but each time I got to close they would dive and come back up a little further away.   I chased them for a while, seems they were going the same way that I was anyway. I did eventually get a couple nice shots before leaving them to their lunch.                      
                </p>

                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/loon.jpg")}>
                    <img loading="lazy" alt="loon" src="/bigtroutlake/loon.jpg"></img>
                </div>

                <p>I continued north on Little Otterslide, passing by to the left of the big island and making my way into the small creek that connects to Big Otterslide Lake.  This short section of creek was packed full of grass, and lily pads making it a slow paddle, but the scenery was great and it was a nice change from a large body of water.  I saw little frogs resting on the grasses laying in the water, they would hop away if I got too close. I also passed some really nice looking water lilies. The creek was short and before I knew it I was heading into Big Otterslide Lake and fighting a good wind for the first time since setting out.  I paddled steadily towards my goal, a small boxy inlet on the north shore ahead of me, with a massive log jam in the area.  It was a bit tricky trying to find the actual portage sign, it wasn’t really visible until I was right on top of it.  Once on shore, I unloaded my gear and prepared for a short carry. I was looking forward to finally getting into the creek on the other side.                    
                </p>

                <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Portage Landing" src="/bigtroutlake/portagelanding3.jpg" onClick={() => openImageView("/bigtroutlake/portagelanding3.jpg")}></img>
                    <img loading="lazy" alt="log jam at the portage" src="/bigtroutlake/logjam.jpg" onClick={() => openImageView("/bigtroutlake/logjam.jpg")}></img>
                </div>

                <p>Otter Slide creek is a long, shallow creek with a number of portages breaking it up.  I was at the first portage, a short 250 meter haul which takes me around this massive log jam and into the first part of the creek. This portage was very flat and uneventful with shallow rocky landings, it wasn’t long before I was back in the canoe paddling down the creek.                    
                </p>

                <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Portage Landing" src="/bigtroutlake/portageend2.jpg" onClick={() => openImageView("/bigtroutlake/portageend2.jpg")}></img>
                    <img loading="lazy" alt="the creek" src="/bigtroutlake/creek1.jpg" onClick={() => openImageView("/bigtroutlake/creek1.jpg")}></img>
                </div>

                <p>This was followed by a second, 390 meter portage, taking you to another section of creek, but this one much larger. After that, you come to a smaller 265m portage, bringing you to the longest section of the creek.  The creek wound back and forth, and seemed to go on forever sometimes. It was a beautiful, relaxing paddle and I enjoyed every moment.
                </p>

                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/beaverdam.jpg")}>
                    <img loading="lazy" alt="Beaver Dam" src="/bigtroutlake/beaverdam.jpg"></img>
                </div>

                <p>In more than one place I had to get out and pull the canoe over a beaver dam, or maneuver around shallow rocks. The longest section of the creek can take a while to get through but I found the slow, meandering pace enjoyable and it gave me a chance to really take in my surroundings and appreciate where I was. I was not fortunate enough to see any moose, but the sheer number of frogs, dragon flies, and various bird species all around me was very cool.                      
                </p>

                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/creek2.jpg")}>
                    <img loading="lazy" alt="the creek" src="/bigtroutlake/creek2.jpg"></img>
                </div>

                <p>I was nearing the end of the creek, where I would find my longest portage of the day. This 730 meter hike would lead me into the final length of Otterslide Creek.  For only 730 meters it sure felt longer at this point because I had been travelling all day, and while the trail was mostly flat and free of any major obstacles, it still had it's share of mud and rocks to deal with.  I paddled down the rest of the creek, finally coming to the very last portage of the day, an easy 105 meters into Big Trout Lake.  
                </p>
                
                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/portageend3.jpg")}>
                    <img loading="lazy" alt="sandy Portage Ending" src="/bigtroutlake/portageend3.jpg"></img>
                </div>

                <p>The landing was shallow and sandy with a few big rocks around, like a little beach.  I pulled up and unloaded my gear, ready to make quick work of this one.  This portage routes you around a waterfall at the end of the creek, something definitely worth checking out. I walked around it, gave it a good look and took some pictures. After all the travelling, I was tired, hot and seriously considering dunking myself in the water, but I was short on time and needed to move fast because daylight was fading soon and I still needed to paddle a good portion of Big Trout Lake and find a campsite.                     
                </p>

                <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="The Waterfall" src="/bigtroutlake/waterfall1.jpg" onClick={() => openImageView("/bigtroutlake/waterfall1.jpg")}></img>
                    <img loading="lazy" alt="The Waterfall" src="/bigtroutlake/waterfall2.jpg" onClick={() => openImageView("/bigtroutlake/waterfall2.jpg")}></img>
                </div>

                <p>So off I went, paddling away from the portage landing as quickly as I could, eager to get into the lake and find a campsite.  The waterfall runs into a small inlet on the south-east corner of Big Trout, sheltered from the lake but it soon opens up in a massive body of water.  The paddle through the inlet was nice and easy, but I could see from a distance that my circumstances were about to change.  As I popped out into the open lake, I was hit head on with a solid headwind and waves that were starting to get pretty big.  I paddled hard, working my way north west towards the large peninsula that sticks out west of the portage.  I made slow but steady progress across the lake in search of my next campsite. I found on the map, there was a campsite on the west side of that same peninsula, hopefully I could just make it there. The time was well after 5pm already, I would be losing sun soon, and I had a way to go yet. I was really hoping the site was both vacant and at least nice, especially since I was to be spending two days on it.  I dug in with the paddle as hard as I could and made my way across the lake.                      
                </p>

                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/bigtrout1.jpg")}>
                    <img loading="lazy" alt="Into Big Trout Lake" src="/bigtroutlake/bigtrout1.jpg"></img>
                </div>

                <p>I pulled up to the site around 6pm, it was unoccupied and looked good from the canoe.  The landing was very rocky and not the easiest to get up but it did the job.  The whole front of the campsite was like one big rock laying on an angle. One side of it was a large flat cliff that dropped into the deep water.  The other side slowly went deeper into the water on a nice gradual slope.  There was a really cool looking pine tree that stuck out over the water but the rock was mostly open with a clear view.  There was a path from the canoe landing leading back into the forest.                      
                </p>

                <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Big Trout Campsite" src="/bigtroutlake/campsite2.jpg" onClick={() => openImageView("/bigtroutlake/campsite2.jpg")}></img>
                    <img loading="lazy" alt="Big Trout Campsite" src="/bigtroutlake/campsite2-1.jpg" onClick={() => openImageView("/bigtroutlake/campsite2-1.jpg")}></img>
                </div>

                <p>I followed the path, maybe fifty meters in and I found the actual campsite. There was a nice stone fire pit with plenty of available firewood, and a round cooking grate. I can't recall finding a round grate in the park before, it fit the fire perfectly. There was a nice set of wooden benches and a few suitable spots for a tent. The site was relatively sheltered in case there happened to be high wind or rain.  The site also offered some ideal spots for shore fishing, but the highlight for me was the view. This site was facing the sunset perfectly and had a great view across Big Trout Lake. I was really happy to have lucked out on a nice site, it didn’t take long to decide this would be my home. I had originally planned to make more distance on Big Trout Lake, but with little time until dark, this was definitely the best option.                      
                </p>

                <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Big Trout Campsite" src="/bigtroutlake/campsite2-2.jpg" onClick={() => openImageView("/bigtroutlake/campsite2-2.jpg")}></img>
                    <img loading="lazy" alt="Big Trout Campsite" src="/bigtroutlake/campsite2-3.jpg" onClick={() => openImageView("/bigtroutlake/campsite2-3.jpg")}></img>
                </div>

                <p>I unloaded the canoe and moved all the bags back into the site. I brought the canoe up into a sheltered area far from the shore, knowing Big Trout, I wasn’t taking any chances on it being blown into the water over night, regardless of how heavy it is.  I quickly setup my tent and sleeping pad, then setup my bag hang. Once all the setup work was done, I sat down to actually relax and made some dinner. I had been keeping my calories up all day with protein bars and various nuts and candy but I was really looking forward to something more substantial and also not having to paddle while I eat is nice.                    
                </p>

                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/sunset1.jpg")}>
                    <img loading="lazy" alt="Big Trout sunset" src="/bigtroutlake/sunset1.jpg"></img>
                </div>

                <p>The night’s meal was a creamy chicken noodle dish with a pound of crispy bacon cooked over the fire. It was tasty and filling, lots of much needed calories.  I watched a beautiful sun set on a clear sky, and while the lake never really calmed completely, it was less windy than it had been. Since the next day would be a rest day, I decided to stay up a little later and enjoy the night by the fire. 
                </p>

                <p>At one point, I realized it had become “Algonquin dark” as I call it, and I crawled out onto the rocky point at the shore to check out the sky.  This vantage point gave me a perfect view of the starry night.  In the cities, even in my small town, we get used to seeing such a limited number of stars in the sky. That night, as I lay out on the rocks under the stars, I was able to see the Milky Way so much more clearly than I normally can and it was amazing.  The sky was just filled with stars and the big dipper was huge and plastered right out in front of my site making for a really neat sight.  It was moments like these that are nice to share with others, it’s something I can’t really do justice to in words and I could never get my camera to take a good picture of the sky.  I’ll never be able to truly describe in words how beautiful the sunset on Big Trout can be (so I took pictures), or how big the sky feels in that moment under the stars, but if you’ve been there, you’ll know what I mean when I say that you feel somehow connected with the rest of the universe during these experiences.                     
                </p>
               
                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/sunset2.jpg")}>
                    <img loading="lazy" alt="Big Trout sunset" src="/bigtroutlake/sunset2.jpg"></img>
                </div>

                <p>Day two had been quite a day, lots of travel and exploration, but it was coming to an end, still, I was on track with the plan I had, so far, so good. I was looking forward to a free day to relax and explore before continuing on my journey. I crawled into the tent close to midnight after enjoying a long fire, watching the stars and listening to the loons call across the lake.  I was tired and tomorrow was another day.                    
                </p>

                <br/><hr/><br/>

                <p>I slept well for a second night, getting out of the tent around 7am again. The first thing I did was setup a fire, and get it ready to go but I didn’t light it yet. I wanted to head out on the canoe and do some fishing around the area while the water was still calm.  I trolled a number of spoons, though I don’t have the ideal setup for a real deep troll, I attempted what I could. I also moved around from spot to spot and casted various lures. I was using mostly mepps spinners and some small spoons. After an hour or two of various fishing attempts, I had managed to pull in one small perch just off the shore from my camp. He was too small to keep, and I really wanted trout anyways, so back he went. It was <i>a</i> fish so it counts, I reassured myself.  I decided to take a break from fishing and head back to camp for breakfast.                    
                </p>

                <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="sunrise on Big Trout" src="/bigtroutlake/sunrise.jpg" onClick={() => openImageView("/bigtroutlake/sunrise.jpg")}></img>
                    <img loading="lazy" alt="Boiling water for coffee" src="/bigtroutlake/waterboil.jpg" onClick={() => openImageView("/bigtroutlake/waterboil.jpg")}></img>
                </div>

                <p>When I got back to camp I brought down the food bag.  Today was special, today was my pancake breakfast day.  I was excited, I like pancakes.  I pulled out the butter from the cooler, the oil and syrup and a pan. I brought a flat wooden spatula that I could use to flip them. Now I like to think that I learned some lessons throughout my trip, and one of them was definitely to never cook pancakes on your pocket rocket stove.  I think the flame intensity is just too much and not ideal for something like a pancake.  The fire provided a much more even heat and better end product. Lesson learned.  The pocket rocket is far more suited to boiling water than it is actually cooking. So after ruining the first batch, I made some presentable pancakes over the coals, loaded them with butter and syrup and ate every last bite.                    
                </p>

                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/firewood.jpg")}>
                    <img loading="lazy" alt="Firewood" src="/bigtroutlake/firewood.jpg"></img>
                </div>
                <p>I took the morning slowly, it was a rest day and it was early, I had all day. I ate my pancakes and enjoyed a coffee by the fire before figuring out how I would spend my day. I decided to start out with fire wood hunting. I wanted to have a good fire and I didn’t want to have to go out and get wood again, so I launched the canoe and followed the shore looking for quality sources of drift wood. I filled my canoe up until I couldn’t possibly fit more <i>(I totally could have fit more)</i>, I didn’t want to make more than one trip.  I brought my folding saw with me to cut off pieces when needed.  Once my canoe was at capacity I headed back to camp and unloaded everything just behind the fire pit.  I setup a tarp above and behind the fire area, where I could sit on a bench and be out of any rain while still being near the fire, it also give me a place to keep firewood dry, just in case we got the rain that was in the forecast.  I was set, I had a full day and night of quality firewood now.                     
                </p>

                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/bigtrout2.jpg")}>
                    <img loading="lazy" alt="Big Trout Lake" src="/bigtroutlake/bigtrout2.jpg"></img>
                </div>

                <p>The day was turning into a beautiful sunny day, some clouds in the sky but nothing that looked like rain.  I went back out into the canoe deciding I would do some exploring along the shore near my camp.  I had noticed on the map, there is a stream that leads to a small round pond like body of water, not far behind where my campsite was. I thought if I followed the stream, maybe I could find it. Well, I’m pretty sure I found the stream, and there were signs of other people, as in garbage, unfortunately.  I picked up what I found to pack out with me but the so called stream was all dried up and the bush so dense, there was no way I was getting through. So I decided to just keep exploring along the shore close to camp, not wanting to stray too far.                    
                </p>

                <p>After a while of exploring the general area I went back to camp for a mid-afternoon lunch.  I got a quick fire going and boiled water for some soup. As a side I also enjoyed a protein bar and some candy. After lunch I stripped down to my boxers and went for a short dip in the lake to clean up a bit and refresh myself. The water was actually really nice and it was extremely refreshing.  I sat at the fire for a while to dry off before setting out for a little bit of late afternoon fishing.                     
                </p>
                
                <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/treeroots.jpg")}>
                    <img loading="lazy" alt="Large Tree Roots" src="/bigtroutlake/treeroots.jpg"></img>
                </div>

                <p>I started out fishing from the canoe but the wind was starting to blow and the sky was threatening rain. Across the lake I could see angry looking clouds and lightning flashing in the distance.  I continued my fishing off the rocky edge of the campsite. The ledge made an ideal shore casting location and while I fished I enjoyed watching what appeared to be quite a good storm raging across the other side of Big Trout Lake.  By late afternoon the storm had started heading towards my campsite, I continued to fish in the rain until the storm got too close for comfort, then I packed it up and took shelter beneath my tarp behind the fire that I had been keeping alive. The rain became heavy at times but never torrential. The storm was pretty wild for a time but I remained dry under my tarp and enjoyed a raging fire while sipping hot chocolate and snacking. I had a huge load of wood and I wasn’t going to let it go to waste. I had a great fire that night despite the storm, it kept me warm and dry all night and kept my spirits up while everything else was wet.  I can honestly say I fully enjoyed the thunderstorm that night.                     
                </p>

                <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Storm on Big Trout" src="/bigtroutlake/storm1.jpg" onClick={() => openImageView("/bigtroutlake/storm1.jpg")}></img>
                    <img loading="lazy" alt="Storm on Big Trout" src="/bigtroutlake/storm2.jpg" onClick={() => openImageView("/bigtroutlake/storm2.jpg")}></img>
                </div>

                <p>I made a quick dinner of rice and beef jerky that night so cooking during the storm was nice and easy. When I was done eating, I grabbed a snack and hung the bag for the night. As the evening wore on and night came, the rain slowed but it kept up on and off long after I went to bed.  Day three had finally come and gone, I had a long day of travel ahead of me and a new campsite to look forward to the next night, I was excited but I would surely miss this one. I hoped to get to bed at a reasonable time so I could get up and pack up camp as early as possible, I think I finally drifted off around ten or eleven o’clock that night.                    
                </p>
         
        </div>
    )

  }

  export default BigTroutLake2021page2;