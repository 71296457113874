import React from 'react';
import { Helmet } from 'react-helmet';

function Content() {
   
    return (
        <div className="content-container">
            <Helmet>
                <title>Algonquin Adventures - Camping Blog</title>
                <meta name='description' content="Check out some of the best scenery Algonquin Park has to offer. Back country camping stories. Find inspiration for your next camping adventure!" />
            </Helmet>
            <div className="text-container title">
                <p>
                Just about 3 hours north of Toronto lies the beautiful landscape of Algonquin Park. It is here that many people come to get away from it all.  I am among the many campers who prefer to go farther into the
                interior of Algonquin to explore the parks true serenity.  As a young kid, I was lucky to have what I refer to as a “camping family”.  Cottage time and camping were a large part of our summer activities.  At a very young age I got to explore the depths of Algonquin as my mother and father would lug my sister and I with all of our gear through the bush each year for many days at a time.  Typically we would leave just after school let out for the summer at the end of June.
                I always looked forward to this time of year and going on another adventure.
            </p>
            </div>
            
            <div className="slide slide-1">
            
            </div>


            <div className="text-container">
                <p>There are many who would question the sanity of someone willing to haul 100lb packs and a canoe through the Algonquin back country (just ask my wife), but there are few things I find as rewarding
                as the challenge of trekking my way through the wilderness, surviving in the wild without all of life's typical necessities, surrounded by it’s unique beauty for even a short period of time, being able to enjoy a sense of quiet and complete darkness away from the big city lights, there’s nothing quite like it.  I believe this is an experience to be shared so over the years I have taken my friends, my wife and most recently our two young girls into Algonquin so they can experience it for themselves. In order to share our adventures with others I decided to write this website to log our trips and post our pictures along the way, enjoy!
                </p>
            </div>           
           

            <div className="slide slide-2">
            </div>

            <h3>HOW DO YOU GET THERE?</h3>
            <div className="map-slide-text">
                <ul>
                <li>Access Points on the west side of the park, #1-4 are accessible from Hwy 11</li> <br/>
                <li>Access Points #5-13, #16-18 are all accessible from Hwy 60, some much closer than others.</li> <br/>
                <li>Access Points located on Northern side of the park are accessible by travelling Hwy 17 and going south along Brent Rd.</li><br/>
                <li>Algonquin Park Access Points - Click Here for a <a href="https://www.algonquinpark.on.ca/visit/camping/access-points-for-backcountry-canoeing.php" rel="noopener noreferrer" target="_blank">Complete List</a> -</li>
                </ul>

            
              
            </div>
        
            <div className="map-slide">                   
                <iframe id="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d504255.5292632269!2d-78.67058049720916!3d45.71816694637098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cd679c83c416dff%3A0x436dbbeb68f460eb!2sAlgonquin%20Provincial%20Park!5e0!3m2!1sen!2sca!4v1611518405439!5m2!1sen!2sca" title="google map" width="600px" height="450px" frameBorder="0" styles="border:none;" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
        
            <div className="map-slide-text-container">

                <div className="map-slide-text">
            
                    <h2>PLANNING TIPS</h2>

                    <ul>
                <li>Use a map like <a href="https://www.algonquinmap.com/" target="_blank" rel="noopener noreferrer">Jeff's Map</a> to plan the route you want to take.</li><br/>
                <li>Make <a href="https://www.algonquinpark.on.ca/visit/camping/reservations_general.php" target="_blank" rel="noopener noreferrer">reservations</a> well ahead of time to ensure you get spot on the lake you want. Depending on the time of year, it can be very busy and paddling around looking for the one empty campsite is no fun. You can make reservations from the Algonquin website listed below.</li><br/>
                <li>Algonquin Park is accessible through a number of different access points located all around the park. Pick one that interests you and plan from there.</li><br/>
                <li>Plan a route according to your abilities, plan carefully and keep safety in mind at all times.</li><br/>
                <li>Make a gear list and go over again and again. Detailed preparation is key to a successfull and enjoyable trip.</li><br/>
                <li>Always prepare for bad weather, pack important gear such as electronics in dry bags.</li><br/>
                <li>Wrap clothing and sleeping bags in garbage bags for extra water protection.</li><br/>
                <li>Make someone else aware of your trip plans, in the event you don't come back on schedule, there is someone who can summon help.</li><br/>
                <li>If you are portaging, proper footwear should be one of your most important considerations.</li><br/>
                <li>Always pack 2 or 3 ways to make fire, waterproof matches are a great backup.</li><br/>
                <li>Depending on the time of year, the Algonquin nights can be quiet cold, when sleeping in a tent this becomes very apparent. Pack warm clothing and use a quality, seasonally appropriate sleeping bag.</li><br/>
                <li>Again, depending on the time of year, and your tolerance to flies and mosquito's, bug spray and long clothing is pretty much a necessity.</li><br/>
                <li>Plan your trip in such a way that you <a href="https://www.algonquinpark.on.ca/visit/camping/leave-no-trace.php#:~:text=Leave%20rocks%2C%20plants%20and%20other,%2C%20furniture%2C%20or%20dig%20trenches." target="_blank" rel="noopener noreferrer">Leave No Trace</a> during your stay.</li>
            </ul>
            </div>
          </div>
          </div>

            <hr width="80%" />

            <div className="youtube-video-container">
                <iframe title="yt-video" width="560" height="315" src="https://www.youtube.com/embed/MLP12yfooeM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>                        
            </div>
         
        </div>
    )

}

export default Content;