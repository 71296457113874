import React from 'react';
import { useEffect } from 'react';
import Slides from './imagelist.json'


const GalleryImageView = ({slideIndex, setSlideIndex, numSlides, imageViewActive, setImageViewActive}) => {
    
    let touchendX = 0,
        touchstartX = 0;

    const slides = Slides.images;

    const handleGesture = (e) => {
        
            touchendX = e.changedTouches[0].screenX;

            let xDiff = Math.abs(touchendX - touchstartX);

            if (touchendX < touchstartX && xDiff > 100 ) handleNextSlide();
            if (touchendX > touchstartX && xDiff > 100 ) handlePrevSlide();
        }

    const handleTouchStart = (e) => {
       
        touchstartX = e.changedTouches[0].screenX;
    }

    const setupSwipeEvents = () => {

        const slider = document.querySelector('.image-container img');
 
        slider.addEventListener('touchstart', handleTouchStart, { passive : true });
        slider.addEventListener('touchend', handleGesture, { passive : true });
    }

    const handleCloseImage = () => {
        if ( imageViewActive !== -1 )
            setImageViewActive(-1);

        return false;
    }

    const handlePrevSlide = () => {

        if ( slideIndex >= 1 ) {
            setSlideIndex((slideIndex - 1));
            setImageViewActive(slides[slideIndex-1].url);
        }     
    }

    const handleNextSlide = () => {
       
        if ( slideIndex < numSlides-1 ) {
            setSlideIndex(slideIndex + 1);
            setImageViewActive(slides[slideIndex+1].url);
        }
    }

    useEffect( () => {

        if ( window.innerWidth <= 1050 )
            setupSwipeEvents();

        return () => {
           let e = document.querySelector('.image-container img');

           if ( e !== undefined ) {
            e.removeEventListener('touchstart', handleTouchStart);
            e.removeEventListener('touchend', handleGesture);
           }
        }  
    });

    return (
        <div className="image-view-container">

            <div className="image-container">
                    <div>
                        <div className="prev-slide-btn" onClick={handlePrevSlide}>&#x0003C;</div>
                        <div className="close-btn" onClick={handleCloseImage}>X</div>
                        <img alt="" src={imageViewActive} />
                        <div className="next-slide-btn" onClick={handleNextSlide}>&#x0003E;</div>
                        <div className="slide-caption">Slide {slideIndex+1} / {numSlides} - {slides[slideIndex].text}</div>
                    </div>
            </div>


        </div>
    )

}

export default GalleryImageView;    