import React, { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import ImageView from './imageview';

const LindaLake2020 = () => {

    const [imageViewState, setImageViewState] = useState(null);

    useEffect( ()=> {
        let e = document.getElementById('nav');
        if ( e !== undefined )
            e.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },[]);
  
    return (
        <div className="triplog">
             <Helmet>
                <title>Linda Lake 2020</title>
                <meta name="Linda Lake Camp Log" content="Linda Lake Algonquin Park. Great fishing, bass, lake trout, brook trout fishing. Algonquin Park Access Point at Source Lake, accessible from Highway 60 is the nearest point of entry if you wish to do some camping on this lake. Travel time is approximately 4-5 hours canoeing and portaging, depending on weather."></meta>  
             </Helmet>

             {imageViewState ? <ImageView closeImageView={() => setImageViewState(null)} imgSrc={imageViewState} /> : null }
           
            <h3>Linda Lake</h3>
            <p>The year 2020 has been a hell of a ride, with Covid-19 shutting down everything everywhere, we didn’t have the chance to do much over the summertime as a family. I had been planning a second Algonquin trip for us in September, it would be a perfect way to get out and do something, while also distancing us from people.  We opted to go in late September so that we could enjoy the outdoors without the presence of bugs, and if we were lucky, we might catch the beautiful color change in the trees that occurs each fall.  I watched the weather nervously, we had been seeing a lot of rain and stormy weather but the long range forecast looked promising.   We settled on September 21st, a Monday and it would be a four day trip.</p>
            <p>So now I needed a plan. I spent a lot of time going over Jeff’s Map, looking at various routes, trying to find one that stood out to me. I had been on many of these paths already, and I wanted to find something memorable for the kids.  This would be the second time I’ve taken my two girls on a portaging trip in Algonquin, so they had some experience, but I promised my wife I wouldn’t over work everyone so I tried to find a small route we could do reasonably in a day.  We decided on four days total for the trip, this would give us one day to travel into camp, two days rest and recreation and one day left to travel out again.</p>

            <img className="expandable-image" onClick={ () => { setImageViewState("/lindalake/routemap.jpg") }} alt="map of route" width="80%" src="/lindalake/routemap.jpg"></img>

            <p>
            Linda Lake is a scenic little lake, only a few lakes and portages in from highway 60. I had been to Linda Lake twice in the past with my parents and I remember it fondly. One of the best memories I have here was catching bass non-stop from the shore of our campsite, using grass hoppers that were hopping everywhere for bait. Linda Lake is fairly small and remote enough that it’s pretty quiet later in the year. There are two main ways to get there, one of them is through the access point at Source Lake, and the other is from Canisbay Lake, both a short drive from highway 60.  The only difference between the two routes seems to be that one consists of more paddling and one has more portaging.  The route with more portaging requires that you do only two portages, but one of them is very long and I’ve been down it, I didn’t want to subject everyone to that torture.  The portaging on the other route is much more broken up with small lakes in between. This is the route that I chose.
            </p>

                <div className="image-text hwy-60-pics">
                    <img className="expandable-image" onClick={ () => { setImageViewState("/lindalake/moosecrossing.jpg") }} alt="map of route" src="/lindalake/moosecrossing.jpg"></img>
                    <img className="expandable-image" onClick={ () => { setImageViewState("/lindalake/hwy60.jpg") }} alt="map of route" src="/lindalake/hwy60.jpg"></img>
                    <p>A section of highway 60, this is how you can tell your in moose country.</p>
                </div>
            <p>
            We would begin at access point #7, Source Lake.  Our way in would involve four portages, two of them rather small, the other two a little larger but manageable for sure.  We would cross four lakes before coming to Linda Lake upon which we would make our home for the next couple of days.  My kids, Petra who would be almost 13 years old and Maya, who recently turned 8 were really looking forward to camping.  We trained for the trip, often hiking hills or riding bikes to improve our endurance throughout the summer.
            </p>

            <p>
                While preparing for the trip, I aimed to pack lighter than last time. The kids were growing so there was less room in the canoe, and we really didn’t feel like lugging the big cooler all that way. I was hoping to make this a single carry run on the way in, but there’s just so much stuff for four of us.  I thinned the packs as much as I could and I think overall I did well, but we would likely still need to run every portage twice.
            </p>

            <p>
            I had all of our gear ready, I cleared the camera cards, charged the batteries and updated the GPS as per my usual pre trip ritual.  Since we weren’t bringing the big cooler, we couldn’t really carry in any food requiring refrigeration.  I had picked up a small collapsible cooler which was pretty light and did a really great job, I was able to attach it to one of the packs with a carabiner.  This allowed me to bring in a frozen bottle of milk, a pack of sausages and some eggs, that would be enough for a few good meals, the rest would be dried food like beef jerky, dried pepperoni, pasta and rice packs.  For portaging and snacking there was a large selection of granola and chocolate bars, nuts and dried fruit. Coffee and Tea were a necessity of course as well as some oatmeal and packaged soups. I don’t think we’ll starve out there.
            </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/sky1.jpg") }} alt="sunny day" src="/lindalake/sky1.jpg"></img>
                
            <p>
            The weather report could not have been better come the week of the trip. Mostly Sunny, with few clouds but no rain and temperatures that were above the norm this time of year, even for us down here in southern Ontario.  I was excited but I’m always a little worried as weather can change pretty quickly, still, I had high hopes our trip would go well.  Nothing quite dampens a trip like working your butt off to get to a campsite only to sit in a tent in the rain for who knows how many days.  
            </p>

            <p>
            The day of the trip, I loaded all of the gear into the van, with the canoe strapped solidly to the roof.  I had everything ready to go, I tried to sleep early so I could be ready for the long drive.  I was up at 2AM, I made a quick pot of coffee and went over my gear list, making sure I wasn’t forgetting anything vital. I woke everyone so they could get ready quickly, and within thirty minutes we were on the road, my coffee in hand and ready for adventure.
            </p>

            <p>
            I tried to let everyone get some sleep during the drive, I was wide awake and excited for the drive but wanted them to save their energy for the long day ahead.  I don’t think anyone slept through the night but we were all pretty hungry as the sun started to come up. When we hit Huntsville, Ontario, we stopped for gas and food and partly because it’s such a nice place and I like to drive through it.  We stopped at a Tim Hortons, put on our masks and grabbed some breakfast and a new coffee. 
            </p>

            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/parkinglot.jpg") }} alt="parking lot" src="/lindalake/parkinglot.jpg"></img>
        
            <p>
            Once again we set off towards the park, following highway 60 until we arrived at the Source Lake entrance. We pulled in and parked the van shortly after 8AM. The parking lot was pretty empty and there was no one around on this quiet Monday morning.  We unloaded the canoe and set it in the water alongside the dock.  As we pulled the camping gear out of the van, we carefully placed it into the canoe trying to keep it well balanced and comfortable.  One last look around the van to make sure we weren’t missing anything, we posed for a few quick pictures and off we went.
            </p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/sourcelake2.jpg") }} alt="source lake" src="/lindalake/sourcelake2.jpg"></img>
            <p>My wife and kids pretending their paddles can fly, I guess me didn't need the canoe afterall!</p>
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/sourcelake3.jpg") }} alt="source lake" src="/lindalake/sourcelake3.jpg"></img>
            </div>
            <p>The morning was cool and calm, a beautiful start to the day. The sky was clear and there was no wind at all.  We made the short jaunt across Source Lake to our first portage of the day, a short 540 meter into Bruce Lake.  
            </p>
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/sourcelake1.jpg") }} alt="source lake" src="/lindalake/sourcelake1.jpg"></img>
            <p>A calm morning on Source Lake, a perfect beginning for our trip.</p>
            </div>
            <p>
            Bruce Lake is a very small lake and the next, portage was directly across from where we were.  We made the quick paddle to the other side to our next challenge. This portage was a slightly lengthier 920 meters and brought us to Raven Lake.  The trail was pretty rugged and very muddy for the most part but we pulled through, it sure did feel like a full kilometer.  While hiking this portage we crossed paths with a solo canoer going back in the opposite direction, he assured me we were close to the end.
            </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/brucelake.jpg") }} alt="bruce lake" src="/lindalake/brucelake.jpg"></img>
 
        <p>
        Looking out onto Raven Lake, we try and gauge where the next portage was, this lake is rather narrow but long, we had to paddle east down the length of it to find our next stop.  The landing was shallow and muddy making it very difficult to load and unload the canoe without getting wet but we did our best.  I always double wrap our sleeping bags and clothing in plastic, on top of being in a water resistance backpack just in case. Few things suck more than a wet sleeping bag when you’re sleeping in a tent. 
        </p>

        <p>
        The paddle down Raven Lake was nice and calm, the weather was ideal and we had no trouble making it to our next portage, a relatively short 435 meter into Owl Lake. This was yet another muddy portage. 
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/ravenlake.jpg") }} alt="raven lake" src="/lindalake/ravenlake.jpg"></img>

        <p>
        Owl Lake was also a long, narrow lake and we had to go from end to end, making it technically our longest paddle of the day, though that’s not really saying much as it wasn’t really all that much distance.  We made great time paddling across this lake as well, enjoying a scenic shoreline all along the way.  We found many places in these lakes that I think would be ideal for moose watching early in the morning, but we were just passing through.
        </p>

        <div className="muddy-portage-pic">
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/portage1.jpg") }} alt="muddy portage" src="/lindalake/portage1.jpg"></img>
        <p>A boardwalk through a trail of mud.</p>
        </div>
        <p>
        We had finally come to our last and longest portage of the day. This one was nearly a kilometer and a half of ups and downs, roots, rocks and deep mud pits. The trail was extremely muddy, forcing me to detour around many portions of the trail, this made for a difficult time hauling my heavy aluminum canoe.  I found myself balancing on slippery rocks and logs, hopping from one to the other, finding my way through the forest as best I could with my family close behind me.  
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/portage2.jpg") }} alt="muddy portage" src="/lindalake/portage2.jpg"></img>
        <div className="image-text">
            <p>One of the many section of muddy trail.</p>
        </div>
        <p>
        I had started off carrying the canoe and brought it so far in that it felt like I was about half way through the portage before I put it down. Then I went back for the second load, bringing everything up the trail in stages. We switched back and forth as we progressed up the trail, three times. I guess we were not half way after all.  Part way through the trail, some sort of small bush trail/road intersects with the portage, I made extra sure I was following the right path as I did not want to end up on some unknown bush trail and get us lost.  
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/portage3.jpg") }} alt="muddy portage" src="/lindalake/portage3.jpg"></img>
        
        <p>
        I reached the end of the portage with the canoe first, and started making my way back for the second load, passing my wife and kids along the way as they made their way towards the finish. I didn’t want to stop and rest just yet, we were so close to finishing. I told them sit and relax a minute as I went back for the rest.  It felt like forever going back to where we had left the last bit of gear, even without carrying a load on your back, the portage was challenging and hazardous enough as it was.
        </p>

        <p>
        As I rounded a bend in the trail, not far from the lake I heard loud splashing and movement. I quickly stopped and peered through the thinning trees into a marshy area along the shoreline where I saw something large and dark.  The animal was obscured by trees and before I could get a better look, it vanished into the forest. I’m not certain what it was, but I believe it was probably a moose or a bear from the size, whatever it was, I kept an extra close lookout on my way back. 
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/lindalake1.jpg") }} alt="linda lake" src="/lindalake/lindalake1.jpg"></img>
        <div className="image-text">
            <p>Linda Lake at last!</p>
        </div>
        
        <p>
        I finally made it back to the end of the trail, no more sighting of whatever I saw in the forest thankfully.  We were all exhausted from the hike, this was definitely one of the tougher portages, made very challenging because of the sheer amount of mud and water we had to navigate around. We had finally made it to Linda Lake, our home for the duration of the trip.  The landing at the edge of the lake was very nice, and covered in orange pine needles.  We loaded up the canoe one more time and shoved off hoping to find our campsite.  
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/lindalake2.jpg") }} alt="linda lake" src="/lindalake/lindalake2.jpg"></img>
        <div className="image-text">
            <p>Look at all those shades of red and orange, dotted with some green, what a sight.</p>
        </div>
        <p>
        While planning this trip, I had considered trying for the well-known island site on this lake, however we opted to stick to a campsite on shore for better access to fire wood and more room to explore, plus in the event the wind did pickup, we would be much better sheltered inland.  We headed for the southernmost campsite, directly across from the portage landing.  
        </p>
 
        <div className="muddy-portage-pic">
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/trees3.jpg") }} alt="linda lake" src="/lindalake/trees3.jpg"></img>
        </div>

        <p>
        Linda Lake was a little windier by the time we had arrived but not so much as to cause us any problems crossing it. We arrived at our potential home and looked it over. This site was actually pretty nice. It had a really nice bench setup around a well-built fire pit, there was a clean, soft and sizable tent location and we had some extra shelving for kitchen use which was super convenient. There was a nice grate for cooking, and even a few good poking sticks for the fire. This was it, we unloaded the canoe and started setting up camp.
        </p>

        <div className="hwy-60-pics">
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/campsite2.jpg") }} alt="campsite" src="/lindalake/campsite2.jpg"></img>
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/campsite3.jpg") }} alt="campsite" src="/lindalake/campsite3.jpg"></img>
        </div>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/campsite1.jpg") }} alt="campsite" src="/lindalake/campsite1.jpg"></img>
                
        <p>
        The tent went up easily and we put all of our sleeping bags and clothing inside, making a cozy place to retreat to at the end of the day.  Shortly after we gathered wood and made a fire, winding down after a long strenuous day.  Dinner for the night was Italian sausage cooked on the fire with Fettucine Alfredo pasta mix.  We made some hot chocolate and kept a few snacks, then hung the remaining food from a suitable tree outside of camp.  We relaxed around the fire until falling into a deep sleep, it felt great to fall asleep to the sounds of Algonquin again, this was one of my favorite parts.
        </p>

        <div className="image-text">
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/dinner1.jpg") }} alt="dinner" src="/lindalake/dinner1.jpg"></img>
        <p>Sausage and Fettucine cooking on the fire.</p>
        </div>
        <p>
        The next morning, I was up first. Not wanting to waste a good Algonquin morning, I got out of the tent and stretched a bit. I took a few pictures, then began setting up for breakfast. I started a fire and boiled some water. I boil water almost constantly so we always have fresh drinkable water, and I carry water tabs for backup but prefer the boiling method. Some of the boiled water gets set aside to cool, in the morning I mix Tang in the canteens for drinks during the day. Hot water is used for soups, coffee, tea, etc. I made coffee for me and tea for my wife, some hot chocolate for the kids this morning and sat by the fire, enjoying the quiet. Petra was up next and shortly after everyone made their way out of the tent and we started on breakfast.  
        </p>

        <div className="hwy-60-pics">
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/coffeetea.jpg") }} alt="coffee and tea" src="/lindalake/coffeetea.jpg"></img>
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/eggs.jpg") }} alt="eggs for breakfast" src="/lindalake/eggs.jpg"></img>
        </div>

        <p>
        Leftover sausage from the night before with scrambled eggs made for a satisfying breakfast.  Our stomach’s now full, we were ready for the day. Still pretty tired from the day before, we enjoyed sticking around camp for most of the day, exploring the forest, hunting for fire wood and taking pictures.  
        </p>

        <div className="hwy-60-pics">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/sundial.jpg") }} alt="sundial" src="/lindalake/sundial.jpg"></img> 
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/moth.jpg") }} alt="moth" src="/lindalake/moth.jpg"></img>
        </div>

        <p>
        Maya enjoyed feeding the fire and pretending to cook for us while Petra made herself busy building a sundial with rocks and sticks.  We attempted a swim but didn’t get far before we all decided it was way too cold. While the sun was out and the day was comfortably warm, the water had really cooled down by this time of year. The slight wind made the thought of going anywhere past knee deep into the water disappear quickly. We tried a little bit of fishing from shore as well but didn’t have any luck.
        </p>

        <div className="muddy-portage-pic">
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/swim.jpg") }} alt="swim" src="/lindalake/swim.jpg"></img> 
        </div>

        <p>
        Later in the afternoon we grabbed a few snacks and hopped in the canoe. We paddled around the north end of the lake to explore, making a quick stop at the island campsite to check it out as it was unoccupied. So far we hadn’t seen another person on the lake, we had all of Linda Lake to ourselves.  We explored the campsite but it was in pretty rough condition, very well used and there was very little firewood to be had. I’m glad we chose not to come here at first. There was also the remnants of previous campers in the form of leftover garbage, which is always a shame to see. We picked up what we could and brought it back to be disposed of properly.
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/trees1.jpg") }} alt="trees" src="/lindalake/trees1.jpg"></img> 

        <p>
        Once we left the island site, we paddled around the north east side of the lake. We admired the intense color change in the trees, it made the lake look like it was on fire. </p>
        
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/trees2.jpg") }} alt="trees" src="/lindalake/trees2.jpg"></img>
        
        <p> We observed the eastern most campsite from the lake but didn’t actually stop at it. We made our way back to camp paddling along the east side of the lake until we found our campsite.   As the evening came we prepared a dinner of good old mac and cheese with csabai dried sausages. Once again saving a few snacks for later, we packed up the food bags and hung them out of bear’s way.  
        </p>
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/sunset2.jpg") }} alt="evening time" src="/lindalake/sunset2.jpg"></img>

        <p>
        As everyone made their way into the tent for bed, I found myself laying on my back on one of the log benches next to the fire, in a t-shirt, no bugs to bother me, just looking up at the stars, enjoying the profound quietness that surrounds me in that moment. I can hear the flames of the fire flicker, the moon is bright and shines off a still lake and I can periodically hear a very loud owl echoing across the lake, this is amazing I think to myself, this is why I love to be here.  The noises in Algonquin are many, they can be unnerving at times but mostly I find them calming. I eventually join everyone in the tent and fall into a deep sleep.
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/morning1.jpg") }} alt="emorning time" src="/lindalake/morning1.jpg"></img>

        <p>
        Morning comes again it’s much the same as usual. I love to watch the mist on the lake, hoping to catch a glimpse of a moose feeding along the shoreline. We have not been fortunate enough to get a good moose sighting this trip, but there was still time.  After getting a fire going, day three breakfast was a camp favorite…Pancakes! So I made pancakes on the stove, smothered them in butter and syrup and we ate until we were stuffed.  
        </p>

        <div className="muddy-portage-pic">
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/pancakes.jpg") }} alt="pancakes" src="/lindalake/pancakes.jpg"></img>
        <div className="image-text">
            <p>A nice big pancake! Mmmmm...</p>
        </div>
        </div>
       

        <p>
        Today I was teaching Maya how a compass works. We decided to go on a small hike and explore, we would bring the compass with us so we could keep track of which direction we went.  We followed the shore and then went perpendicular to it, climbing up a large hill. We adventured through the woods, climbing over large trees and rocks until eventually we had gone far enough.  We started back, I didn’t want to venture too far and get lost.  During our hike, a large grouse flew up out of the bush suddenly before me nearly giving me a heart attack. I managed to show Maya before it took off out of sight. The grouse was spotted again shortly after but was gone again before I could get a picture.
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/grouse.jpg") }} alt="grouse" src="/lindalake/grouse.jpg"></img>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/forest1.jpg") }} alt="forest" src="/lindalake/forest1.jpg"></img>
        
        <p>
        We made our way back to the shoreline but had overshot the camp slightly on our way down the hill. We travelled a little way in the wrong direction before realizing my error and we reversed course. This wasn’t much of an issue except that I had been carrying some rather large pieces of prime fire wood with us, I just couldn’t leave them behind. I had to lug them all the way back in the other direction, talk about a workout.  We made it back to camp, I think Maya was very relieved because she thought we were really lost.  The firewood made a nice addition to our collection for the night.
        </p>
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/sky2.jpg") }} alt="the sky" src="/lindalake/sky2.jpg"></img>

        <hr></hr>
         
        <div className="fungus-container">

            <div className="fungus-grid">

                <div className="fungus-item"><a href="#/" ><img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/fungi1.jpg") }} alt="fungus" src="/lindalake/fungi1.jpg"></img></a></div>
                <div className="fungus-item"><a href="#/" ><img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/fungi2.jpg") }} alt="fungus" src="/lindalake/fungi2.jpg"></img></a></div>
                <div className="fungus-item"><a href="#/" ><img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/fungi3.jpg") }} alt="fungus" src="/lindalake/fungi3.jpg"></img></a></div>
                <div className="fungus-item"><a href="#/" ><img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/fungi4.jpg") }} alt="fungus" src="/lindalake/fungi4.jpg"></img></a></div>
                <div className="fungus-item"><a href="#/" ><img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/fungi5.jpg") }} alt="fungus" src="/lindalake/fungi5.jpg"></img></a></div>
                <div className="fungus-item"><a href="#/" ><img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/fungi6.jpg") }} alt="fungus" src="/lindalake/fungi6.jpg"></img></a></div>
                <div className="fungus-item"><a href="#/" ><img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/fungi7.jpg") }} alt="fungus" src="/lindalake/fungi7.jpg"></img></a></div>
           
            </div>
        </div>
        
         

        <div className="image-text fungus-text">
        <p>Maya, Petra and I found a variety of mushrooms and fungi growing about the campsite. We tried to take pictures of each new one we discovered, and we found quite a few.</p>
        </div>
        <hr></hr>
        <p>
        While cutting some logs with my saw, a solo canoer coming from one of the portages waved and yelled a friendly hello as he passed by our campsite.  He asked if we knew if anyone was on the island site, turns out he was aiming to camp there that night. I told him about our expedition there and that as far as I knew, it was vacant.  He carried on, reassured I think. I later noticed his canoe pulled up on the island site so I guess he made it.
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/paddling.jpg") }} alt="paddling" src="/lindalake/paddling.jpg"></img>

        <p>
        Today was our last day at camp, so we decided to go do some more exploring by canoe later that afternoon. We set out like last time, with a few chocolate bars on hand, this time exploring down the southeastern narrow of the lake. We travelled the eastern shore as it narrows and turns into a marshy area with a river running through it. We paddled the river as far up as we could,  until we came across a log that blocked our path, it was large enough and just under the water surface, and could risk us getting stuck on it and not being about to get unstuck. The river looked deep and we had no stable ground around us to push off, so if we got stuck, it would have been bad. We reversed course and travelled back, slowly, enjoying the scenic surroundings. We were on the lookout for moose, beavers, anything wildlife really. We did see one large blue heron along the way, that was pretty cool, but no moose. 
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/narrows1.jpg") }} alt="paddling" src="/lindalake/narrows1.jpg"></img>
        <div className="image-text">
            <p>This part of the lake narrows and turns into a river.</p>
        </div>

        <div className="muddy-portage-pic">
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/beaverdam.jpg") }} alt="beaver dam" src="/lindalake/beaverdam.jpg"></img>
        <div className="image-text">
            <p>A large beaver dam on the edge of the water.</p>
        </div>
        </div>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/narrows3.jpg") }} alt="paddling" src="/lindalake/narrows3.jpg"></img>
        <div className="image-text">
            <p>Looking back at Linda Lake from the river, beautiful colors.</p>
        </div>
        <p>
        Maya and I took the canoe out and trolled our fishing lines slowly back and forth across Linda Lake hoping to catch some fish but we didn’t get so much as a nibble. The Lake was calm as the sun went down making the paddle easy and enjoyable none the less. We headed back to camp before it got too dark. 
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/fishing1.jpg") }} alt="fishing" src="/lindalake/fishing1.jpg"></img>
        <div className="image-text">
            <p>The girls and I trying our luck for fish.</p>
        </div>
        <p>
        Once back to camp, it was getting late, we had a full day and were ready for our last supper at camp. Tonight was some sort of packaged rice mix from uncle ben that was amazing, I can’t remember the flavor, but we warmed the rice on the fire and ate beef jerky on the side for some protein. We ate as much food as we wanted as tomorrow was the day we left and anything we didn’t have to carry was a bonus.  We enjoyed a big fire and made marshmallows for snacks.  
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/marshmallow.jpg") }} alt="marshmallow" src="/lindalake/marshmallow.jpg"></img>
        <div className="image-text">
            <p>A perfectly roasted marshmallow!</p>
        </div>
        <p>
        Everyone had already gone into the tent when I decided to do a little solo night time exploration around camp. Shining a flashlight into the water at night reveals a multitude of life. This time of year, I didn’t see too many frogs, but I was lucky enough to find something I’ve never seen in Algonquin before, and I never knew existed out here so it was a bit of a surprise to find a good size yellow spotted salamander.  I found it under some bark in behind our firewood pile which laid along a row of small pine trees, it was a nice dark area.   I was amazed to see this lizard just chilling on the ground, I scooped him up on a large piece of bark to show to the kids, who were just as thrilled to see this little guy.  After we had all had a good look, I gently placed him back where I had found him and let him be, he was gone by morning.
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/fire.jpg") }} alt="fire" src="/lindalake/fire.jpg"></img>


        <p>
        We fell asleep listening to the sound of the Algonquin forest for the last time, I was a little sad that the adventure was coming to an end, but I knew it would feel good to be home, and we did have a great experience over all.  We still had a pretty long way to get home ahead of us and would need the rest, so we didn’t worry about waking up too early the next morning. Once we were up we lost no time cleaning and packing up camp. A quick breakfast of oatmeal and coffee was had, we burned any burnable waste and packed up the last of our gear. We made one last round of the site, picking up any scrap garbage found and looking for anything we may have left behind. Sure that we had everything packed, we loaded the canoe and said goodbye to our campsite. 
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/campsite4.jpg") }} alt="campsite" src="/lindalake/campsite4.jpg"></img>
        <div className="image-text">
            <p>A look down into camp from behind.</p>
        </div>
        <p>
        This morning was a bit different than our last few, the weather had for the most part been sunny and calm. Temperatures were as high as 20-21C degrees, so very comfortable for September in Ontario. But today was grey and cloudy, with a slight wind and it looked like rain was a good possibility.  We paddled back across Linda Lake coming to the familiar landing for the 1315 meter portage into Owl Lake. Just as we paddled up to the landing, there was a huge crack of thunder. Well, here we go I thought as the rain started coming down. I guess it could be worse, we were getting wet but were going home anyways and it did sort of cool us off during the hike.
        </p>

        <div className="muddy-portage-pic">
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/portage8.jpg") }} alt="gloomy day" src="/lindalake/portage8.jpg"></img>
        </div>
        <p>
        We began our trek home, this time starting with the longest portage, I suppose the bright side of this was that we would be getting it out of the way early. With our previous trip down this portage still fresh in our memory, none of us were anxious to do this again, especially in a storm. Thankfully, while the rain did keep up for a while, it wasn’t anything crazy. The forest canopy helped keep some of the rain off of us and other than a few rumbles of thunder at the beginning and that one big boom, the storm never really turned into anything more.  
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/portage4.jpg") }} alt="gloomy day" src="/lindalake/portage4.jpg"></img>
        <div className="image-text">
            <p>A gloomy day but fine for travel.</p>
        </div>
        <p>
        After a very wet and middy trek, we had finally made it, the longest portage was over at last and was quite literally a load off our backs.  This was definitely the most difficult portage and was extremely muddy, all of us at some point had the experience of having a boot or shoe sucked off of our foot in deep mud. I momentarily lost a shoe while carrying the canoe, but managed to keep my footing and pulled it out, sure was a close call.  
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/portage5.jpg") }} alt="gloomy day" src="/lindalake/portage5.jpg"></img>
        <div className="image-text">
            <p>My kids watch me cross this small patch of mud while carrying the canoe.</p>
        </div>
        <p>
        I was leading on the trail a ways, my wife and the kids a little further behind me, but not too far. I hear a loud yelp in the distance. I dropped my gear and headed back in their direction to see what was wrong. As I came upon the scene I see my wife on her butt, knee deep in mud and completely stuck. The extra pack she was carrying kept her from getting herself up in any way.  Try to save time, she had bitten off a little more than she could chew, attempting to carry an extra pack on her front while also overloading her arms she slipped and get herself stuck in deep mud. Given that she was ok and there were no injuries, it was actually a pretty funny situation and I had a good laugh as I helped her up.  I grabbed some of her stuff from her and we continued along the way. At the end of the trail we crossed paths with a solo camper going further into the park after recently camping on Owl Lake.
        </p>

        <div className="muddy-portage-pic">
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/wetshoes.jpg") }} alt="all wet" src="/lindalake/wetshoes.jpg"></img>
            <div className="image-text">
        <p>It sure was a good thing I waterproofed her shoes before we came, atleast the mud didn't stick to them I guess.</p>
        </div>
        </div>

        <p>
        We were wet and muddy, some of us more than others but we continued on our way. The rain continued for most of the trip home but it did get lighter making it almost enjoyable to paddle in.  We made our way back down the length of Owl Lake watching rain drops splash the surface of the lake, it was a little windier on our way back but no serious waves were encountered. We came to the 435 meter portage and made quick work of it, it felt very short after that last one but the rain had enhanced the messiness of the trail and we had to be extra cautious not to slip on all the rocks and roots.  On this trail we came across a trio of people heading into Linda Lake for the weekend, we advised them of our experience and continue our way.
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/portage7.jpg") }} alt="portage" src="/lindalake/portage7.jpg"></img>

        <p>
        We arrived at the shores of Raven Lake once again, making our way down to the southwest corner to the second last portage, the other somewhat long one at 920 meters.  We trudged through mud, navigated around small ponds of water for what seemed like quite a while but we made it through to the end and it was at this point that we started to feel close to our destination, most of the hard work was done.  
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/portage6.jpg") }} alt="portage" src="/lindalake/portage6.jpg"></img>
        <div className="image-text">
            <p>A wide section of the trail is deep mud and water.</p>
        </div>
        <p>
        At the portage landing on Bruce Lake, we ran into two young girls heading into Raven Lake for the night.  We chatted a bit, wished them well on their trip, then we made our short paddle across Bruce Lake to the last portage. A short, muddy, 540 meter, and then we were back on Source Lake. Source Lake was calm, the paddle was less than a kilometer back to the dock where the van was parked.  
        </p>
       
        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/loon.jpg") }} alt="a loon" src="/lindalake/loon.jpg"></img>
        <div className="image-text">
            <p>A lonely loon swimming about, probably on the hunt for fish. I wonder if he found any, we sure didn't.</p>
        </div>
        <p>
        We approached the dock with a feeling of relief, the hard work was over, and we were almost home.  A small motor boat passed us, he parked at the dock where there were already a few boats in waiting. There was no room to fit a canoe so we pulled up on the sand bottom at the shoreline and unloaded everything from there. Everything packed back into the van, I loaded the canoe on the roof and tied it down securely.  
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/together.jpg") }} alt="all together at camp" src="/lindalake/together.jpg"></img>

        <p>
        We were all soaked from head to toe, mostly covered in mud as well so before getting in the van we cleaned up as best as possible, in the end, I drove home in bare feet and boxer shorts because my shoes were a mess and I didn’t feel like digging through the packs for clothing.  The drive home was quiet, we were all pretty tired, the last four days had been physically demanding for everyone but we had quite an adventure that I think we will all remember.  With one stop for gas and food on the way, and traffic moving well, we made great time getting back home.  Once we arrived, our cat greeted us happily at the front door, glad that we finally came back to her. I unloaded all of the gear quickly and put the canoe in the back yard. I spread out the wet stuff in my garage to give it a chance to dry.  Finally, I could shower the layers of dirt off me, get pyjamas on and get into my own bed tonight. Home sweet home.
        </p>

        <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/lindalake/sunset1.jpg") }} alt="sunset" src="/lindalake/sunset1.jpg"></img>
    
        </div>    
        )
}

export default LindaLake2020;