import React, { useEffect } from 'react';

const Kiosk2023p1 = ({openImageView}) => {

    useEffect( ()=> {
        let e = document.getElementById('nav');
        if ( e !== undefined )
            e.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, []);
    
    return (
        <div className="kiosk2023_page1">
            <p>It was mid-August and summer was quickly nearing its end, or so I thought.  I had just a little over one week of vacation time left at work and I needed to use it up before the end of the year.  Having just come back from a week off, I wasn’t able to take this time right away and I didn’t want to ‘waste’ it on the cold weather season, I much prefer my vacation time in the warmer months.  As it turned out, the nearest available time for me to take would not be until at least late September.  This was ideal camping season I thought, and so an idea was born.  Maybe I could get away for a late season fishing trip, while using the opportunity to take some nice photos of the fall color change…. I had some planning to do.                  
            </p>
            
            <img alt="Kiosk Lake Loop" src="/kiosk2023/header.jpg"></img>

            <p>I began looking at Jeff’s map, brainstorming for route ideas, I wasn’t really sure where I intended to go just yet.  I wanted to go a little further North this time and I wanted to go somewhere I hadn’t been too recently.  I decided I would head back to Kiosk Lake, a large beautiful lake in the North West area of Algonquin Park.  I hadn’t been there since 2018, when I took my wife and kids into Mink Lake.  Ever since, I had been curious about White Birtch Lake, which runs parallel to Mink.  I had also wanted to check out Mouse Lake a little further down, and according to the map, there were trout all along the way.  After some consideration, I found a route I thought I could manage in six days with one day being a rest day.  I always like to have an extra day to enjoy a campsite or in case I get stuck somewhere due to weather and water conditions.   </p>    
               
            <p>It was a Saturday morning when I got a phone call from my boss at work.  It wasn’t a common occurrence on a weekend so I was a little worried something was wrong.    As it turns out, due to some unforeseen circumstances, my planned vacation time had to be knocked back by a week.  It wasn’t a major issue except that it would push me into the first week of October.  I had never been to Algonquin quite this late in the year, as I usually aim for mid-September.   This wasn’t really the issue; the worst part really was that it would put me just outside of fishing season in Algonquin.   I opted to look on the positive side of things, it was less gear to carry and I would have more time to focus on taking pictures, this would be peak fall color time after all.</p>

            <img alt="Kiosk Lake Route Map" src="/kiosk2023/routemap.jpg"></img>

            <p>My time off began on a weekend, Saturday September 30th to be exact. I planned to stay in the park until Thursday, October 5th, arriving home later that same day.  The route I chose began at the Kiosk Lake access point.  On my first day, the plan was to travel to Mouse Lake, where I would stay for my first night.  The next night would be spent on Maple Lake, followed by two nights on Three Mile Lake, and finally one last night on Manitou Lake before heading back to Kiosk and then home.  This route gave me a good mix of different lakes and even a creek or two thrown into the mix, I was very happy with the plan and looking forward to a new adventure.</p>
        
            <p>Unlike most of my trips, this particular route contained a very large portion of portaging, in fact, including the double carries, I calculated approximately forty-five and a half kilometers of portaging in total, and roughly forty to fifty kilometers of paddling on top of that.  I didn’t want to have to rush it but I knew my days would be long and my daylight would be in short supply.  I was a month and a half out from leaving and though I had been very active throughout the summer, I made a point of adjusting my training to maximize work capacity for this trip as I knew it was going to be a rough one.  Adding a lot of cardio and auxiliary exercises would help me keep a good pace on the trail and avoid injuries.</p>

            <p>Well before I left, I spent some time researching my route, checking for anything I should know about before I go and also trying to get a good idea of which campsites might be best.  I replaced my broken Gerber folding saw with a new Boreal 24” which I absolutely love, I was going to need something reliable and a bit bigger.  I also grabbed a couple of new dry bags for the trip, my experience earlier in the year on the Nipissing River encouraged me to upgrade, so I have one dedicated to the camera equipment and one for my clothing and sleeping bag.  I gradually collected my gear and planned out my food intake for the trip, I also fully cleaned and flushed my water filter so it was working like new.  I was ready to go and couldn’t wait.</p>

            <img alt="Camping Gear" src="/kiosk2023/gearpile.jpg"></img>

            <p>One of my concerns leading up to this trip was the weather. Although it was very early, October had a tendency to get windy, wet and cold very quickly.  I had been watching the forecast daily as early as I could and what I saw looked too good to be true.  The weather was forecast to be dry, sunny and not just warm, but twenty-seven degrees Celsius warm! For October… Could I really get this lucky? I was hoping for at least comfortable temperatures and mostly dry, but this? I couldn’t have planned anything better…It also appeared that the wind speed would be very low, with a bit higher wind near the end of the trip but nothing to worry about.  This was all very good news; I was excited and really looking forward to my next adventure.</p>
      
            <p>My last day of work was on a Friday.  As soon as I got home, I got to work loading all of my gear into the van and securing the canoe to the roof with straps and rope.  Once I was satisfied that the canoe wasn’t going to fly off the roof while I’m driving 120kmh down highway eleven, I went to bed to try and get some sleep before I left that night.  My travel time from home to the Kiosk access point is almost six hours, I decided one thirty in the morning was a good time to leave, giving me plenty of time to get there and get on the water by dawn. I wanted to get as early a start as possible, hoping to catch Kiosk when it’s nice and calm in the early hours and get to camp as soon as I could.</p>

            <p>I woke up shortly before one in the morning, grabbed some coffee and a quick bite to eat, quickly went over my camping list then headed out. I had no stops to make before I left, it was straight onto the highway and headed north.  I was on the road just after one, music up and coffee in hand, by day light I would be in Algonquin Park.  It was an uneventful drive, I made good time with only a single stop to top up gas and a few more to pee, pulling into Kiosk around seven that morning.  It was actually still pretty dark out and I was tired, so I decided to take a quick power nap in the van while parked in front of the permit office there at the access point.  I set my small travel alarm and leaned my seat back, closing my eyes for about forty-five minutes. </p>

            <img alt="Kiosk Lake Access Point" src="/kiosk2023/kiosk1.jpg"></img>

            <p>I was up and at it by eight, unloading everything down by the water.  I parked the van and then loaded everything up in the canoe.  I stopped to appreciate the beautiful morning I had and take a few pictures of the lake.  Kiosk was beautiful, calm and full of color.  Clouds of mist hung just above the surface all across the lake.  The day was looking like it would be sunny and clear so far, there was no wind.  I began my journey officially at approximately eight thirty as I pushed my canoe away from shore and towards the old rail road bridge on the eastern side of the lake.  I paddled slow but steadily, I didn’t need to be in a hurry, I wanted to enjoy my surroundings and take everything in. I predicted I would make my first camp close to three or four in the afternoon. </p>

            <p>It wasn’t long before I was pulling up to my first portage, a 635-meter hike from Kiosk into Little Mink Lake.  I had been on this trail previously, so it was somewhat familiar, the landing was nice, a shallow, sandy beach with lots of big rocks around it.  I brought my packs up to a dry spot then pulled the canoe out, ready to head down the path.  The portage was littered with rocks and roots in places but was otherwise pretty clear and easy to navigate, the leaves were just beginning to fall along the path.  At just 635 meters, it wasn’t a real long portage and didn’t take long to complete, soon I was on the shore of Little Mink Lake.</p>

            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="First Portage" src="/kiosk2023/kioskportage1.jpg" onClick={() => openImageView("/kiosk2023/kioskportage1.jpg")}></img>
                    <img loading="lazy" alt="First Portage" src="/kiosk2023/kioskportage2.jpg" onClick={() => openImageView("/kiosk2023/kioskportage2.jpg")}></img>
            </div>

            <p>Little Mink Lake was a very small but pretty lake sitting between two small sections of creek.        I loaded up the canoe and quickly crossed the small lake, heading for the next portage, a 1300-meter into White Birtch Lake.  Crossing Little Mink was an easy task, but the next portage was not so much.  I pulled up to the landing, it was shallow and sandy with large rocks. I was hoping for the best as I unloaded my canoe and began the long carry.  Thirteen hundred meters isn’t really a terribly long distance, but only a short way through this portage and I was already hating it.  A large portion of this hike is very uphill making for slow tortuous travel.  I was about half way through the portage when I came across a fellow traveler, Jean-Marc heading in the opposite direction.  We stopped and chatted for a few moments, I ranted a little about how terrible this portage was in this direction and then we parted ways.  He was the first person I had seen since beginning my expedition but he would not be the last.  I suffered through the remainder of the portage, finally coming to the north end of White Birtch Lake. </p>

            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Fall Colors" src="/kiosk2023/fallcolor1.jpg" onClick={() => openImageView("/kiosk2023/fallcolor1.jpg")}></img>
                    <img loading="lazy" alt="Fall Colors" src="/kiosk2023/fallcolor2.jpg" onClick={() => openImageView("/kiosk2023/fallcolor2.jpg")}></img>
            </div>

            <p>I had not been to this lake in recent history and I was happy to finally be here, I decided to take some time and have lunch before continuing on. The shores of White Birtch were quite full of color, I took lots of photos of the trees as I passed through this gorgeous lake.  Whitebirch is long and narrow, the water was extremely calm and the day had really started to warm up by now, I enjoyed every moment of my paddle as I made my way down the lake.  Once I made it to the south end of the lake, I found the small 350-meter portage that takes you into Waterclear Lake.</p>

            <p>After another rough but short, uphill battle I found myself on Waterclear Lake.  I don’t know if it’s the reason for the name, but I did notice how amazingly clear the water actually was on Waterclear Lake, and I mean, much clearer than your typical Algonquin water.   I could see the bottom quite deep, but there was a noticeable lack of color around this lake due to the prevalence of pines and cedar it seemed, it was quite a contrast to the previous lakes.  Waterclear is a long, narrow lake and like the lakes before it, was very calm, making for an easy paddle.  I made my way down to the end, where another small 350-meter portage awaited me, taking me into Club Lake.  </p>
   
            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Portage Bridge" src="/kiosk2023/portagebridge.jpg" onClick={() => openImageView("/kiosk2023/portagebridge.jpg")}></img>
                    <img loading="lazy" alt="Club Lake" src="/kiosk2023/clublake.jpg" onClick={() => openImageView("/kiosk2023/clublake.jpg")}></img>
            </div>

            <p>Club Lake had a different feel, it was larger than I expected, composed of two large roundish sections of water, connected by a sort of marshy river between them.   The portage landing coming into Club Lake from Waterclear was very shallow and very muddy, but somehow, I managed to get my gear and myself into the canoe and out into the water, without getting wet or muddy.  I paddled past the remains of an old lumber building, I didn’t bother to stop and check it out but I did grab some pictures, it’s sort of spooky looking if you ask me.  I enjoyed the lazy paddle through the river that connects both sides of Club Lake, once out onto the second part of Club, I headed all the way to the southern most part and into a creek that would take me to my last portage of the day, a 610-meter into Mouse Lake.</p>
   
            <p>This last portage was only 610 meters, but I had to find it first.  The south end of Club Lake closes in and becomes a winding creek.  For the most part, the creek was easy to traverse and the correct path was evident, but not always.  Shortly after pulling over a large beaver damn, I came to an opening with two possible paths, one of them went left, one went right.  The path on the left looked choked with weeds and unlikely to continue, the right path appeared to be the obvious choice, there was no real discernable current from either direction.  So, I went right, winding my way through the creek, all the while keeping a look out for a portage sign.  Eventually, I came to a dead end and still no portage.  I felt like I was close, but it was nowhere to be found.  My only option was to back track and try the other path I thought, so this is what I did.</p>
   
            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Old Building" src="/kiosk2023/oldbuilding.jpg" onClick={() => openImageView("/kiosk2023/oldbuilding.jpg")}></img>
                    <img loading="lazy" alt="Beaver Dam" src="/kiosk2023/beaverdam.jpg" onClick={() => openImageView("/kiosk2023/beaverdam.jpg")}></img>
            </div>

            <p>I made my way back, coming once again to the overgrown section of the creek, only to find that after pushing through, it continued on further.  As it turned out, this was the correct path and eventually led me to the portage landing I was looking for.  The landing here was extremely rocky so I took care in moving across them and pulling up my canoe.  I was finally here, the last portage of the day.  As I pulled up to this portage there was a couple just leaving going the opposite direction, they had been on a day trip over on Mouse Lake and were able to report that it was pretty empty.  I was hoping to get one of the beach sites on the eastern side of the lake and it was looking as if my chances were good.</p>
        
            <img alt="Mouse Lake" src="/kiosk2023/mouselake.jpg"></img>

            <p>In contrast to today’s earlier portages, the 610-meter into Mouse Lake was relatively flat and clear and made for a much easier carry. The portage ends on a shallow, weedy beach. The water was beautiful and a swim had been on my mind all day but I really wanted to wait until I got to camp before getting myself completely soaked.  I loaded up and headed out onto the water and towards the east side of the lake where I was aiming to camp on one of the beach sites.  It wasn’t long before I found the site I was looking for and landed the canoe right up on the sand.</p>

            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Beach Site" src="/kiosk2023/beachsite1.jpg" onClick={() => openImageView("/kiosk2023/beachsite1.jpg")}></img>
                    <img loading="lazy" alt="Beach Site" src="/kiosk2023/beachsite2.jpg" onClick={() => openImageView("/kiosk2023/beachsite2.jpg")}></img>
            </div>
           
            <p>I pulled into the small bay with a nice looking sand beach, it looked like a great spot to stay.  The site isn’t directly on the beach, to the right of the beach was a path leading into the forest a bit further up, where there was a fire pit, seating and a few potential tent pads.  The site itself was quite nice, sheltered but with a nice view out onto the lake and easy access to the beach.  It was already almost five in the afternoon before I got to camp but I was happy to stay here, so I immediately setup my tent and got a fire going.  After nearly eight hours of travel, including stopping for breaks and to take pictures, I was pretty hungry and looking forward to dinner. Before making food, I took a quick dip in the lake to wash and cool off, the water was incredibly warm for October.  It felt great to be clean and refreshed after a long day of portaging and paddling. I dried off and started preparing my dinner.</p>
   
            <div className="image-frame50">                
                <img loading="lazy" alt="Sausage" src="/kiosk2023/sausagedinner.jpg" onClick={() => openImageView("/kiosk2023/sausagedinner.jpg")}></img>
                <p style={{marginTop: '100px'}}>Tonight’s dinner would be sausages and potatoes cooked in the fire.  Once I had a decent fire going, I boiled the potatoes over it until they were soft.  I placed the potatoes back into their foil, I added salt and pepper, along with a load of butter and wrapped them back up. Placed them in the coals for a while, as I cooked the sausages on the grate over the flames.  It was definitely one of the best meals of the trip!</p>
            </div>

            <p>After dinner, I tidied up camp so I could relax a bit before bed.  It would be a somewhat early night as I was tired and I needed to get out of camp as soon as I could to continue on my journey.  Earlier in the day, I had gone out to seek an appropriate tree to hang my food bag.  There weren’t many good ones around, forcing me to go way back into the forest to hang the rope.  Unfortunately, I had waited too long to hang my food bag that night and had to do it in the pitch dark. This isn’t usually a problem, however in this case, I could not find the rope I had hung earlier.  </p>

            <p>I followed the trail I had taken with my lantern, then into the thicker brush in the direction of where the rope should be, all the while looking up for the reflection of the rope hanging in the tree.  I couldn’t see it and I was surrounded by dense brush; I was too far from the water and losing my sense of direction. In the moment I had a panicked thought that if I kept looking further, going in circles I might be spending the night out here. I was too far away from camp to even see my fire at this point so I made the decision to backtrack until I found the trail again, eventually seeing the light of my fire in the distance, my heart began to slow down a little and I decided that I would make other arrangements with my food bag that night.  In the end, I stashed it against a tree, closer to camp but still well away from my tent and had no visitors that night.</p>

            <img alt="Sunset" src="/kiosk2023/mousesunset.jpg"></img>

            <p>I enjoyed a calm night sitting around the fire, getting to bed around ten so I could have a good sleep before another long travel day.  I fell asleep rather quickly and don’t remember a thing until the morning.             </p>
        
        </div>  
        )
}

export default Kiosk2023p1;