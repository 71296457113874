import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import ImageView from './imageview';

const OpalescentLake2022 = () => {

    const [imageViewState, setImageViewState] = useState(null);

    useEffect( ()=> {
        
        let e = document.getElementById('nav');
        if ( e !== undefined )
            e.scrollIntoView({ behavior: 'smooth', block: 'start' });
    
    }, []);

    return (
        <div className="triplog">
              <Helmet>
                <title>Opalescent Lake 2022</title>
                <meta name="Opalescent Lake Camp Log" content="Opalescent Lake Algonquin Park. Algonquin Park Access Point at Sand Lake Gate, accessible on the East side of the park. Opalescent Lake was a great mid point for doing both the Baron Canyon and High Falls as day trips."></meta>
            </Helmet>

            {imageViewState ? <ImageView closeImageView={() => setImageViewState(null)} imgSrc={imageViewState} /> : null }
           
            <h3>Opalescent Lake, The Baron Canyon and High Falls</h3>
            <img alt="baron canyon banner" src="/opalescentlake/banner.jpg"></img>

            <p>This is a story about the family vacation that was not to be, becoming the family vacation that likely wasn’t to be but suddenly was.  This year has been a rough year for many, we were slowly but finally getting back to some sense of normal. Covid had seemingly taken a back seat to summer fun and travel was again becoming a thing to do.  My wife is European and has not had the opportunity to see her parents or siblings in some time, so we had been greatly looking forward to finally all getting on a plane and heading to Europe for a couple of weeks to enjoy the summer with her side of the family. </p>
            <p>Our focus over the last few weeks had been planning our itinerary to make sure we could make the most of our visit, making time for every person we could, including going to a couple of birthday parties while we were there. We were going to have a great time… or so we thought.  It was Friday, July 29th, the very day we were supposed to fly out of Toronto that we got the call. The flight was overbooked and seats for us were no longer available.  We could find alternatives but could no longer guarantee that we could get back within our allotted vacation times. That was it, our plans had been ruined in an instant. My wife was heartbroken, my kids and I of course very disappointed and worse, everyone at home planning for us to come had been let down.  We were devastated but there wasn’t a whole lot we could do. The reality of it was that we would have to push our plans back a bit, it didn’t mean we wouldn’t go, just that we had to wait until the craziness at the airport has settled down a bit. So that is what we would do, fortunately I was able to cancel the last half of my vacation and save it for a later date. </p>
            <p>So, what to do now?  I had been hoping to get back to Algonquin all year, and I had some intentions of going back in the fall to see the Baron Canyon, but nothing was official and most of our time had been spent planning the Europe trip, so Algonquin wasn’t quite on the top of our minds.  That was, until now. I didn’t want to waste the vacation I had taken. I was off for a whole nine days, maybe we could fit Algonquin in there somewhere?  Late that night, as everyone slept, I was considering our options and the thought occurred to me, what if Algonquin is crazy busy like everything else, will I even be able to get a camp site?  Sometime, well after midnight,  I logged into the Parks Canada site to look at the Algonquin back country reservations for Opalescent Lake, the lake I was hoping to stay on.  I was shocked to see the entire month of August was completely booked, with the exception of four days, Aug 1st-4th.  It couldn’t have been more perfect timing. I booked them without a second thought, I didn’t want to risk losing them and I would discuss it with everyone the next day.</p>
            
            <div className="image-center-60 image-text">
                <img className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/map.jpg") }} alt="camping map" src="/opalescentlake/map.jpg"></img>
                <p>This map shows the general area around Opalescent Lake.</p>
            </div>

            <p>The next day, as my wife and I were discussing alternatives to our Europe adventure when out of nowhere she asks me if I want to go camping instead.  What sort of question is that? I thought to myself... of course I wanted to go camping!  I chose this moment to bring up that I had in fact already made reservations and we could leave this weekend.  I presented my idea, 4 nights, 5 days in Algonquin on Opalescent Lake. We would leave Sunday night at around midnight and be on the water early Monday morning, coming home on the Friday. I had hoped to make a couple of day trips during our stay, one to the Baron Canyon and one to High Falls waterfall and the well-known water slide. I figured Opalescent Lake was a good midpoint that offered me both options without too much trouble.  We agreed to this plan and so I went into planning and packing mode real quick. I had just more than a day to have a complete plan and be packed and ready to go. </p>           

            <p>Most of my camping gear is neatly stored in one spot so it wasn’t too much trouble to sort through. I designed a quick and tasty meal plan and made a grocery run for everything we needed. I pulled some potatoes from the garden and made some peanut butter & jam sandwiches for the first day.  Everything was coming together well; I keep a master list of my camping needs so it’s easy to remember all the important items to pack.    By Sunday, early afternoon, everything had been sorted and packed, ready to be loaded into my van.   We were going to eat pretty well, I packed eggs, pancakes and bacon for breakfast, sausage, bacon and pork chops with rice or pasta for dinners and all sorts of snacks, healthy and otherwise.  I brought enough tea, coffee and hot chocolate to keep us all happy, and of course a big bag of marshmallows to roast.</p>

            <img loading="lazy" alt="camping tent" width="80%" src="/opalescentlake/camping1.jpg"></img>

            <p> I freeze all of the cooler items such as butter or meat to give them a longer life time in the bush. Eggs are cracked into a zip-lock bag and then frozen. I put the frozen items into the cooler just before leaving the house so as to keep them frozen as long as possible.  Everything else was carefully packed into a couple of main backpacks and one smaller day pack.  I dedicate one large backpack to food and kitchen related items; this pack gets hung at camp. I then dedicate another pack to clothing, towels, med kit, etc. I also have a smaller back I use for fishing tackle, rope, and miscellaneous items. Sleeping bags are covered with plastic bags in case of rain and all of our clothing had been double bagged as well. This gives us an extra layer of protection in case the backpacks get soaked.  </p>
            <p>Everything was loaded into the van along with a number of tools I keep with me on long trips, just in case I break down somewhere. I tied the canoe securely to the roof rack and double checked I had all paddles, life jackets and related gear.  We were set, all that was left was the frozen items and they would come out later.  I tried to relax for the rest of the afternoon before going to bed early, around 8pm. I think I got a few hours of sleep in before I had to get up to drive. I was awake and out of bed by midnight. I quickly grabbed the frozen stuff from the freezer and put it all into the cooler, covered it in ice and loaded it into the van.  Grabbed some snacks and a coffee for the road, and got Heni and the kids into the van. I went through my mental checklist before leaving.  We were good to go, or were we? I can’t ever shake that feeling of “what did I forget?” … </p>
            <p>We were off, making good time; I think we hit the road around 12:30am.  Our destination would be the Sand Lake Gate on the East side of Algonquin Park, a roughly 7-hour drive from my home.  I expected with a few small stops for gas and pee breaks we would arrive at the Brigham Lake parking lot around 8:30am.</p>

            <img loading="lazy" alt="loon" width="80%" src="/opalescentlake/loon.jpg"></img>

            <p>I was driving a very different route than I usually take to Algonquin, because I generally stick to the western side of the park.  I was looking forward to new scenery along the drive, but I made sure to input the route on my GPS so I wouldn’t get lost.  The drive was smooth through the night, which is the primary reason I drive all night. I avoid the heavy traffic of the QEW and the 400 series highways and by morning my drive is a beautiful cruise through lakes and trees.  I travelled mostly East along the top of Lake Ontario before finally turning north somewhere near Port Hope. I travelled some smaller highways, where at some point my GPS lead me down some back country dirt/stone roads through the middle of nowhere.  I didn’t think much of this at first, but after a while I started to worry.  We were headed in the right general direction so I just kept on course. The roads eventually became paved and more familiar… it was quite an interesting detour. </p>
            <p>When we finally came to Baron Canyon Road, I knew we were getting close to our destination.  We travelled this road until coming to the Sand Lake Gate office. I parked the van and went inside. The girl in the office checked my permit and gave me some advice on accessing the high falls area.  With permits in hand, we continued the drive down Baron Canyon Road, in search of the Brigham Lake parking lot from which we would begin our adventure.</p>
            
            <div className="image-text">
                <img loading="lazy" alt="loon" width="80%" src="/opalescentlake/sandlakegate.jpg"></img>
                <p>The Sand Lake Gate offices.</p>
            </div>

            <p>The road was bumpy, really bumpy in places… something akin to the rumble strips along the side of the highway, except all over the road.  I had a good laugh at the road sign suggesting 70km/h was a reasonable maximum speed. I was concerned my van was going to shake itself apart from the extreme vibration caused by driving this stretch at half that speed...   We did manage to survive the drive in one piece, and eventually turned left into a small parking lot marked for Brigham Lake.  There were only a couple of cars in the parking lot suggesting it wouldn’t be very busy. This wasn’t too surprising early on a Monday morning.  I find starting a trip early in the week gives you a better chance at getting a choice campsite.</p>
            
            <div className="image-frame50">
                <div>
                    <p>We all got out of the van to stretch after a long drive, it was nice to be out.  We made our destination just before 9am which was just a bit later than I had expected. I wanted to try and get to camp as soon as possible, there was a particular camp site on Opalescent Lake that I was hoping to snag and getting there early would improve our chances. </p>
                    <p>I started by unloading the canoe, we then took all of the gear out of the van and started hauling it down to the river where we start our journey.   There is a small portage from the Brigham Lake parking lot to the beginning of the Baron River. The portage goes down a relatively steep hill and bends to the left, going right through a campsite and ending at some shallow rapids.  A chipmunk zoomed passed me as I made the descent down to the water, diving into his small hole on the path. He didn’t seem pleased with my presence and I’m pretty sure he was telling me off as he went by.  I say, if he didn't want company, he shouldn't have made his home right on the portage...</p>
                </div>
                <img loading="lazy" alt="brigham sign" src="/opalescentlake/brighamparking.jpg"></img>
            </div>
            
            <p>I interrupted a woman camping with her kids as I hauled my canoe through the site she was occupying.  She was very nice about it and we chatted about our trip plans as we hauled the remaining gear from the van down to the water access.  She had a dog that was especially interested in our presence, though very friendly.  The access to the water here was a bit tricky. We needed to put the canoe in water deep enough that it didn’t bottom out with all of our gear and ourselves in it, this meant loading at the very end of the path where the access to the water is a bit deeper but the shore ledge is a high enough to make getting in and out a bit of a challenge.  One by one we all got into the canoe, adjusting our seating as we tried to find the sweet spot for balance.  We then departed slowly down the Baron River, beginning our adventure.</p>
            <p>We were finally here, on our next adventure. The weather was beautiful, it was a warm sunny day with a slight breeze to start us off.  We paddled slowly down the river, avoiding the rocks and logs that lay hidden just below the water’s surface, trying our best not to get stuck on one.  About twenty minutes later we came to the entrance to the very small Brigham Lake. We didn’t need to go far though, our one and only portage, to Opalescent Lake was right at the end of the river.  We pulled up to a shallow access point with a big slopped rock landing and started taking gear out, preparing for a relatively quick hike and then to find a campsite.  The bugs were out but not terrible, as long as you flailed your arms around every once in a while, you were fine.   </p>

            <div className="image-text">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/brighamlake.jpg") }} alt="brigham lake" width="80%" src="/opalescentlake/brighamlake.jpg"></img>
                <p>Looking out from the portage landing from Birgham Lake to Opalescent.</p>
            </div>

            <div className="image-frame50">
                <div className="top-margin-2"> 
                    <p>Due to some extras such as the cooler, we would be doing double carries on the portage, I helped my wife put her pack on and loaded the kids with what they could reasonably carry.   I took the canoe and started making my way down the trail with everyone following behind.  One of the nice things about this camping trip was the lack of long portages, we only really had one portage to get to camp but it was a tough one despite its deceivingly small length.  Constant elevation changes and jagged rocks and roots all over the place made the 730 meters feel a whole lot longer than I was expecting.  I went back and helped Heni with the remaining gear. Once we made it to the end with everything, I assured everyone that the hard work was done, after that hike we could now relax. All that was left was to go find a campsite.</p>
                    <p>Opalescent Lake is a beautiful little lake and there was one site I was hoping we might get. The lake appeared quiet; I was hopeful.  I put the canoe in the water, the landing was decent but the water is deceivingly shallow due to rocks that lay hidden under the muddy water.  We managed to get all the gear into the canoe and kids next. So far it was looking like we might escape.  I got in the canoe with some careful maneuvering and now it was Heni’s turn.  </p>
                </div>
            <img loading="lazy" alt="brigham lake portage" width="50%" src="/opalescentlake/portage730.jpg"></img>

            </div>

            <p>While standing on a small rock, she placed one foot into the canoe, but she hesitated. Balancing with one foot still on a rock.  I pleaded with her to just make the move… just do it, just get in… I watched as the gap slowly widened, sitting in the rear of the canoe, unable to do anything as Heni took a dive into the shallow, mucky water along shore.  She fell completely out of the canoe, managing to soak herself mostly, especially her face, in the smelly water…she was not a happy camper already and we had only just begun. Thankfully she was not seriously injured, save for a small scrape and a bruised knee, she was mostly ok.  </p>
            <p>Once she was able to compose herself, we pushed our way into deeper water and made our way out into the lake.  I had a rough idea where the campsite I wanted was, and looking from the portage landing, it appeared empty, so we made our way quickly towards it, hoping to beat anyone else that may be eyeing the same site. </p>
            
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/opal1.jpg") }} alt="opalescent lake" width="80%" src="/opalescentlake/opal1.jpg"></img>
                <p>Looking out over a calm Opalescent Lake from the 730m portage landing.</p>
            </div>

            <p>Along the western side of the lake, about half way down the length of it, there is a campsite with a big rock landing, known for its big rock couch and massive fire pit.  It was looking like we may have lucked out and the site was unoccupied but as we approached, my hopes were dashed when I noticed someone standing up on the rocks.  I knew getting the site was going to be a long shot, I guess we would have to keep looking. </p>
            <p>As we got closer, I noticed that the people on the site were taking a group photo, and there was no sign of a tent or that they were staying. ‘Maybe they were leaving?’ I thought… So, I decided to ask, shouting to them from the boat, and as it turns out, that’s exactly what they were doing.  We chatted a bit before they continued on their way as we lay claim to our new home for the next week.  I was so happy to have grabbed this site, it had a little bit of everything.  The stone couch was very unique and surprisingly comfortable.  The overall structure of the site was nice, easy to walk around and covered in pine needles so very soft on the feet. The front of the campsite was surrounded by a large rock ledge that sloped gradually into the water making for an easy canoe landing and a great spot to both swim and fish.  Due to the rockiness of the site, tent pads weren’t the best but we made due.  The site resides on a point so we managed to catch a nice breeze keeping bugs at a minimum.  The firepit was large and came with a variety of grates for cooking.  Next to the pit was a large table made from rocks, like an outdoor kitchen island.  We spent a few minutes walking around and checking out the new site before hauling everything out of the canoe to begin setting up camp. </p>
           
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/stonecouch.jpg") }} alt="stone couch and fire pit" width="80%" src="/opalescentlake/stonecouch.jpg"></img>
                <p>The flintstone style couch and massive fire pit that came with our campsite.</p>
            </div>

            <p>We had arrived well before noon and had a full day ahead of us so we didn’t need to rush anything.  We set up the tent and unpacked a few things before scouting the site a bit more in depth.  My kids started filling the firepit with pine needles and small sticks in an effort to get a fire ready for later. To my daughter’s surprise, the fire started smoking and ignited on its own after only a few minutes. It was then that I realized that the previous campers had not properly extinguished their camp fire, and it was still quite hot. The situation was upsetting, but there wasn’t much I could do or say, the campers were long gone.  The people seemed very nice and I don’t believe they had any ill intent, I think a few of them were first time campers as well.  That being said, there is never any excuse for not properly extinguishing your fire and something all campers should learn to do.  Many forest fires have occurred due to this very reason, so I hope to point this out as a learning experience to anyone reading this. All it took was a few minutes and a light breeze to ignite the few small twigs my kids put into the firepit, it doesn’t take much for a small coal left over to spread and become a much bigger problem. Always repeatedly drown and stir your fire until it is completely out when leaving it for extended periods or before bed.  Forest fires cause a lot of destruction and loss of life, it’s well worth the extra effort of make sure the fire is out before moving on. Ok…End rant.</p>
            
            <div className="image-text">
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/opal2.jpg") }} alt="opalescent lake" width="80%" src="/opalescentlake/opal2.jpg"></img>
                <p>A picturesque view of the shoreline on Opalescent Lake.</p>
            </div>
    
            <div className="image-bear image-text">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/beartree.jpg") }} alt="beartree" src="/opalescentlake/beartree.jpg"></img>
                    
                 <p>Look closely, can you see the bear in the woods?</p>
                    
            </div>

            <div className="bear-text">
                <p>So, we had a fire going a little sooner than expected, we had some wood logs left over from the last campers, and the surrounding campsite was littered with pine needles and small sticks. We would certainly need to go in search of more big wood to have a good fire.  My oldest daughter, Petra and I took a short canoe ride along the south end of the lake to explore and try to gather fire wood, grabbing my trusty folding saw just in case.  We found that much of the shoreline on Opalescent Lake was mucky and full of weeds and lily pads or very rocky and completely infested with frogs and water snakes but firewood was sparse and hard to come by.  We brought back some sorry looking pieces of which were mostly pine and created a small pile.  We would have a fire tonight, but would need to go back out for more eventually. </p>
                <p>As we approached shore, there was a loud fightened yelp from camp.  Heni was sitting at the firepit, and as she turned around she spotted when appeared to be a large bear towering above the brush just behind the campsite.  We were relieve to find out it was just an unexpected tree root and rock formation.</p>
            </div>
        <br/>
            <hr/>
            
            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/frog1.jpg") }} alt="frog" width="50%" src="/opalescentlake/frog1.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/snake1.jpg") }} alt="snake" width="50%" src="/opalescentlake/snake1.jpg"></img>
            </div>
           
            <p>The day was still young and it was really hot out so it wasn’t long before my youngest daughter Maya demanded to swim.   Great idea! We all had a good swim off the front of the camp site. There was a nice sloped rock entry into the water and it got deep enough that you weren’t dangling your feet in weeds or rocks below.  The swim was refreshing and washed away the sweat and dirt accumulated from the day’s travel, it felt great. We ate some peanut butter and jelly sandwiches and then decided try out the fishing from shore. I set up the kids fishing poles with a simple spinner lure on each. No sooner has I started working on my own fishing pole when I hear Maya screaming that she got a fish. Shortly after that, Petra also hooked a fish. They both continued to pull in a mixture of tiny perch, and bass, all of them only a few inches long, but they had a blast catching them and that’s all that matters.  I caught a few and then gave up to do some more shore exploring. </p>
          
            <img loading="lazy" onClick={ () => { setImageViewState("/opalescentlake/fishing.jpg") }} alt="fishing" width="80%" src="/opalescentlake/fishing.jpg"></img>
            
            <p>We spotted some really big frogs and toads, as well as a painted turtle living along the shore. Our site was also home to a family of four water snakes that lived in the rocks along shore.  We occasionally picked one up to check it out, otherwise we watched them daily as they poked their heads out to sun, or swam out into the water for food. They never once appeared aggressive and didn’t seem too bothered by us so we happily shared the site with them, Maya even went swimming with one of them, and somehow that didn’t bother her…. Maya spent as much time as she could in the water, she’s one with the fish, err snakes.. I suppose. I got up and tended to the fire and just relaxed a while to enjoy my surroundings.  The tent and sleeping areas were all set up, I needed to find a suitable bag hanging tree, so I went back into the forest to take a look. I was gazing up at the trees, trying to find a good strong branch for a while, sort of disappointed in the options I found at first, when suddenly, there it was. A huge pine tree with a massive branch perfect for hanging a bag.  This clearly had been used before, there were a number of leftover rope pieces that were still hanging on.  This was it, perfect!  I hung the rope, ready to hang the food bag at the end of the night. </p>

            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/snake2.jpg")}} alt="frog" width="50%" src="/opalescentlake/snake2.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/snake3.jpg")}} alt="snake" width="50%" src="/opalescentlake/snake3.jpg"></img>
            </div>

            <div className="image-text">
                <p>We counted four snakes living in the space betweek the rocks, often sticking their heads out to sun themselves.</p>
            </div>

            <p>Dinner on the first night was baked potatoes and hot dogs cooked over the fire. I boiled the potatoes a little to make them soft. Then I put them in tin foil with some salt, pepper and butter, and lay them in the coals to bake a while, until perfect. Hot dogs were cooked on a stick to everyone’s own liking.  After dinner we relaxed around the fire, but everyone was pretty tired and headed to bed around 9:30pm. As usual, I stayed out for another couple hours to enjoy the quiet stillness of the Algonquin night. I ate a final snack and hung the bags before heading to bed close to midnight. Walking back into the dark forest late at night, with only a flashlight to hang the bags is always a fun experience, I wondered how many eyes were watching me as I work.</p>
            <p>Morning came, and I think for the first time ever, I didn’t leave the tent until almost 9am.  I think we all needed some sleep because no one got much the night before, especially me since I did the driving.  I went out and made a small fire to get the morning started. I took the food bag and cooler down and boiled some water for coffee and hot chocolate.  One by one, everyone came out from the tent. Breakfast this morning would be scrambled eggs and bacon cooked over the fire. Every meal always tastes amazing cooked outside on a fire, this was no exception.  We ate and relaxed a bit while we considered out plans for the day.   </p>
            
            <div className="image-center-60 image-text">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/chipmunk.jpg")}} alt="chipmunk" width="60%" src="/opalescentlake/chipmunk.jpg"></img>
                <p>This little bugger was trying for every opportunity to steal some food around camp.</p>
            </div>

            <p>We took the morning slow, doing a little exploring and fishing around camp. Petra helped me set up the tarp to create a small shelter over the firewood.  It also covered a large log where we could sit by the fire out of the rain if needed.  We weren’t expecting rain until maybe later in the week but you never know, so we got it done and out of the way.  The day was looking sort of cloudy and a little on the cooler side, so we decided it would be a good day to canoe the Baron Canyon.  We wanted to hit the waterslide and high falls on a hotter / sunnier day if possible.  We packed a day bag with the essentials such as food and medical. We made sure our fire was out and food bag was hung then departed camp just after noon.</p>
            
            <div className="image-frame50">
                <div>
                    <p>Reaching the Baron Canyon would mean back tracking over that pain in the ass of a portage, the 730-meter back to Brigham Lake, but at least this time we didn’t have all of our gear with us so the carry was easier.  Once back on Brigham, we made the short paddle to the other side of it to find the portage. This portage is an easy 100 meters that routes around shallow rapids.  After the portage, you cross a small pool of water before coming to the next one.  This portage was hidden in a small inlet, the bottom here was very shallow and took some serious care to navigate it without getting stuck.  It was a bit tricky in spots as we had a fair bit of weight in one canoe, we didn’t have a lot of depth to spare.  </p>
                    <p>This last portage into the Baron Canyon routes you around the Brigham Chute which is a very cool set of rapids / small falls that you can easily get into and cool off in after a long day. We took note on the way in, there appeared to be a small trail off the edge of the portage leading down to the water.   The portage was full of poison ivy, so we had to watch our step. I told the kids to keep to the trail and stay out of the bush. There were also a lot of steep drop offs along the edge of the path as the portage followed a rocky ledge, it would be a nasty fall, especially with a canoe overhead, so extra precautions were to be taken.  </p>
                </div>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/chute1.jpg")}} alt="chute" width="50%" src="/opalescentlake/chute1.jpg"></img>
                       
            </div>
            
            <p>The 440 meters were quickly passed and we came to a shallow stony stream heading into the Baron Canyon. When we arrived, there were two kayaks already present with a man and woman resting nearby.  We said hello and spoke a while about our trip and theirs. Before bidding farewell, the older gentleman offered to take a family photo of us. Out in the bush we didn’t get that opportunity much since it’s usually one of us taking the picture. I don’t remember getting your name, but if you see this, Thank You so much! </p>
            
            <div className="image-text">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/familypic.jpg")}} alt="family" width="80%" src="/opalescentlake/familypic.jpg"></img>
                <p>All of us about to head down the barron canyon.</p>
            </div>

            <hr/>
            
            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/canyon1.jpg")}} alt="canyon rock" width="50%" src="/opalescentlake/canyon1.jpg"></img>
                
                <div className="top-margin-5">
                    <p>We continued on our way, the rest of the journey was paddling only, that is until we need to go back.  The day had become very sunny and hot after all, I encouraged a lazy pace, there was no need to rush. There was no serious wind or current to contend with, just enjoy the scenery as you paddle.  We slowly made our way through the canyon, admiring the steep shorelines and sheer cliff faces. Parts of this place were still familiar to me; I had been through the Baron Canyon as a child and I remember it vividly.  In particular, there is a large rock that is ideal for jumping from, I made sure to point it out as we passed by it, I suggested maybe we stop back here on our way back.  </p>
                    <p>We paddled through most of the canyon, stopping short of the Cache rapids but still covering the most scenic areas before we turned back.  Everyone was getting tired and it was blazing hot so Maya was begging to swim at this point, we also needed to stop for a food break.  I suggested we stop at the big rock we saw earlier; we could eat and swim before heading back to camp.  We were still quite a way’s away so this idea was met with groans but we trudged on until finally, we saw the rock in the distance. </p>
                </div>
            </div>

            <p>As we approached to find a suitable landing, from behind another couple had also decided on making this rock a hangout spot. I yelled over to them, asking if they minded the company, and they were very cool about it.  We parked our canoe up on some rocks opposite of theirs, grabbed the day pack and climbed up to the top of the big rock. We had some snacks while I surveyed the area to see depth and look for any dead-heads or rocks sticking out.  Maya didn’t waste any time jumping in at the bottom and just swimming about the canyon in her life jacket, but I wanted to jump from the top of the big rock.  I swam the area below and didn’t feel any obstacles. The other people had already made a few jumps by now and lived so at this point it looked safe.  </p>
           
            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/canyon2.jpg")}} alt="baron canyon" width="50%" src="/opalescentlake/canyon2.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/canyon3.jpg")}} alt="baron canyon" width="50%" src="/opalescentlake/canyon3.jpg"></img>
            </div>

            <div className="image-text">
                <p>A view of the canyon walls as we canoed along the barron river.</p>
            </div>

            <p>The water was beautiful, I made the jump and it was amazing fun. The other guys and I kept climbing back up and jumping off into the canyon, taking turns and some pictures. I tried, but there was no way I was talking Heni and the kids into making the jump.  Swimming in the canyon was a fun way to spend an afternoon and cool off. Once refreshed we said goodbye to our swimming companions and continued the journey back to camp.  </p>
            <p>On our way back over the 440-meter portage, I stopped part way and made my way down to the water. The Brigham Chute offers some nice small pools of rushing water that are sort of like little natural Jacuzzis, you just lodge your body into the rock crevice, get comfortable and relax. It’s fun and refreshing… until you see a water snake pass by, I suppose. But that didn’t happen this time.  The kids and I had lots of fun dunking ourselves into the pools and enjoying the rush of the waterfalls.  I took a moment to filter some river water for our canteens, then we once again continued on our way.  We ripped through the 100-meter portage once more and finally came to, for a third time on our trip, that 730-meter portage into Opalescent.  We all hated this particular hike by now, it was a necessary evil, but at least camp wasn’t far off.   We made it to Opalescent Lake, Heni, taking extra precautions this time getting into the canoe.  We were getting good at navigating this particular landing, soon we were off. </p>

            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/chute2.jpg")}} alt="brigham chute" width="50%" src="/opalescentlake/chute2.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/chute3.jpg")}} alt="brigham chute" width="50%" src="/opalescentlake/chute3.jpg"></img>
            </div>

            <div className="image-text">
                <p>Cooling off in the rushing water of the brigham chute.</p>
            </div>

            <p>Within minutes we were at our campsite, home sweet home. It was now after 6pm and time for dinner, we were all very hungry. We went for another swim to cool down and wash off before getting to dinner.  We built a fire and took the food bags down, dinner tonight would be rice and sausage.   After dinner we played cards around the fire for a while before it started to rain lightly.  The rain continued on and off for a while but as night time came it got heavier and continued well into the early morning hours.  Heni and the kids went back to the tent early to escape the rain, I stayed and tended the fire a while longer. I waited for a break in the rain to go hang the food bags then went to bed to the sound of rain drops hitting the tent.  The tent stayed mostly dry; though we did have a small puddle earlier when someone left the tent door flap open.</p>

            <p>I woke up to a damp, windy morning. Everyone was still sleeping when I left the tent but Petra followed me out soon afterwards. My first mission was to get a good fire going, Petra helped me get one together.  Everything was wet, but we had stored our pile of wood under the tarp, so we had lots of dry logs.  We scavenged some dry pine needles and small twigs we found and managed to get a fire going in no time.   The sky was getting cloudy and looked like it might rain again but it was still early so the day had lots of time to change, I had hope. </p>
            <p>After pulling down the food bags, we made breakfast, more eggs but with leftover sausage this time.  As the day wore on, it stayed mostly cloudy but the sun would peek out now and then to provide some heat and help dry out everything.  We decided to make this our rest day and go to High Falls on our last day. We fished, we explored, we enjoyed the fire and we ate.  The day had turned out to be a nice one after all so we did a fair bit of swimming as well. </p>

            
            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/firewood1.jpg")}} alt="firewood" width="50%" src="/opalescentlake/firewood1.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/firewood2.jpg")}} alt="firewood" width="50%" src="/opalescentlake/firewood2.jpg"></img>
            </div>

            <div className="image-text">
                <p>Gathering firewood, think I have enough yet?</p>
            </div>

            <p>I went out on the canoe to hunt down some more firewood, this time I went solo to fit more in the canoe.  As I approached the shore where I had spotted a promising bit of wood, I noticed there was a large bullfrog sitting among the lily pads.  He was in my path and I didn’t want to run over him with my canoe.  As I inched closer, I fully expected the frog to make a jump for it, but it didn’t.  So, in attempt to coax it into moving, I took my paddle and started digging in beneath him, still he didn’t move. Gently I scooped this giant frog up with my paddle, right out of the water, moved him over five feet and set him down without a single movement from the frog. I had a good laugh about this as I just watched him sitting there, I was wishing someone else could have seen this.  </p>
            <p>I made it back to camp with a pretty sizeable amount of firewood, enough to last the rest of our stay. After unloading it on shore, Maya and I then went for a paddle around the lake to explore. The flies were out and bothering us so we didn’t stay out long.  Lunch and dinner consisted of good old Kraft Dinner, pork chops and bacon grilled over the fire.  We spent some time playing crazy-eights around the fire before dark and explored the shore line for frogs and other various life forms. We managed to find and catch a number of large toads and frogs during our search, each was gently inspected and released, never gets old.  We enjoyed a quiet night by the fire before heading to bed, tomorrow would be our last full day and it was going to be a busy one.</p>
            
            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/loon1.jpg")}} alt="loon" width="50%" src="/opalescentlake/loon1.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/loon2.jpg")}} alt="loon" width="50%" src="/opalescentlake/loon2.jpg"></img>
            </div>
            
            <div className="image-frame50">
                <p>I woke up around 7am, wanting to get an early start on the day and enjoy as much as possible.  I spent some of my morning trying to catch pictures of loons, getting a few good ones of a pair that clearly called Opalescent Lake their home. We had seen these same two loons fishing up and down the lake since we moved in, at night their call was eerie and unmistakable as it echoed across the water.  This morning, I was treated to something interesting.  As I watched the lake, a group of three loons were all swimming in a circle, sort of going around each other as if they were sizing each other up.  The standoff eventually ended as a pair of them dove down into the water and the other left.  I had seen a lot of loons but I had never seen them act like this, I was curious. After looking into this activity when I got home, I learned its related to dominance or mating displays between loons. It was very interesting to observe in the wild.</p>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/threeloons.jpg")}} alt="three loons" width="80%" src="/opalescentlake/threeloons.jpg"></img>
            </div>
            
            <div className="image-frame50">
            <img loading="lazy" alt="pancakes" width="50%" src="/opalescentlake/pancakes.jpg"></img>
                <div className="top-margin-5">
                    <p>I spent a little time casting a Mepps lure off the front of the campsite without a single bite. Seems the fish weren’t hungry this morning, oh well, time to start a fire.   It wasn’t long before Petra was out of the tent helping me with whatever was needed.  We got a fire going and pulled down the now quite light food bag and cooler.  Today was pancake breakfast day and I know my kids were looking forward to it. I’m not going to lie; I might have been looking forward to it even more. I brought butter and syrup to do it right. I made the pancakes right on the fire, they turned out amazing, so buttery and slightly crispy. We feasted as we would be using the calories today on our journey to high falls and the water slide.</p>
                    <p>The day was early but was already starting to look like a nice day, sunny with clouds in the sky and warming up quick.  We spent the morning preparing to leave.  I filtered water and filled the canteens with tang mix, packed a mix of protein and chocolate bars as well as nuts and granola for food.  The goal was to head to High Falls Lake via Ooze Lake, then find our way to the waterfall from there.  Hopefully we would get a chance to take some nice pictures and swim in a cool spot, maybe run the slide a few times.  </p>
                </div>
            </div>
            
             <p>We needed to cross two portages to get there and both were quite small, the first was a 640 meter directly across from the 730-meter portage coming into Opalescent.  This trail was quite rugged, as is the general topography of the area but didn’t feel so bad because we didn’t have much with us, so it was a relatively quick single carry.  We came to the entrance of Ooze Lake to see that it is quite appropriately named.  There was a very large brown hawk like bird flying around the area, I wish I had a picture or a better view because I could never figure out exactly what it was.  </p>

            <p>We all piled into the canoe and shoved off into the ooze, I think we all let out an audible “eww” at the sight of the islands of rotting matter that dot the lake. We did our best to avoid these mounds so as to not get stuck on one.  We were looking for the entrance to the 300-meter portage into High Falls Lake. I was looking at my old Algonquin Map, because it was handy, it should be easy to find, so we went to look for it. </p>
        
            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/ooze1.jpg")}} alt="ooze lake" width="50%" src="/opalescentlake/ooze1.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/ooze2.jpg")}} alt="ooze lake" width="50%" src="/opalescentlake/ooze2.jpg"></img>
            </div>

            <div className="image-text">
                <p>Ooze Lake, living up to its name.</p>
            </div>

            <p>I should have known better because this map has thrown me off more than once, but we all looked for quite some time and couldn’t spot anything that resembled a portage sign.  There was a visible path up the shore and what looked like a poor landing at the very spot the map had marked.  What we didn’t see, was a portage sign.  Now it’s not unheard of for a portage sign to go missing, or be hard to spot, so I gave it a good look. I went so far as to go onto shore and scout the area to see if there was a viable path or if I could find the sign.  </p>
                 
            <p>We pulled up to the shore as close as we could, nearly getting stuck in the process. As I hopped out of the canoe and into the water, I sank deep into the dark smelly sludge that was the bottom of this lake. I quickly hoped to shore, regretting every moment. I proceeded to follow a few trail-like indentations in the brush, none of which led to a sign or an actual portage. I was not about to risk getting us lost so I went back to the canoe.  </p>
            <p>As I positioned myself on shore, trying to figure a way into the canoe without tipping it and with as little contact with the muck as possible, I noticed a huge green leech swimming right where my feet had been only moments before. I cringed, I know very well that leeches are harmless, I’ve handled them and had them on me many times, they still creep me the hell out and I don’t want one that large anywhere near my bare legs. So, I quickly stepped in the water / muck then into the canoe, managing to escape with no more than a stinky foot. </p>

            <div className="image-text">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/highfallslake1.jpg")}} alt="high falls lake" width="80%" src="/opalescentlake/highfallslake1.jpg"></img>
                <p>Having a quick break before heading out onto High Falls Lake.</p>
            </div>
            <p>I checked another map which showed a clear difference in the location of the portage entrance, so we continued around the edge of the lake until we spotted a big yellow portage sign.  As with most of the portage landings, this one was shallow and rocky making getting in and out of the canoe tough to do without getting your feet wet.  This 300-meter portage that took us into High Falls Lake was much the same as the others but felt much easier because it was so short.  Before long we were at the rocky shores of High Falls Lake.  </p>          
            <p>Looking down the lake it’s still evident that there was a fire along the west side.  It was interesting to see the damage but also how fast nature has started to regrow and take over again.  We enjoyed the paddle; High Falls Lake was quiet and calm with only a slight breeze. As we came to the South-west end of the lake we had to paddle carefully.  There are a number of large rock slabs that lay just under the water, you have to take special care to avoid bottoming out on these, there are channels between many of the rocks but they can be hard to see.  There were two campsites on the lake here as well, one of them appeared to be occupied.</p>
          
            <div className="image-frame50">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/highfallslake2.jpg")}} alt="high falls lake" width="50%" src="/opalescentlake/highfallslake2.jpg"></img>
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/highfallslake3.jpg")}} alt="high falls lake" width="50%" src="/opalescentlake/highfallslake3.jpg"></img>
            </div>

            <div className="image-text">
                <p>Looking down High Falls Lake, fire damage is still evident on one side.</p>
            </div>

            <p>Once we were at the very far end of the lake, we discussed whether we could get the canoe up any further or if we would be hiking from here.  I was not completely sure how we were going to find High Falls from our current location. I knew where it was in reference to us but how to get there? My research online had suggested there was a path that went up along the rapids that we could take, this was also shown on Jeff’s Maps, so this is what I was hoping to find, but I truly wasn’t sure exactly what we would be looking for.  </p>
            <p>The timing could not have been more incredible… no sooner had we started discussing this very path when out of the bush came a man and a young girl, apparently from the very same place this path was supposed to be.  They confirmed we were looking in the right place, as it turned out, they were coming back from High Falls themselves and were happy to give me some advice on following the trail there. We pulled up the canoe on the large rock ledge that was the shoreline, and left it stashed in the bush while we hiked in further.  </p>
            
            <div className="image-text">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/highfalls1.jpg")}} alt="high falls waterfall" width="80%" src="/opalescentlake/highfalls1.jpg"></img>
                <p>High Falls waterfall, beautiful!.</p>
            </div>
            <p>Looking into the woods, there was no visible path to start from, I just kept the rapids to my right and headed in.  I was warned about this, the beginning of the path is a bushwhack. As we headed in, keeping along the rapids, we eventually did run into a faint path, someone had even placed markers on some of the branches part way through.  I brought a compass to be safe and we followed the path a while.  We found some rapids and before long we had an amazing view directly across from High Falls.  The path seemed to disappear, and was getting harder to follow, and I was unsure if continuing the way we were going would get us behind the falls and to the waterslide.  We wanted to see the water slide but looking at the direction we were going and the questionable nature of the path we were unsure if we could actually get to the slide going this way. I scouted a little further up but didn’t find any answers so we decided to back track to the previous rapids.  </p>
            <p>Just before the High Falls there is a smaller set of rapids that we came across, we found a way down and then spent some time playing and refreshing ourselves in the rushing water.  I took this opportunity to refill the water canteens by filtering some stream water, then we continued our way back to find the canoe.  </p>

            <div className="image-text">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/rapids.jpg")}} alt="high falls lake" width="80%" src="/opalescentlake/rapids.jpg"></img>
                <p>Just below the waterfall, this small set of rapids was fun to play in.</p>
            </div>
            
            <p>On the way back, we managed to get onto a different path, since there were a number of paths crossing each other but all going the same general direction. We ended up intersecting with the portage into St Andrews but continued in the right general direction.  We ended up coming out along the shore in the wrong place, about 20-meters to the right of where our canoe was, so we had to go back, bushwhacking our way through some very rough bush, with a few interesting spots. We passed a massive rock wall, and a cave that I’m pretty sure was home to a bear, before we finally found the canoe right where we had left it. </p>
            <p>As we were coming to the edge of the brush, I heard a blood curdling scream behind me, it was Heni.  She was dancing and screaming about how much her leg was burning. At first, I thought she got a good fly bite, but the burning sensation intensified and didn’t go away for a while. We finally realized that she must have been stung by some sort of wasp while she was walking through the brush.  After she calmed down, she did remember seeing something she described as long and black with wings. I inspected the wound and found a small hole but no stinger. I gave her some Benadryl for the reaction and hoped it would help, whatever it was, she was in significant pain for quite a while after the sting. </p>
            
            <div className="image-frame50 portage-sign">
                <div className="top-margin-5">
                    <p>I put the canoe back in the water and we all piled in, looking forward to getting back to camp.  Everyone was pretty tired from hiking through the forest, so off we went, back up High Falls Lake to the 300-meter portage.  We exchanged greetings with a family camping on the northern of the two campsites on the lake as we passed by, they were out in the water enjoying a swim. More familiar with the landings we managed them quicker this time. We made quick work of the portage, followed by a short paddle on Ooze Lake.  One more time across the 640-meter and we were home free and back on Opalescent Lake.  </p>
                </div>
                <img loading="lazy" alt="330m portage" width="80%" src="/opalescentlake/portage330.jpg"></img>
            </div>

            <p>We were back in time to make a creamy pasta dinner and enjoy the campsite a bit more as it was our last night.   A number of canoes passed by looking for our campsite in particular only to find it already occupied. I knew the feeling. We were the lucky bastards this time.  One large group in particular got all the way down to the end of the lake before all three of their canoes had to backtrack. All the sites on the lake were now taken and they ended up having to camp on the site which resides right next to the 730m portage.  I couldn’t help but feel a little bad for them, but I’m sure they made the best of it.  </p>
            
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/fire.jpg")}} alt="bon fire" width="50%" src="/opalescentlake/fire.jpg"></img>

            <p>I was, as usual, the last to bed, always trying to stretch my day / night as far as I can. There was no need to wake up early the next morning, but we did need to pack up camp and leave the next day at some point.  The stars were occasionally visible this night, but not to their fullest like they were the first night.  I enjoyed a rather large fire as long as I could and ate up some food so we wouldn’t need to carry it back. I hung the bag and cooler one last time, feeling rather light by now, then I crawled into the tent and drifted off to sleep.</p>
           
            <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/sunset2.jpg")}} alt="sunset" width="80%" src="/opalescentlake/sunset2.jpg"></img>

            <p>Friday morning was warm and slightly cloudy, a pretty nice day right from the start.  I woke up and immediately packed my sleeping pad and bag before I made some coffee and breakfast.  Oatmeal and protein bars were the main breakfast items, we may have had some leftover clementines as well.  We ate well and slowly started packing up to leave.  I noticed a guy in a canoe fishing and generally just hanging around our site, I suspected he was waiting to see if our site was becoming available.  He got closer as I was near shore and I shouted out a hello to him. He took the opportunity to ask me if we were staying for the day or leaving, to which I relied we were currently packing and about an hour from leaving the site vacant. I could tell he was happy; he was part of the group that had to turn back the previous night and now they could get the good site on the lake.  I was happy to hand it over to them and told him to consider it reserved. </p>
            <p>Everything in the tent was packed first, followed by the tent itself and the tarp shelter.  The food bag and cooler were dropped and the rope removed and packed. All we had to do was load the canoe and we were off.  We went over the campsite checking for any garbage or leftovers from campers, we always remove these and pack them out.  My kids gathered some firewood and created a nice little pile for the next people to get started.  We said goodbye to our campsite, it had been a great home over the last four days and I think we were all going to miss it a little.  We paddled off towards the 730-meter portage back to Brigham Lake.   The new group was already making their way over to the campsite as we left.  </p>
            
            <div className="image-text">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/opal3.jpg")}} alt="opalescent lake" width="80%" src="/opalescentlake/opal3.jpg"></img>
                <p>Looking out over a beautiful, calm Opalescent Lake.</p>
            </div>

            <p>We managed the portage without any real trouble, though it was still a pain, it was over pretty quickly. That was the worst of it, the rest was easy.  We paddled up the baron river, back to the Brigham parking lot access point where we had begun our adventure.  This paddle took us no longer than about twenty minutes and we were within sight of the now vacant campsite that sits at the access point.  We pulled up to shore and started unloading gear, throwing it up onto the ground above.  </p>
           
            <p>Everyone carefully got out of the canoe and then I managed to pull it up the steep bank and lay it down.  All we needed to do now was take everything up the trail to the parking lot and get into the van.  We grabbed as much as we could and started hauling, all of us, anxious to get back.  The way up is much harder I found, a good section of the trail is uphill at a rather steep angle making it a slow walk with a pack and canoe on your back.  Once everything was back up, we loaded the van and tied up the canoe securely.  </p>
           
            <div className="image-center-40">
                <img loading="lazy" className="expandable-image" onClick={ () => { setImageViewState("/opalescentlake/sunset1.jpg")}} alt="sunset" width="80%" src="/opalescentlake/sunset1.jpg"></img>
            </div>
            <p>A quick look around, and we all got into the van.  We had snacks and drinks waiting for us to tide us over until we could stop for food somewhere.  I started up the van and punched in a route on my GPS to get home. This time, the GPS took me on a much more reasonable route on which I travelled small highways back the entire time once I was off of Baron Canyon Road.  Once we got back closer to civilization, we stopped to top up with gas and grab some sandwiches at Tim Hortons before settling in for the long drive back.  We didn’t really run into much trouble on the way home, though there were a few delays on the 401, nothing serious and we made good time getting home close to 9pm that Friday.  Tired but happy to be home, we quickly unloaded the van and threw the gear into the garage to be sorted later.  Our cat was anxiously awaiting our return, when we opened the door, she was right there to greet us.  We were finally home, another memorable family camping trip in Algonquin back country, we had an amazing time and I look forward to the next one.    </p>           

</div>
    )
}

export default OpalescentLake2022;