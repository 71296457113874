import React, { useEffect } from "react";

const BigTroutLake2021page3 = ({openImageView}) => {

    useEffect( ()=> {
        let e = document.getElementById('nav');
        if ( e !== undefined )
            e.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, []);

    return (<div>

            <img alt="Big Trout Lake" src="/bigtroutlake/bigtrout3.jpg"></img>

            <p>When I woke the next morning, the rain had stopped, the sky was clear and everything was starting to dry up, there were no big puddles or anything, everything was just damp. I was able to start a fire pretty easily and still had some leftover wood under the tarp that was good and dry. I got a small fire started and boiled some water for coffee and breakfast. This morning I was having oatmeal and protein bars and all I needed was some clean hot water. After breakfast I got to work taking down camp and packing everything up.  I really enjoyed this site and would come back again if I get the chance but it was time to head to the next destination. By around 10am in the morning I had the canoe packed up, fire extinguished and I was ready to head out.                
            </p>
 
            <p>I said goodbye to my campsite and made my way directly north west towards a group of islands.  The paddle wasn’t too difficult that day, the wind was moderate and I was able to make progress as long as I didn’t stop paddling too long.  I made my way through the small islands, continuing north west until I made it to the entrance of the narrows leading into White Trout Lake. I stopped at the large island at the mouth of the narrows. There were a few campsites around the island, I had stopped on the rocky point on the west side for a few moments to have a quick rest, eat a protein bar and take some pictures. This spot looked like a good place to fish and swim, very rocky with a nice deep drop off along the shore. I cast my lure a few times without success.
            </p>
        
            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/narrows.jpg")}>
                <img alt="The Narrows" src="/bigtroutlake/narrows.jpg"></img>
            </div>
                       
            <p>I entered what is referred to as "the narrows", a narrow corridor of water that connects Big Trout Lake to White Trout Lake, the water was a bit calmer here.  As I paddled along, I made sure to pay attention to and enjoy all the natural scenery around me. It was very quiet and peaceful this far into the park.  I continued through the narrows until it opened up into White Trout Lake. I was once again dealing with wind and waves as I made my way into the middle of the lake. I paddled my way ahead a little bit at a time, making my way towards the large towering cliffs in the distance.                  
            </p>         
             
            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="White Trout Lake" src="/bigtroutlake/whitetrout.jpg" onClick={() => openImageView("/bigtroutlake/whitetrout.jpg")}></img>
                    <img loading="lazy" alt="The High Cliffs" src="/bigtroutlake/highcliffs.jpg" onClick={() => openImageView("/bigtroutlake/highcliffs.jpg")}></img>
            </div>

            <p>I sure did feel small as I paddled along the towering rock face, dotted with trees.  The huge rock cliffs on the east side of White Trout are really cool to see up so close.  I made my way along the entire length slowly, admiring the amazing landscape here. I passed through the middle of White Trout and continued moving south. I was heading towards what would be a very long, marshy paddle into Grassy Bay.
            </p>
            
            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/whitetrout1.jpg")}>
                <img loading="lazy" alt="White Trout" src="/bigtroutlake/whitetrout1.jpg"></img>
            </div>
            
            <p>About mid-way through White Trout, there is a ‘u’ shaped island that I needed to paddle around.  I chose to go around it on the north-west side but the channel between the island and the mainland shore looked to be an almost impassible mix of mud and lily pads with very little clearance for a canoe. I proceeded carefully and found that there was in fact, a channel that made its way through the muck, and to my surprise, it was actually quite passable with some careful navigation.
            </p>
            
            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/goose.jpg")}>
                <img loading="lazy" alt="A Goose" src="/bigtroutlake/goose.jpg"></img>
            </div>
           
            <p>As I passed through, I found a large number of canadian geese milling about the marshy scene, I think they were busy enjoying lunch so I tried not to disturb them as I passed. I did manage to grab a few pictures however. Continuing on, I slowly made my way through and towards the narrow opening into Grassy Bay.                
            </p>
           
            <hr/>
            
            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/grassybay1.jpg")}>
                <img loading="lazy" alt="Grassy Bay" src="/bigtroutlake/grassybay1.jpg"></img>
            </div>
           
            <p>Grassy Bay is an interesting place, lots of floating mounds of mud and/or plant life all over the place, lily pads and beaver dams everywhere, it certainly looked like prime moose territory.  I made my way slowly through the channels between these islands, for hours I had been paddling, stopping only to have some lunch and snap a few pictures.  I decided to make a quick stop at the sorry looking campsite that lies most west on Grassy Bay. The water at the canoe landing was thick with grass and lily pads.  I pulled the canoe up the large rock landing and went up for a look. The site gave you a great overview of the marsh and might be ideal for moose sightings but I can’t imagine any other reason to want to stay there.
            </p>

            <div className="image-frame50 expandable-image">
                <img loading="lazy" alt="Ugly Campsite" src="/bigtroutlake/sadcampsite.jpg" onClick={() => openImageView("/bigtroutlake/sadcampsite.jpg")}></img>
                <img loading="lazy" alt="A Water Lily" src="/bigtroutlake/waterlily.jpg" onClick={() => openImageView("/bigtroutlake/waterlily.jpg")}></img> 
            </div>

            <p> The site was small and ugly, and clearly hadn’t been used in a while as it was very over grown. I snapped a few pics and ate lunch. It was nice to stretch my legs a bit but I was anxious to get on my way and get to McIntosh Lake, I still had a long day ahead of me. As I prepared to launch my canoe once again, two canoes in a group passed by going the opposite direction.  I was still hours from my destination and the wind was in my face, I set off and paddled my way through the remaining marsh.                
            </p>   

            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/grassybay.jpg")}>
                <img loading="lazy" alt="Grassy Bay" src="/bigtroutlake/grassybay.jpg"></img>  
            </div>

            <p>Finally, I could see a portage sign in the distance. I made it! I thought to myself.  My joy was short lived when I realized that this was actually the 930 meter portage into Hawkins Lake. At least I was on the right path, but I still had some paddling to do.  I continued for a time, until the end of the marsh narrowed into more of a small grassy creek. I followed the creek as it wound its way through the increasingly dense bush.  I had to pull my canoe over a couple of small beaver dams along the way but they were relatively easy to pass.  I had been travelling for quite some time and started to wonder if It was possible that I could have missed my portage somehow as the creek was getting less navigable and the surrounding forest more dense and I had yet to see a portage sign. As there was no other way, I kept going until finally at the edge of the forest, nailed to a tree in the distance, there was visible a small yellow sign with an arrow, indicating a portage was up ahead. This was not your typical big portage sign, it was this little six inch square yellow tile, but it was visible and confirmed to me that I was close.                
            </p>

            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/creek3.jpg")}>
                <img loading="lazy" alt="McIntosh Creek" src="/bigtroutlake/creek3.jpg"></img>
            </div>

            <p>I continued along the creek for a while longer until I came to a very large dam blocking the way, easily two feet high out of the water. I stopped to check out the area, wondering if I had missed something, this was the largest obstruction I had come across and I wanted to be sure before I hauled my canoe over it. I back tracked to the sign I had seen earlier to make sure there was no other option. Nope, that was it. So I paddled back up to the dam and got out, pulling my long pants up to keep them dry. I hoisted the front of my canoe up onto the dam, pulling it up and over into the next section of creek. Once it was over, I got in and took a good look around me.  I continued to follow the creek a while further, until finally, in the distance I could make out a big yellow portage sign. I paddled the remaining creek as it looped back and forth, finally coming to an end on a shallow, rocky sand shore. I unloaded the gear and prepared for the hike. I was glad to finally have made it to the portage after so much time in the canoe. The portage landing here wasn’t very nice at all, it was small with a very steep rocky start.  There was very little space to put anything so I had to carry the gear from the canoe up the portage a way to set it down.                
            </p>

            <p>This portage was this first of the day, a whole 745 meters, so not exactly small but nothing serious, even after a long day of paddling. It would be followed shortly by a 510 meter portage, the last of the day and I would be at my destination, McIntosh Lake, looking for a campsite.                 
            </p>

            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="White Trout Lake" src="/bigtroutlake/creek4.jpg" onClick={() => openImageView("/bigtroutlake/creek4.jpg")}></img>
                    <img loading="lazy" alt="The High Cliffs" src="/bigtroutlake/bridge.jpg" onClick={() => openImageView("/bigtroutlake/bridge.jpg")}></img>
            </div>

            <p> I was making progress but the day was getting late and the sun sets earlier this time of year so I really was trying to be as fast as reasonably possible. The whole first part of the portage was a hike up a narrow rocky hill and felt pretty brutal but it did get a little better and it was one of the more scenic portages I’d seen along the way. The surrounding forest is beautiful, the portage closly follows McIntosh Creek and there are a couple of neat wooden bridges to cross along the way.                  
            </p>

            <p>There were some real muddy spots and a lot of big rocks to navigate but pretty soon I was on the other side and loading the canoe back up.   I was now in a small grassy part of McIntosh creek, wide enough to easily canoe through. I had spent most of my day in such creeks and I was enjoying every minute of it. I had to pull my canoe over more beaver dams, but the paddle was very scenic and I took the time to snap more pictures as I went.                 
            </p>
            
            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/muddyportage.jpg")}>
                <img loading="lazy" alt="A Muddy Portage Landing" src="/bigtroutlake/muddyportage.jpg"></img>
            </div>

            <p>Before long I was pulling up to my last portage.  The portage landing on this one was far worse than the previous. There was a long wooden pier sticking out from a very muddy landing. The mud is deep and goes way back, so you can’t get any footing anywhere.  The wooden pier was it. Getting out of the canoe was tricky enough, but lifting the canoe out of the water and muck was going to be lots of fun, I thought. Once I emptied all the gear from the canoe and brought it up the trail to a dry spot, I ended up pulling the canoe up onto the wooden pier and just dragging it along the wood into the forest until I could pick it up properly.  I picked up the small pack along with the canoe and I headed down the trail, I was almost at my destination and could hardly wait to get there.                  
            </p>

            <p>This trail was pretty flat and even but did have its share of mud pits, though I passed it with ease, I’m not going to lie, I was pretty damn tired by then!  As I emerged from the cover of forest, to a sunny beach like landing on the shores of McIntosh Lake, my energy was renewed. McIntosh Lake was beautiful, the weather was sunny and calm, absolutely perfect.  It was late afternoon and I was excited to be on the hunt for a campsite now. After a quick water and food break, I packed up the canoe and set off into the lake. I wanted to camp somewhere on the south east end of the lake preferably, since I would be heading that direction when I leave.  I decided I would check out a couple of sites I found on the map, one of them being on an island and looking to have a pretty nice view of the sunset.                  
            </p>

            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/mcintoshlake.jpg")}>
                <img loading="lazy" alt="McIntosh Lake" src="/bigtroutlake/mcintoshlake.jpg"></img>
            </div>

            <p>I paddled hard, I was paddling against a light wind as got into the middle of the lake.  I headed for the island, making good speed, as I noticed the sun getting lower. It was 6pm by now and I still needed time to setup camp and find firewood. As I got closer to my destination I was disappointed to see that people occupied the island site I was aiming for.  This meant I needed to keep paddling and hope to find something further to the south. There were some sites along the east side of the lake. I decided to aim for the lone one that stuck out on a big rocky point. Paddling as hard as I could, I passed by the islands and towards the point.  As I got closer I was a little concerned as to where I would land my canoe. The shore was mostly a big rock cliff all the way around.  As I pulled up to the south side of the site, to my relief, there was a big inlet and a slightly less deadly sloped rock landing, where I could pull up my canoe.  The site looked pretty good from the lake, so I was excited to check it out.                
            </p>

            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Campsite on McIntosh Lake" src="/bigtroutlake/campsite3.jpg" onClick={() => openImageView("/bigtroutlake/campsite3.jpg")}></img>
                    <img loading="lazy" alt="Campsite on McIntosh Lake" src="/bigtroutlake/campsite3-1.jpg" onClick={() => openImageView("/bigtroutlake/campsite3-1.jpg")}></img>
            </div>

            <p>I didn’t have a lot of time to be choosy with my site anyways, but it’s always nice to find a great site to enjoy. This site had an amazing view, it was on a large open rock, giving me an ideal view of the sunset and the night sky.  There was a large open fire pit right along the edge of the site, looking over the lake.  Alongside the fire was a decent looking wood bench, and even a picnic table to one side.  About fifty meters up into the denser forest was where I would actually pitch my tent, this way I wasn’t exposed on the point if the wind picked up.                 
            </p>

            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/mcintoshlake1.jpg")}>
                <img loading="lazy" alt="McIntosh Lake" src="/bigtroutlake/mcintoshlake1.jpg"></img>
            </div>

            <p>The forest behind the site was really nice and open with lots of large trees, unfortunately firewood was lacking. Overall this site was really nice and I felt fortunate to stay here for my last night in Algonquin.  I unpacked the canoe and took all the gear up onto the site. I brought the canoe into the forest and secured it between a couple of trees so I wouldn’t lose it.  I setup my tent and got my bag hang ready, then set out to find some firewood.  Since it would be my last night, I was really hoping to have a decent fire. There were a few nice logs left over from the last campers, but not nearly enough. I scavenged what I could from the surrounding forest, I found it a really tough area to find good firewood and the immediate shores seemed pretty barren as well. I grabbed what I could find and ended up with an ok pile after all.                
            </p>

            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/sunset3.jpg")}>
                <img loading="lazy" alt="McIntosh Lake Sunset" src="/bigtroutlake/sunset3.jpg"></img>
            </div>
            
            <p>Once camp was setup and I had a nice fire going, I made dinner and settled in for the evening. I was treated to a gorgeous sunset once more, giving me something to watch while I enjoyed my meal. Today’s dinner was an Uncle Ben’s rice mix with Beef Jerky again, lots of protein and carbs and a favorite of mine when camping. I grabbed some snacks for the night and hung my food bag then enjoyed a huge fire on the lake.  The sky was clear making every star so bright and visible in the sky, the loons made their calls across an otherwise still, silent lake, it really was an amazing thing to experience and a great way to end my nights in Algonquin. I didn’t need to make it a real early night as I wasn’t in a big hurry to leave camp the next morning and I wanted to enjoy my last night so I burned though a good chunk of the wood I had found before finally calling it and going to bed. I slept well, after all the travelling, my body sure did need the rest. I was still up pretty early, ready to go, however I had a bit of a surprise when I opened my tent door.                
            </p>

            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Fog on McIntosh Lake" src="/bigtroutlake/fog1.jpg" onClick={() => openImageView("/bigtroutlake/fog1.jpg")}></img>
                    <img loading="lazy" alt="Fog on McIntosh Lake" src="/bigtroutlake/fog2.jpg" onClick={() => openImageView("/bigtroutlake/fog2.jpg")}></img>
            </div>

            <p>Upon opening my tent that morning, at about 6am, I was greeted by a fog so thick I couldn’t see most of the campsite, it felt like I was floating in a cloud. It took until 7am just to be able to see the surroundings of my campsite. I began the process of packing up my gear, I wasn’t in a hurry to leave but I wanted to get all of the work of taking down camp out of the way before anything else.  After taking down camp I made coffee and some oatmeal for breakfast.  The fog was receding very slowly and at this rate it would be a while before I could safely navigate the lake so I couldn’t leave even if I was ready to. I couldn’t even see 10 feet of shoreline left or right of my campsite at this point and I sure as hell couldn’t see anything across the lake. So I did what any sensible person would do, I went fishing.  This campsite had a perfect rock ledge for shore casting into deep water and I was going to take advantage of it.                 
            </p>

            <p>I began casting a spinning lure and a few spoons, and shortly after I got my first nibble. A few more casts and I had a fish on. This was a big one, I could feel it as I tried to carefully reel it in. I kept the tension on the line as I brought a beautiful lake trout to the surface just long enough to get a good look at him, when suddenly the fish managed to spit the hook and get away. Disheartened that I had lost a really nice fish but excited that they were at least present and hungry, I was hopeful I’d get another chance. So I kept trying, cast after cast, and though I had one more close call, as I watched a massive trout follow my lure right to the shore’s surface without taking a bite. I never did land any that day. I was disappointed to have come so close and fail, but I was out of time and needed to leave soon. I fished as long as I could before finally packing it in. I pushed off from shore about quarter to noon, after the fog had finally cleared.  I said goodbye to a great campsite and headed towards the south end of McIntosh Lake where I would be again doing some creek travel.                
            </p>

            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/inkcreek.jpg")}>
                <img loading="lazy" alt="Ink Creek" src="/bigtroutlake/inkcreek.jpg"></img>
            </div>
            
            <p>It didn’t take long to reach the beginning of the creek leading into Ink Lake. It’s a long winding creek and it looks and feels much different than the Otter slides. It really is a neat looking place and I enjoyed the slow pace of the paddling while making my way through it.  I made frequent stops to take pictures and enjoy the surroundings.</p>
            
            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/inkcreek1.jpg")}>
                <img loading="lazy" alt="Ink Creek" src="/bigtroutlake/inkcreek1.jpg"></img>
            </div>
            
            <p>It was during one of these quick stops that I had a very strange experience that freaked me out a bit. I was sitting in the canoe, paddle in hand, and the camera around my neck, I had just finished taking some pictures. The air was dead calm, there was no noise, it was just peaceful and I was enjoying a still moment. Suddenly, the air made a sudden whooshing noise around me, I watched in astonishment as to my immediate right the water started sputtering loudly while starting to swirl. I watched as the water was picked up in a cyclone, creating a tower of floating water which, after one final quick whoosh, was tossed directly in my face as if some external force was throwing it at me. At that moment the wind died and everything went quiet again.  The moment was eerie, I must have stared into nothing for at least a few minutes, trying to make sense of what I had just experienced.  This was an otherwise calm, sunny, quiet day. What the hell was that!? I thought to myself, it came out of nowhere, what a strange experience, one I’ll definitely never forget.                
            </p>

            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Ink Lake" src="/bigtroutlake/inklake.jpg" onClick={() => openImageView("/bigtroutlake/inklake.jpg")}></img>
                    <img loading="lazy" alt="Ink Lake" src="/bigtroutlake/inklake1.jpg" onClick={() => openImageView("/bigtroutlake/inklake1.jpg")}></img>
            </div>

            <p>Eventually I popped out into a small body of water called Ink Lake. As I paddled across it towards my next portage, I could see in the distance some canoers making their way away from the landing heading in my direction. Turns out these people were running a similar route but in the opposite direction.  They were looking for someone, apparently an older man had gone missing while out canoeing. This would certainly explain the plane I had seen overhead earlier that day. I had not seen anyone in distress during my travels but I kept an eye out after that.  I never did find any information regarding the supposed missing man, even after arriving home after the trip, so no closure there, but maybe this is one of those no news is good news things.                 
            </p>
            
            <div className="image-frame50 expandable-image">                
                    <img loading="lazy" alt="Ink Lake" src="/bigtroutlake/portagesign2.jpg" onClick={() => openImageView("/bigtroutlake/portagesign2.jpg")}></img>
                    <img loading="lazy" alt="Ink Lake" src="/bigtroutlake/woodstairs.jpg" onClick={() => openImageView("/bigtroutlake/woodstairs.jpg")}></img>
            </div>

            <p>I landed the canoe and unloaded my gear. Looking up, I noticed a long wooden stair case going up a steep hill to start the portage.  I also noticed a couple of people seemingly camped near the portage landing. They greeted me as I came to shore, as it turns out they were camping on the next lake and only hiked this portage to check it out and would be going back the way they came, which happened to be the way I was headed so we chatted a bit before heading out separate ways.                
            </p>

            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/tomthompsonlake.jpg")}>
                <img loading="lazy" alt="Tom Thompson Lake" src="/bigtroutlake/tomthompsonlake.jpg"></img>
            </div>

            <p>This was the big portage of the trip and has a bit of a reputation as a rough hike, regardless it’s two and a half kilometers long which is in itself a challenge. The steep wooden staircase may have been a sign of what’s to come. I loaded up a pack and the canoe and started on my way up the stairs. As I got to the top, I thought to myself, ok here is where the fun starts right? I headed off on the trail, intent on getting as much distance as I could before dropping the canoe and heading back for the other pack. My plan was not to do this in one run but to stagger my runs each time going a little further up the trail.  The trail was long, and it was exhausting, it had every obstacle you could think of for a portage but it wasn't so bad and I survived it to find myself on a small marshy inlet on Tom Thompson Lake.                 
            </p>

            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/tomthompsonlake1.jpg")}>
                <img loading="lazy" alt="Tom Thompson Lake" src="/bigtroutlake/tomthompsonlake1.jpg"></img>
            </div>

            <p>I took a moment to rest, happy that I had reached a major milestone in my trip, finally completing the dreaded Ink Lake portage, it was tough and I was happy to have it over with. Now all that was left was a few hours of easy paddling back to the access point. I may have tempted fate with that thought, because there was to be nothing easy about it.  As I headed out into Tom Thompson Lake I was met with a strong wind making my paddling slow but I kept at it making steady progress.                 
            </p>

            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/littledoe.jpg")}>
                <img loading="lazy" alt="Little Doe Lake" src="/bigtroutlake/littledoe.jpg"></img>
            </div>

            <p>Things calmed down and I was able to relax a little once I was in the narrows between Tom Thompson and Little Doe, unfortunately once I got into the Little Oxtongue river I was back to a steady and stronger headwind making my progress extremely slow all the way through Tee Pee Lake and into Joe.  After a couple hours of hard steady paddling I was finally at the small portage that gets you around the dam and into Canoe Lake.  This was it, the final paddle and I was home free. I had been through this way coming the opposite direction only a matter of days ago and had now completed a full loop, I felt a sense of accomplishment but it wasn’t quite over yet.                  
            </p>
            
            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/oxtongue.jpg")}>
                <img loading="lazy" alt="Oxtongue River" src="/bigtroutlake/oxtongue.jpg"></img>
            </div>

            <p>As I paddled out into the open part of Canoe Lake I was once again met with a strong wind and decent sized waves pushing me in the wrong direction. This was it, a final challenge, it would take every last bit of energy I had left to get through this and to make matters more interesting, the sun was setting behind me as large visible moon rose in front of me reminding me of my limited time before dark. I, in no way wanted to be stuck paddling Canoe Lake in the dark so I set my heading as straight as possible and paddled my ass off all the way back.  Slowly I progressed, stroke by stroke, sometimes feeling like I was standing still, but I managed to reach shore just before sunset at about 7:30pm, giving me close to thirty minutes to spare before dark.                
            </p>

            <p>I pulled the canoe up the rocky shore then walked up to the now nearly empty parking lot where I had left my van. I parked the van back on the beach where I could easily pack my gear and strap the canoe on top. Once everything was secured, I took one last look back on a crazy adventure, snapped a few more pictures and headed off on the highway to home. I stopped in Huntsville to top up on gas and grab a coffee then drove straight home. I think I managed to get home just a while after midnight, leaving most of the unpacking until the next day, I was able to shower and sleep in my own bed that night, and it was soooo nice.                 
            </p>

            <div className="expandable-image" onClick={() => openImageView("/bigtroutlake/finish.jpg")}>
                <img loading="lazy" alt="The Finish Line" src="/bigtroutlake/finish.jpg"></img>
            </div>

            <p>Looking back at my experience, it really was a great adventure and I enjoyed it far more than I thought I might. While on most occasions I would prefer to experience Algonquin with the company of a friend of family member, there is definitely something special about the experience of being alone in the wilderness and one that I’m sure I will find myself repeating in the near future.  The only real disappointment was that I didn't see any moose, but there is always a chance next time.  I found this was a great opportunity to test my physical and mental endurance, allowing me to push myself as hard as I wanted to go and in an environment I really enjoy. I do feel that this experience allowed me to gage my abilities a bit more, giving me greater confidence for future trips.  Overall, this was a great learning experience and a great way to close out the season, I can now hibernate at home for the winter satisfied that I got my fix of Algonquin for a little while. Until spring is here, I will be waiting. Until next time Algonquin…                
            </p>

            <div className="end-text">THE END</div>

    </div>

    )
}

export default BigTroutLake2021page3;