import React, { useEffect } from 'react';

const Kiosk2023p5 = ({openImageView}) => {

    useEffect( ()=> {
        let e = document.getElementById('nav');
        if ( e !== undefined )
            e.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, []);
    
    return (
        <div className="kiosk2023_page4">

           <img loading="lazy" alt="Morning Calm" src="/kiosk2023/tent.jpg" onClick={() => openImageView("/kiosk2023/tent.jpg")}></img> 

           <p>I woke up earlier than usual and could hear something that worried me. I could hear the lake and the wind.  I find most mornings in Algonquin; the lakes tend to be nice and calm for a time.  This was not the case today; the waves were crashing into the shore and the wind was quite heavy but not impossible to deal with.  In the distance you could see some dark clouds, possibly a storm coming in my direction.</p>

           <p>I took down my camp as quickly as I could and managed a quick oatmeal and protein bar for breakfast.  The task of loading up the canoe and trying to get away from shore was made more difficult because the waves kept pushing me back in.  I managed to get off the site by nine thirty that morning, doing my best to keep the canoe pointed into the waves I pressed on, trying to get further north on the lake.  As I paddled past the large island half way up the lake, there was a noticeable break in the wind and the water had calmed almost completely. For a short time, paddling become a little easier. </p>
          
           <div className="image-frame50 expandable-image">                
                 <img loading="lazy" alt="Manitou Lake" src="/kiosk2023/manitoulake1.jpg" onClick={() => openImageView("/kiosk2023/manitoulake1.jpg")}></img>        
                 <img loading="lazy" alt="Manitou Lake" src="/kiosk2023/manitouisland.jpg" onClick={() => openImageView("/kiosk2023/manitouisland.jpg")}></img>         
           </div>
        
           <p>I continued north and along the shore, heading just north of Pine Island, then towards the rocky point that sticks out on the east shore just after that.   Here, the wind and waves once again became very noticeable, however they were pushing mostly in my direction. The portage was close, I was nearing the north end of the lake.  As I paddled around the rocky peninsular that sticks out from the east, I was nearly caught off guard.  There are some large rocks, barely visible in the water, laying just above the surface.  Due to the waves, you cannot see many of them, as I got closer, I noticed that it was not just a few rocks, it was an entire line of jagged rocks that went much further out into the lake than I had anticipated.  I was heading straight for them and the waves were pushing me with such force that had I hit one, I surely would have wrecked.  I quickly maneuvered the canoe, pulling a fast turn alongside the rolling waves, then paddling hard to get out and around the rocks, just barely missing the last one. I swung the canoe back around and towards my destination.  Breathing a sigh of relief, I was nearly home free, I could see the portage sign in the distance.  </p>
        
           <img loading="lazy" alt="Beach Landing" src="/kiosk2023/manitoubeach1.jpg" onClick={() => openImageView("/kiosk2023/manitoubeach1.jpg")}></img> 

           <p>The rolling waves were lifting my canoe as they pushed me towards the end of the lake, it was like surfing in my canoe.  I worked hard to keep the canoe straight towards the portage, these waves would still roll me quickly given the chance.  Soon, I pulled up to a large beach on the north end of Manitou Lake.  Manitou had blown up and become quite wavy, I’d hate to be going the other way, I thought to myself.  The beach here was pretty nice, on a better day I can imagine it would be a great place to spend some time and swim or lay in the sun.  The portage was about mid-way up the beach, there is a cottage up to the right of the beach, and opposite the cottage, on the other end of the beach is a campsite.  I could still see dark skies looming in the background and the wind had gotten worse, I’d better hurry if I’m going to outrun this storm.</p>

           
           <div className="image-frame50 expandable-image">                
                 <img loading="lazy" alt="Fungus" src="/kiosk2023/portagefungus.jpg" onClick={() => openImageView("/kiosk2023/portagefungus.jpg")}></img>        
                 <p>I gathered up my gear and headed down the portage, a 1300-meter hike to the Amable Du Fond River.  This would be the longest portage of the day.  It started out in a somewhat open field, gradually going deeper into the forest.  Overall, the entire portage was pretty easy going, nice and clear and flat, in fact I think it was probably the nicest portage I’d been on the entire trip by a long shot.  You could no longer see anything on the ground because the number of leaves was now far too thick, a vast difference from when I started.  I got through this one pretty easily, coming finally to the Amable Du Fond River.  The landing here was nice and flat with a few big rocks around and a large chunk of wooden boardwalk with no apparent use.</p>          
           </div>


           <p>The beginning of the Amable Du Fond here was pretty wide and very calm compared to the lake I had just come from.  The sky wasn’t so dark here either, it almost seemed as if the weather had changed its mind.  I paddled along the river, passing by the rapids I had just portaged around, you could feel the gentle current just a bit.  I continued down the river for some time, passing by a few campsites, at least one of which looked promising from the canoe.    Nearing the end of this portion of the river, there is a small rapid I needed to get through just before coming to the portage.  I knew it was there from the map, but I did not know what to expect.  Thankfully it was just a baby, and I made it through without even scraping a rock.  I pulled up to another flat rocky and slightly mudding landing and emptied my canoe ready for another hike.</p>

           <div className="image-frame50 expandable-image">                
                 <img loading="lazy" alt="Amable Du Fond" src="/kiosk2023/amabledufond.jpg" onClick={() => openImageView("/kiosk2023/amabledufond.jpg")}></img>        
                 <img loading="lazy" alt="Rapids" src="/kiosk2023/rapids.jpg" onClick={() => openImageView("/kiosk2023/rapids.jpg")}></img>         
           </div>

           <p>This next portage was actually a series of three individual portages with small sections of navigable river between them, eventually ending at Kiosk Lake, which was my destination.  The portages were all connected and I didn’t see the reason to load and unload the canoe multiple times for such small distances on the river, so I carried the entire distance straight through to Kiosk.  These portages were also quite flat and easy to walk with a few interesting sights along the way.  I made a stop to take some pictures of the massive boulder pile along the river.  There were a couple of campsites along the way, there was also a logging road and bridge that I passed by.  </p>
        
           <div className="image-frame50 expandable-image">                
                 <img loading="lazy" alt="Amable Du Fond" src="/kiosk2023/amabledufond1.jpg" onClick={() => openImageView("/kiosk2023/amabledufond1.jpg")}></img>        
                 <img loading="lazy" alt="Landing" src="/kiosk2023/portagelanding2.jpg" onClick={() => openImageView("/kiosk2023/portagelanding2.jpg")}></img>         
           </div>
           
           <div className="image-frame50 expandable-image">                
                 <img loading="lazy" alt="Amable Du Fond" src="/kiosk2023/boulders.jpg" onClick={() => openImageView("/kiosk2023/boulders.jpg")}></img>        
                 <img loading="lazy" alt="Landing" src="/kiosk2023/rapids1.jpg" onClick={() => openImageView("/kiosk2023/rapids1.jpg")}></img>         
           </div>

           <p>When I came to the end of the final section of the portage, I found myself on a rocky sand landing looking out onto Kiosk Lake.  This inlet was quite narrow at first, gradually opening up into the larger lake, and at the moment it was very calm.  I had a long paddle ahead of me so I was really hoping it would stay that way.  The day was still cloudy but for the moment at least, it appeared the threat of rain had gone away.  I loaded up the canoe and prepared for one more long paddle, back to the access point from where I began.  I passed a group of geese dipping their heads in the water for fish, they didn’t seem bothered by my presence.  As I continued down the lake, I came across the remnants of an old lumber company.  It was a long and slow paddle but eventually the lake opened up and I was now facing a big wide-open Kiosk.  I worked my way north-east along the south shore, using the peninsulas that stuck out as navigation points. I passed by a cottage or two before making a final crossing directly north to the boat launch at the access point.  Luckily, the weather cooperated for me and if anything, a gentle breeze gradually pushed me along, making the paddle a little easier. </p>

           <div className="image-frame50 expandable-image">                
                 <img loading="lazy" alt="Amable Du Fond" src="/kiosk2023/kiosklanding.jpg" onClick={() => openImageView("/kiosk2023/kiosklanding.jpg")}></img>        
                 <img loading="lazy" alt="Landing" src="/kiosk2023/rapids2.jpg" onClick={() => openImageView("/kiosk2023/rapids2.jpg")}></img>         
           </div>           

           <p>I landed on the stoney shore at the access point around three in the afternoon, got out and pulled the canoe up a bit further.  I stopped to look over Kiosk Lake.  Six days ago, I left from here, now I have made it back, my adventure was complete.  It was an amazing, happy moment, the completion of a goal, but it was also a sad moment knowing that the experience was over, for now.  I was looking forward to seeing everyone at home again, being away for even a short week was hard. Thanksgiving weekend was coming and I had another four days off to recover and enjoy time with family.   I would miss Algonquin but I would be back for another adventure soon enough.  </p>

           <img loading="lazy" alt="Morning Calm" src="/kiosk2023/finaldest.jpg" onClick={() => openImageView("/kiosk2023/finaldest.jpg")}></img> 

           <p>I pulled my van down to the water near the boat launch and loaded up all of my gear, making sure to put any good left-over snacks in the front seat for the long ride home.  Once I had the canoe secured to the roof, I made my way back to the highway and towards home.  After a quick stop for gas, I was on my way, I pulled into my driveway just after nine that night. Home sweet home.</p>

            <p>The End.</p>

        </div>  
        )
}

export default Kiosk2023p5;