import React, {useEffect} from 'react';
import {Link, Route, Switch } from 'react-router-dom';
import MinkLake2018 from './logs/minklake2018.jsx';
import LittleTrout2019 from './logs/littletrout2019.jsx';
import LindaLake2020 from './logs/lindalake2020.jsx'; 
import BigTroutLake2021 from './logs/bigtroutlake2021.jsx';
import OpalescentLake2022 from './logs/opalescent2022.jsx';
import Nipissing2023 from './logs/nipissing2023.jsx';
import KioskLake2023 from './logs/kiosklake2023.jsx';
import { Helmet } from 'react-helmet';


const LogList = () => {

    useEffect( ()=> {
        let e = document.getElementById('nav');
        if ( e !== undefined )
            e.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });

    return (
            <div>
                <div id="triplog-list" className="triplog-list">
                    <ul>
                        <div className="triplog-banner">
                            <li><Link rel="canonical" to="/triplogs/minklake2018"><h3>MINK LAKE 2018</h3>
                            <img alt="" src="../banner/minklake2018-banner.jpg" /></Link></li>
                        </div>
                        <div className="triplog-banner">
                            <li><Link rel="canonical" to="/triplogs/littletroutLake2019"><h3>LITTLE TROUT LAKE 2019</h3>
                            <img alt="" src="../banner/littletrout2019-banner.jpg" /></Link></li>
                        </div>
                        <div className="triplog-banner">
                            <li><Link rel="canonical" to="/triplogs/lindalake2020"><h3>LINDA LAKE 2020</h3>
                            <img alt="" src="../banner/lindalake2020-banner.jpg" /></Link></li>
                        </div>
                        <div className="triplog-banner">
                            <li><Link rel="canonical" to="/triplogs/bigtroutlake2021"><h3>BIG TROUT LAKE LOOP (solo) 2021</h3>
                            <img alt="" src="../banner/bigtrout2021-banner.jpg" /></Link></li>
                        </div>
                        <div className="triplog-banner">
                            <li><Link rel="canonical" to="/triplogs/opalescentlake2022"><h3>OPALESCENT LAKE, THE BARON CANYON AND HIGH FALLS 2022</h3>
                            <img alt="" src="../banner/opalescent2022-banner.jpg" /></Link></li>
                        </div>
                        <div className="triplog-banner">
                            <li><Link rel="canonical" to="/triplogs/nipissing2023"><h3>THE NIPISSING RIVER (solo) 2023</h3>
                            <img alt="" src="../banner/nipissing2023-banner.jpg" /></Link></li>
                        </div>
                        <div className="triplog-banner">
                            <li><Link rel="canonical" to="/triplogs/kiosk2023"><h3>KIOSK LAKE LOOP (solo) 2023</h3>
                            <img alt="" src="../banner/kiosk2023-banner.jpg" /></Link></li>
                        </div>
                    </ul> 
                </div>

            </div>
    )
}

const TripLogs = () => {
 
        return (
            <div>
                <Helmet>
                <title>Algonquin Adventures - Trip Log Selection</title>
                <meta name="description" content="A complete list of our most recent adventures in Algonquin Park for you to read. We have lots of cool trip pictures posted for your to check out as well. Check out the slide gallery for more outdoor pictures."></meta>  
                </Helmet>
                <Switch>
                    <Route path='/triplogs/minklake2018' component={MinkLake2018}></Route>
                    <Route path='/triplogs/littletroutlake2019' component={LittleTrout2019}></Route>
                    <Route path='/triplogs/lindalake2020' component={LindaLake2020}></Route>
                    <Route path='/triplogs/bigtroutlake2021' component={BigTroutLake2021}></Route>
                    <Route path='/triplogs/opalescentlake2022' component={OpalescentLake2022}></Route>
                    <Route path='/triplogs/nipissing2023' component={Nipissing2023}></Route>
                    <Route path='/triplogs/kiosk2023' component={KioskLake2023}></Route>
                    <Route path='/triplogs/' component={LogList}></Route>
                </Switch>
            </div>
            
        )
}

export default TripLogs;